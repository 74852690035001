// import "../../../StyleSheet/clientmoney/accounts/clientaccount.scss";
// import wallet from "../../../../Assets/Client/clientmoney/Wallet.svg";
// import Search from "../../../../MainComponent/fields/search";
// import DateRange from "../../../../MainComponent/fields/daterangepicker";
// import GenerateReport from "../../../../MainComponent/fields/generatereport";
// import TableComponent from "../../../GlobalComponents/table-v8/reacttable";
// import { createColumnHelper } from "@tanstack/react-table";
// import { useEffect, useMemo, useState } from "react";
// import { useClientAccountDetails } from "../../../../Api/clientmonetQuery";
// import { useSelector } from "react-redux";
// import dayjs from "dayjs";
// import { formatPounds } from "../../../../Utils/Utils";
// import { Skeleton } from "antd";

// export default function ClientAccount() {
//   const columnHelper = createColumnHelper();
//   const [tabledata, settabledata] = useState([]);
//   const [Generate, setGenerate] = useState("");
//   const [search, setsearch] = useState("");

//   const [Balance, setbalance] = useState(0);
//   const clientid = useSelector((state) => state.counter.clientid);
//   const [count, setCount] = useState(0);
//   const [pageindex, setPageIndex] = useState(1);
//   const [pagesize, setPageSize] = useState(10);

//   const getclientaccountdetails = useClientAccountDetails({
//     params: {
//       client: clientid,
//       page: pageindex,
//       page_size: pagesize,
//       search: search,
//       is_show: true,
//     },
//   });

//   useEffect(() => {
//     if (getclientaccountdetails.data != undefined) {
//       const newData = getclientaccountdetails.data?.data?.results;
//       const filterData = newData.filter(
//         (item) => item.is_show === true && item.credit >= 0 && item.debit >= 0
//       );
//       console.log(filterData, filterData.length, "akshksfjhsfkh");
//       // settabledata(getclientaccountdetails.data.data.results);
//       settabledata(filterData);
//       setCount(getclientaccountdetails.data?.data?.count);
//       setbalance(filterData[0]?.balance || 0);
//     }
//   }, [getclientaccountdetails.data, pageindex, pagesize, search]);

//   const columns = useMemo(
//     () => [
//       columnHelper.accessor((row) => row, {
//         id: "receipt_date",
//         header: "Date",
//         sortingFn: "basic",
//         cell: (info) => {
//           // console.log(info.row.original, "lkjalslfjsladjlfajdslj");
//           return (
//             <span>
//               {dayjs(info.row.original.recpit_date).format("DD/MM/YYYY")}
//             </span>
//           );
//         },
//       }),
//       columnHelper.accessor((row) => row, {
//         id: "payer_name",
//         header: "Name",
//         sortingFn: "basic",
//         cell: (info) => <span>{info.row.original.payer_name[0]}</span>,
//       }),
//       // columnHelper.accessor((row) => row, {
//       //   id: "recipt_type",
//       //   header: "Description",
//       //   sortingFn: "basic",
//       //   cell: (info) => (
//       //     <span>{info.row.original.debit != 0 ? "Charge" : "Receipt"}</span>
//       //   ),
//       // }),
//       columnHelper.accessor((row) => row, {
//         id: "type",
//         header: "Type",
//         sortingFn: "basic",
//         cell: (info) => <span>{info.row.original.type}</span>,
//       }),
//       columnHelper.accessor((row) => row, {
//         id: "debit",
//         header: "In",
//         sortingFn: "basic",
//         cell: (info) => (
//           <span>{`£${formatPounds(info.row.original.credit)}`}</span>
//         ),
//       }),

//       columnHelper.accessor((row) => row, {
//         id: "credit",
//         header: "Out",
//         sortingFn: "basic",
//         cell: (info) => (
//           <span>{`£${formatPounds(info.row.original.debit)}`}</span>
//         ),
//       }),
//       columnHelper.accessor((row) => row, {
//         id: "balance",
//         header: "Balance",
//         sortingFn: "basic",
//         cell: (info) => (
//           <span>{`£${formatPounds(info.row.original.balance)}`}</span>
//         ),
//       }),
//     ],
//     []
//   );
//   return (
//     <div className="client">
//       <div className="cli_acc_button">
//         <div className="cliaccHead">
//           <h1>Client Account</h1>
//           <div className="searchClientAmount">
//             <Search
//               setsearch={setsearch}
//               style={{ width: "100%" }}
//               setPageIndex={setPageIndex}
//               placeholder={"Account"}
//             />
//           </div>
//         </div>
//         <div className="table_headers">
//           <div className="card">
//             <div
//               style={{
//                 display: "flex",
//                 justifyContent: "space-around",
//                 alignItems: "center",
//                 // gap: "4px",
//                 width: "55%",
//               }}
//             >
//               <img src={wallet} />
//               <p>Client Balance</p>
//             </div>
//             <h1>£{formatPounds(Balance)} </h1>
//           </div>
//           <div>
//             <DateRange style={{ width: "200px" }} />

//             <GenerateReport
//               style={{
//                 padding: "5px 30px",
//                 fontSize: "14px",
//                 display: "flex",
//                 alignItems: "center",
//               }}
//               setGenerate={setGenerate}
//             />
//           </div>
//         </div>
//       </div>
//       <div className="main_content">
//         <div
//           style={{
//             width: "100%",
//             height: "100%",
//             overflowY: "auto",
//             overflowX: "hidden",
//           }}
//         >
//           {getclientaccountdetails.isFetching ? (
//             <div className="skelitonDiv">
//               <Skeleton active style={{ height: "98px" }} rows={1} />
//             </div>
//           ) : (
//             <TableComponent
//               columns={columns}
//               data={tabledata}
//               count={count}
//               pageindex={pageindex}
//               pagesize={pagesize}
//               setPageIndex={setPageIndex}
//               setPageSize={setPageSize}
//               // onclickrow={row => console.log(row)}
//             />
//           )}
//         </div>
//       </div>
//     </div>
//   );
// }

import "../../../StyleSheet/clientmoney/accounts/clientaccount.scss";
import wallet from "../../../../Assets/Client/clientmoney/Wallet.svg";
import Search from "../../../../MainComponent/fields/search";
import DateRange from "../../../../MainComponent/fields/daterangepicker";
import GenerateReport from "../../../../MainComponent/fields/generatereport";
import TableComponent from "../../../GlobalComponents/table-v8/reacttable";
import { createColumnHelper } from "@tanstack/react-table";
import { useEffect, useMemo, useState } from "react";
import { useClientAccountDetails } from "../../../../Api/clientmonetQuery";
import { useSelector } from "react-redux";
import dayjs from "dayjs";
import { formatPounds } from "../../../../Utils/Utils";
import { Skeleton } from "antd";

export default function ClientAccount() {
  const columnHelper = createColumnHelper();
  const [tabledata, settabledata] = useState([]);
  const [Generate, setGenerate] = useState("");
  const [search, setsearch] = useState("");

  const [Balance, setbalance] = useState(0);
  const clientid = useSelector((state) => state.counter.clientid);
  const [count, setCount] = useState(0);
  const [pageindex, setPageIndex] = useState(1);
  const [pagesize, setPageSize] = useState(10);
  const [range, setRange] = useState([]);

  const getclientaccountdetails = useClientAccountDetails({
    params: {
      client: clientid,
      page: pageindex,
      page_size: pagesize,
      search: search,
      is_show: true,
      from_date: range[0],
      to_date: range[1],
    },
  });

  useEffect(() => {
    if (getclientaccountdetails.data != undefined) {
      const newData = getclientaccountdetails.data?.data?.results;
      const filterData = newData.filter(
        (item) => item.is_show === true && item.credit >= 0 && item.debit >= 0
      );
      settabledata(filterData);
      setCount(getclientaccountdetails.data?.data?.count);
      setbalance(filterData[0]?.balance || 0);
    }
  }, [getclientaccountdetails.data, pageindex, pagesize, search]);

  const columns = useMemo(
    () => [
      columnHelper.accessor((row) => row, {
        id: "receipt_date",
        header: "Date",
        sortingFn: "basic",
        cell: (info) => {
          return (
            <span>
              {dayjs(info.row.original.recpit_date).format("DD/MM/YYYY")}
            </span>
          );
        },
      }),
      columnHelper.accessor((row) => row, {
        id: "payer_name",
        header: "Name",
        sortingFn: "basic",
        cell: (info) => <span>{info.row.original.payer_name[0]}</span>,
      }),
      columnHelper.accessor((row) => row, {
        id: "type",
        header: "Type",
        sortingFn: "basic",
        cell: (info) => <span>{info.row.original.type}</span>,
      }),
      columnHelper.accessor((row) => row, {
        id: "debit",
        header: "In",
        sortingFn: "basic",
        cell: (info) => (
          <span>{`£${formatPounds(info.row.original.credit)}`}</span>
        ),
      }),

      columnHelper.accessor((row) => row, {
        id: "credit",
        header: "Out",
        sortingFn: "basic",
        cell: (info) => (
          <span>{`£${formatPounds(info.row.original.debit)}`}</span>
        ),
      }),
      columnHelper.accessor((row) => row, {
        id: "balance",
        header: "Balance",
        sortingFn: "basic",
        cell: (info) => (
          <span>{`£${formatPounds(info.row.original.balance)}`}</span>
        ),
      }),
    ],
    []
  );
  return (
    <div className="client">
      <div className="cli_acc_button">
        <div className="cliaccHead">
          <h1>Client Account</h1>
          <div className="searchClientAmount">
            <Search
              setsearch={setsearch}
              style={{ width: "100%" }}
              setPageIndex={setPageIndex}
              placeholder={"Account"}
            />
          </div>
        </div>
        <div className="table_headers">
          <div className="card">
            <div
              style={{
                display: "flex",
                justifyContent: "space-around",
                alignItems: "center",
                // gap: "4px",
                // width: "55%",
              }}
            >
              <img src={wallet} alt="wallet" style={{ marginRight: "20px" }} />
              <p>Client Balance</p>
            </div>
            <h1>£{formatPounds(Balance)} </h1>
          </div>
          <div>
            {/* <DateRange style={{ width: "200px" }} />
             */}
            <DateRange
              onChange={(e) => {
                setRange(e);
              }}
              minDate={new Date(2020, 0, 1)}
              maxDate={new Date()}
              style={{ width: "250px" }}
            />
          </div>
        </div>
      </div>
      <div className="main_content">
        <div
          style={{
            width: "100%",
            height: "calc(100% - 15px)",
          }}
        >
          {getclientaccountdetails.isFetching ? (
            <div className="skelitonDiv">
              <Skeleton active style={{ height: "98px" }} rows={1} />
            </div>
          ) : (
            <TableComponent
              columns={columns}
              data={tabledata}
              count={count}
              pageindex={pageindex}
              pagesize={pagesize}
              setPageIndex={setPageIndex}
              setPageSize={setPageSize}
              // onclickrow={row => console.log(row)}
            />
          )}
        </div>
      </div>
    </div>
  );
}
