// import * as React from "react";
// import solace from "../Assets/Common/solace_short.svg";
// import "./style/Loader.scss";

// export default function CircularIndeterminate() {
//   return (
//     <div
//       className="loadingClass"
//       style={{
//         width: "100%",
//         height: "100%",
//         background: "rgb(247,249,251, 1)",
//         display: "flex",
//         justifyContent: "center",
//         alignItems: "center",
//         zIndex: "10000000000",
//         position: "absolute",
//         top: "0",
//         right: "0",
//         opacity: "0.5",
//         transition: "opacity 5s ease, width 0.5s ease",
//         // left: '120px',
//         // left: '80px',
//       }}
//     >
//       <img
//         src={solace}
//         alt="solace"
//         style={{
//           width: "70px",
//         }}
//       />
//     </div>
//   );
// }

import * as React from "react";
import solace from "../Assets/Common/solace_short.svg";
import "./style/Loader.scss";
import { useSelector } from "react-redux";

export default function CircularIndeterminate() {
  const isLoading = useSelector((state) => state.counter.loader);

  return (
    <div className={`loadingClass ${isLoading ? "visible" : "hidden"}`}>
      <img
        src={solace}
        alt="solace"
        style={{
          width: "70px",
        }}
      />
    </div>
  );
}
