import { useEffect, useState } from "react";
import "../../StyleSheet/dashboard/arrears.scss";
import axios from "axios";
import ReactEcharts from "echarts-for-react";
import { useArrears, useFetchArrearsSelected } from "../../../Api/homeQuery";
import { loginuserdata } from "../../../redux/reducer/counterslice";
import { useSelector } from "react-redux";
import ArrearsPopup from "./popups/arrearspopup";
import { formatPounds, formatPoundsNoDec } from "../../../Utils/Utils";
export default function ArrearsHome() {
  const [Data, setData] = useState([]);
  const [popupstate, setPopupstate] = useState(false);
  const [popuptext, setPopuptext] = useState("");
  const [activeTab, setActiveTab] = useState("0 - 7 Days");

  const clientid = useSelector((state) => state.counter.clientid);

  const getarrears = useArrears({
    params: { id: clientid },
    loading: false,
  });

  useEffect(() => {
    // console.log(getarrears.data, "ajdahkjadhkja");
    if (getarrears.data != undefined) {
      setData(getarrears.data.data[0]);
    }
  }, [getarrears.data]);

  const option = {
    series: [
      {
        name: "Arrears",
        type: "pie",
        radius: ["48%", "90%"],
        avoidLabelOverlap: false,
        itemStyle: {
          borderRadius: 8,
          borderColor: "#fff",
          borderWidth: 3,
        },
        label: {
          show: false,
          position: "center",
        },

        labelLine: {
          show: false,
        },
        data: [
          {
            value: Data?.charges_0_7_days || "",
            name: "0-7 Days",
            itemStyle: { color: "#000047" },
            type: "0 - 7 Days",
          },
          {
            value: Data?.charges_8_14_days || "",
            name: "8-14 Days",
            itemStyle: { color: "#4DDE87" },
            type: "8 - 14 Days",
          },
          {
            value: Data?.charges_15_30_days || "",
            name: "15-30 Days",
            itemStyle: { color: "#4FC3F4" },
            type: "15 - 30 Days",
          },
          {
            value: Data?.charges_31_plus_days || "",
            name: "31Days+",
            itemStyle: { color: "#817EFF" },
            type: "31_plus_days",
          },
        ],
      },
    ],
  };

  const onChartClick = (params) => {
    // console.log("Chart clicked", params);
    setPopupstate(true);
    setActiveTab(params.data.type);
  };
  const onEvents = {
    click: onChartClick,
  };

  return (
    <>
      <div className="arrears_main_wrapper">
        <div className="arrear_header">
          <h1>Arrears</h1>

          <button
            className="viewAll"
            onClick={(e) => {
              setPopupstate(true);
              setActiveTab("All");
            }}
          >
            View All
          </button>
        </div>
        <div className="graph_wrapper">
          <div className="graph" style={{ position: "relative", top: "13px" }}>
            <ReactEcharts
              style={{ width: "100%", height: "100%", top: "0px" }}
              option={option}
              onEvents={onEvents}
            />
          </div>

          <ul>
            <li
              className={
                Data?.charges_0_7_days > 0 ? "animated" : "notAnimated"
              }
              onClick={(e) => {
                if (Data?.charges_0_7_days > 0) {
                  setPopupstate(true);
                  setActiveTab("0 - 7 Days");
                }
              }}
            >
              <span>
                <div className="badge07"></div>
                {"0 - 7 Days"}
              </span>
              <p>{`£${formatPoundsNoDec(Data?.charges_0_7_days)}`}</p>
            </li>
            <li
              className={
                Data?.charges_8_14_days > 0 ? "animated" : "notAnimated"
              }
              onClick={(e) => {
                if (Data?.charges_8_14_days > 0) {
                  setPopupstate(true);
                  setActiveTab("8 - 14 Days");
                }
              }}
            >
              <span>
                <div className="badge814"></div>
                {"8 - 14 Days"}
              </span>
              <p>{`£${formatPoundsNoDec(Data?.charges_8_14_days)}`}</p>
            </li>
            <li
              className={
                Data?.charges_15_30_days > 0 ? "animated" : "notAnimated"
              }
              onClick={(e) => {
                if (Data?.charges_15_30_days) {
                  setPopupstate(true);
                  setActiveTab("15 - 30 Days");
                }
              }}
            >
              <span>
                <div className="badge1530"></div>
                {"15 - 30 Days"}
              </span>
              <p>{`£${formatPoundsNoDec(Data?.charges_15_30_days)}`}</p>
            </li>
            <li
              className={
                Data?.charges_31_plus_days > 0 ? "animated" : "notAnimated"
              }
              onClick={(e) => {
                if (Data?.charges_31_plus_days > 0) {
                  setPopupstate(true);
                  setActiveTab("31+ Days");
                }
              }}
            >
              <span>
                <div className="badge31"></div>
                {"31+ Days"}
              </span>
              {/* <p>{`£${Data?.charges_31_plus_days?.toFixed(0) || 0}`}</p> */}
              <p>{`£${formatPoundsNoDec(Data?.charges_31_plus_days)}`}</p>
            </li>
          </ul>
        </div>
      </div>
      {/* {popupstate && (
        <> */}
      <ArrearsPopup
        setActiveTab={setActiveTab}
        activeTab={activeTab}
        setPopupstate={setPopupstate}
        popuptext={popuptext}
        dataFromData={Data}
        popupstate={popupstate}
      />
    </>
  );
}
