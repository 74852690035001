import "./style.scss";
export default function UploadButton(props) {
  const { handleClick = () => {} } = props;
  return (
    <button
      className="upload_button"
      onClick={() => {
        handleClick();
      }}
    >
      <span>Upload</span>&nbsp;
      <i className="bi bi-plus"></i>
    </button>
  );
}
