import "../../../StyleSheet/Property/popups/propertydetails.scss";
import Money from "../../../../Assets/Common/Money.svg";
import defaultPropertyalt from "../../../../Assets/Common/defaultPropertyalt.svg";
import { useEffect, useMemo, useState } from "react";
import TableComponent from "../../../GlobalComponents/table-v8/reacttable";
import { createColumnHelper } from "@tanstack/react-table";
import Search from "../../../../MainComponent/fields/search";
import GenerateReport from "../../../../MainComponent/fields/generatereport";
import {
  useFetchDetails,
  useFetchMaintenancePropertySpecific,
  useFetchPropertiesCompliance,
  useFetchPropertyTransaction,
} from "../../../../Api/PropertiesQuery";
import dayjs from "dayjs";
import { formatAddress, formatPounds } from "../../../../Utils/Utils";
import PropertyPropertyContacts from "./PropertyPropertyContacts";
import UploadPropertyDocument from "./UploadPropertyDocument";
import { useDispatch, useSelector } from "react-redux";
import { setPropertyDetailsFilter } from "../../../../redux/reducer/counterslice";
import Documents from "./ComplianceDocument";
import ArchivePopup from "./ArchivePopup";
import { useFetchPropertyExpenditure } from "../../../../Api/ContactsQuery";
import expenditure from "../../../../Assets/Common/expenditure.png";
import grossIncome from "../../../../Assets/Common/grossIncome.png";
import income from "../../../../Assets/Common/income.png";
import closeSolidIcon from "../../../../Assets/Common/closeSolidIcon.svg";
import { Button } from "antd";
import GeneratePeriodicStatement from "../../Contacts/Popup/generatePeriodicStatement";

export default function Propertydetails(props) {
  const { rowvalue, propertyPopup, fromContact = false } = props;
  const columnHelper = createColumnHelper();
  const dispatch = useDispatch();
  const [search, setsearch] = useState("");
  const [Generate, setGenerate] = useState("");
  const [propertyApprovalFolder, setPropertyApprovalFolder] = useState("");
  const [count, setCount] = useState(0);
  const [pageindex, setPageIndex] = useState(1);
  const [pagesize, setPageSize] = useState(10);
  const [archivePopup, setArchivePopup] = useState(false);
  const [selectedArchivedData, setSelectedArchivedData] = useState({});
  const [payloadForArchiveApi, setPayloadForArchiveApi] = useState({});
  const [selectedExpenditureType, setSelectedExpenditureType] = useState("all");
  const [tableData, setTableData] = useState([]);
  const tagactive = useSelector((state) => state.counter.propertyDetailsFilter);
  const [propertyData, setPropertyData] = useState([]);
  const [propertyCompliance, setPropertyCompliance] = useState([]);
  const [tenancyCompliance, setTenancyCompliance] = useState([]);
  const [generatePeriodicStatement, setGeneratePeriodicStatement] =
    useState(false);

  const fetchMaintenanceProperties = useFetchMaintenancePropertySpecific({
    id: rowvalue?.id,
    page: pageindex,
    page_size: pagesize,
    search: search,
    type: selectedExpenditureType,
  });
  const fetchComplianceMaster = useFetchDetails({
    property: rowvalue?.id,
    client: rowvalue?.client?.id,
    tenant: fromContact
      ? rowvalue?.tenancies?.[0]?.tenancies?.[0]?.tenant?.id
      : rowvalue?.tenant?.tenant__id,
  });
  const fetchPropertyDetails = useFetchPropertiesCompliance(rowvalue?.id);
  const fetchPropertyPropertyTransactionDetails = useFetchPropertyTransaction({
    property: rowvalue?.id,
    page: pageindex,
    page_size: pagesize,
    search: search,
  });
  const fetchPropertyExpenditureData = useFetchPropertyExpenditure({
    params: {
      property: rowvalue?.id,
      page: pageindex,
      page_size: pagesize,
      search: search,
      type: selectedExpenditureType,
    },
  });

  const handlebuttonclick = (e) => {
    dispatch(setPropertyDetailsFilter(e.target.id));
  };

  const columns = useMemo(
    () => [
      columnHelper.accessor("date", {
        header: "Reported Date",
        sortingFn: "basic",

        cell: (info) => {
          const data = info.row.original?.created_date;
          return (
            <span
              style={{
                wordBreak: "break-all",
                textTransform: "capitalize",
                fontSize: "clamp(12px, 1.1vw, 28px)",
              }}
            >
              {dayjs(data).format("DD/MM/YYYY")}
            </span>
          );
        },
      }),
      columnHelper.accessor((row) => row, {
        id: "status",
        sortingFn: "basic",

        cell: (info) => {
          console.log(info.getValue());
          const data = info.row.original.status;

          return (
            <div
              style={{
                width: "195px",
                display: "flex",
                justifyContent: "flex-start",
                alignItems: "center",
                padding: "8px 0px",
                borderRadius: "10px",
                fontWeight: "500",
                fontSize: "clamp(12px, 1.1vw, 28px)",
                gap: "6px",
                marginLeft: "6px",
              }}
            >
              <span
                style={{
                  width: "10px",
                  height: "10px",
                  borderRadius: "2px",
                  background:
                    data === "Quote Requested"
                      ? "#FFD47C"
                      : data === "New Requests"
                      ? "#97E9AC"
                      : data === "Awaiting payment"
                      ? "#FFB693"
                      : data === "Awaiting Approval"
                      ? "#FF8485"
                      : data === "Approved"
                      ? "#7DD1F3"
                      : data === "Booked"
                      ? "#8889F4"
                      : data === "Rejected"
                      ? "#EFA6A6"
                      : data === "Resolved"
                      ? "#A6EFC3"
                      : data === "More Info"
                      ? "#AC7AFF"
                      : "",
                }}
              ></span>
              <span
                style={{
                  fontSize: "clamp(12px, 1.1vw, 28px)",
                  // textWrap: "nowrap",
                  lineHeight: "1.1vw",
                }}
              >
                {data}
              </span>
            </div>
          );
        },
        header: () => <span>Status</span>,
      }),
      columnHelper.accessor("summary", {
        header: "Summary",
        cell: (info) => {
          const data = info.row.original?.title;
          return (
            <span
              style={{
                wordBreak: "break-all",
                textTransform: "capitalize",
                fontSize: "clamp(12px, 1.1vw, 28px)",
              }}
            >
              {data}
            </span>
          );
        },
      }),
      columnHelper.accessor("description", {
        header: "Description",
        cell: (info) => {
          const data = info.row.original?.description;
          return (
            <span
              style={{
                wordBreak: "break-all",
                textTransform: "capitalize",
                fontSize: "clamp(12px, 1.1vw, 28px)",
                maxWidth: "350px",
                display: "flex",
              }}
            >
              {data}
            </span>
          );
        },
      }),
      columnHelper.accessor("quote", {
        header: "Quote",
        cell: (info) => {
          const data = info.row.original?.invoice_amount;
          return (
            <span
              style={{
                wordBreak: "break-all",
                textTransform: "capitalize",
                fontSize: "clamp(12px, 1.1vw, 28px)",
              }}
            >
              {data > 0 ? `£${formatPounds(data)}` : "--"}
            </span>
          );
        },
      }),
    ],
    []
  );
  const tenancyColumn = useMemo(
    () => [
      columnHelper.accessor((row) => row, {
        header: "Tenant",
        sortingFn: "basic",

        cell: (info) => {
          const data = info.row.original?.tenant?.contact_detail?.[0];
          return (
            <span
              style={{
                wordBreak: "break-all",
                textTransform: "capitalize",
                fontSize: "clamp(12px, 1.1vw, 28px)",
              }}
            >
              {data?.title} {data?.first_name} {data?.last_name}
            </span>
          );
        },
      }),

      columnHelper.accessor((row) => row, {
        header: "Start Date",
        sortingFn: "basic",

        cell: (info) => {
          const data = info.row.original?.start_date;
          return (
            <span
              style={{
                wordBreak: "break-all",
                textTransform: "capitalize",
                fontSize: "clamp(12px, 1.1vw, 28px)",
              }}
            >
              {dayjs(data).format("DD/MM/YYYY")}
            </span>
          );
        },
      }),
      columnHelper.accessor((row) => row, {
        header: "End Date",
        sortingFn: "basic",

        cell: (info) => {
          const data = info.row.original?.end_date;
          return (
            <span
              style={{
                wordBreak: "break-all",
                textTransform: "capitalize",
                fontSize: "clamp(12px, 1.1vw, 28px)",
              }}
            >
              {dayjs(data).format("DD/MM/YYYY")}
            </span>
          );
        },
      }),
      columnHelper.accessor((row) => row, {
        header: "Rent",
        sortingFn: "basic",

        cell: (info) => {
          const data = info.row.original?.rent;
          return (
            <span
              style={{
                wordBreak: "break-all",
                textTransform: "capitalize",
                fontSize: "clamp(12px, 1.1vw, 28px)",
              }}
            >
              £{formatPounds(data)}
            </span>
          );
        },
      }),
      columnHelper.accessor((row) => row, {
        header: "Status",
        sortingFn: "basic",

        cell: (info) => {
          const data = info.row.original?.status;
          return (
            <span
              style={{
                wordBreak: "break-all",
                textTransform: "capitalize",
                fontSize: "clamp(12px, 1.1vw, 28px)",
                background: data === "Periodic" ? "#5EFF9F" : "",
              }}
            >
              {data}
            </span>
          );
        },
      }),
    ],
    []
  );
  const transactionColumn = useMemo(
    () => [
      columnHelper.accessor((row) => row, {
        header: "Charge Date",
        sortingFn: "basic",

        cell: (info) => {
          const data = info.row.original?.charge_date;
          return (
            <span
              style={{
                wordBreak: "break-all",
                textTransform: "capitalize",
                fontSize: "clamp(12px, 1.1vw, 28px)",
              }}
            >
              {dayjs(data).format("DD/MM/YYYY")}
            </span>
          );
        },
      }),

      columnHelper.accessor((row) => row, {
        header: "Description",
        sortingFn: "basic",

        cell: (info) => {
          const data = info.row.original?.description;
          return (
            <span
              style={{
                wordBreak: "break-all",
                textTransform: "capitalize",
                fontSize: "clamp(12px, 1.1vw, 28px)",
              }}
            >
              {data}
            </span>
          );
        },
      }),

      columnHelper.accessor((row) => row, {
        header: "Pay In",
        sortingFn: "basic",

        cell: (info) => {
          const data = info.row.original?.credit;
          return (
            <span
              style={{
                wordBreak: "break-all",
                textTransform: "capitalize",
                fontSize: "clamp(12px, 1.1vw, 28px)",
                color: data > 0 ? "green" : data < 0 ? "red" : "black",
              }}
            >
              £{formatPounds(data)}
            </span>
          );
        },
      }),
      columnHelper.accessor((row) => row, {
        header: "Pay Out",
        sortingFn: "basic",

        cell: (info) => {
          const data = info.row.original?.debit;
          return (
            <span
              style={{
                wordBreak: "break-all",
                textTransform: "capitalize",
                fontSize: "clamp(12px, 1.1vw, 28px)",
                color: data > 0 ? "green" : data < 0 ? "red" : "black",
              }}
            >
              £{formatPounds(data)}
            </span>
          );
        },
      }),
      columnHelper.accessor((row) => row, {
        header: "Balance",
        sortingFn: "basic",

        cell: (info) => {
          const data = info.row.original?.balance;
          return (
            <span
              style={{
                wordBreak: "break-all",
                textTransform: "capitalize",
                fontSize: "clamp(12px, 1.1vw, 28px)",
                color: data > 0 ? "green" : data < 0 ? "red" : "black",
              }}
            >
              £{formatPounds(data)}
            </span>
          );
        },
      }),
      // columnHelper.accessor((row) => row, {
      //   header: "Type",
      //   sortingFn: "basic",

      //   cell: (info) => {
      //     const data = info.row.original?.rent;
      //     return (
      //       <span
      //         style={{
      //           wordBreak: "break-all",
      //           textTransform: "capitalize",
      //           fontSize: "18px",
      //         }}
      //       >
      //         £{formatPounds(data)}
      //       </span>
      //     );
      //   },
      // }),
    ],
    []
  );
  const incomeAndExpenditure = useMemo(
    () => [
      columnHelper.accessor((row) => row, {
        header: "Charge Date",
        sortingFn: "basic",

        cell: (info) => {
          const data = info.row.original?.charge_date;
          return (
            <span
              style={{
                wordBreak: "break-all",
                textTransform: "capitalize",
                fontSize: "clamp(12px, 1.1vw, 28px)",
              }}
            >
              {dayjs(data).format("DD/MM/YYYY")}
            </span>
          );
        },
      }),

      columnHelper.accessor((row) => row, {
        header: "Description",
        sortingFn: "basic",

        cell: (info) => {
          const data = info.row.original?.description;
          return (
            <span
              style={{
                wordBreak: "break-all",
                textTransform: "capitalize",
                fontSize: "clamp(12px, 1.1vw, 28px)",
              }}
            >
              {data}
            </span>
          );
        },
      }),

      columnHelper.accessor((row) => row, {
        header: "Amount",
        sortingFn: "basic",

        cell: (info) => {
          const debit = info.row.original?.debit;
          const credit = info.row.original?.credit;
          return (
            <span
              style={{
                wordBreak: "break-all",
                textTransform: "capitalize",
                fontSize: "clamp(12px, 1.1vw, 28px)",
                color: credit > 0 ? "green" : "red",
              }}
            >
              {`£${formatPounds(credit || debit)}`}
            </span>
          );
        },
      }),
    ],
    []
  );

  useEffect(() => {
    if (fetchPropertyDetails.data?.data) {
      setPropertyData(fetchPropertyDetails.data.data);
      setPropertyApprovalFolder(fetchPropertyDetails.data.data?.document_types);
    }
  }, [fetchPropertyDetails.data]);

  useEffect(() => {
    if (
      tagactive == "2" &&
      fetchComplianceMaster.data?.data?.property_documents_data
    ) {
      console.log(fetchComplianceMaster.data?.data, "jalsdjflajsldjf");
      setPropertyCompliance(
        fetchComplianceMaster.data?.data?.property_documents_data
      );
      setTenancyCompliance(
        fetchComplianceMaster.data?.data?.tenant_tenancy_files_data
      );
    }
  }, [
    fetchComplianceMaster.data?.data,
    pagesize,
    pageindex,
    search,
    tagactive,
  ]);
  useEffect(() => {
    if (fetchMaintenanceProperties.data?.data?.results && tagactive === "3") {
      setTableData(fetchMaintenanceProperties.data?.data?.results);
      setCount(fetchMaintenanceProperties.data?.data?.count);
    }
  }, [fetchMaintenanceProperties.data, tagactive, pageindex, pagesize]);

  useEffect(() => {
    if (
      tagactive === "5" &&
      fetchPropertyPropertyTransactionDetails.data?.data
    ) {
      setTableData(fetchPropertyPropertyTransactionDetails.data?.data?.results);
      setCount(fetchPropertyPropertyTransactionDetails.data?.data?.count);
    }
  }, [
    fetchPropertyPropertyTransactionDetails.data,
    tagactive,
    pageindex,
    pagesize,
  ]);
  useEffect(() => {
    if (fetchPropertyExpenditureData.data?.data && tagactive === "7") {
      setTableData(fetchPropertyExpenditureData.data?.data?.data);
      setCount(fetchPropertyExpenditureData.data?.data?.count);
    }
  }, [
    fetchMaintenanceProperties.data,
    tagactive,
    pageindex,
    pagesize,
    selectedExpenditureType,
  ]);

  const cardData = [
    {
      title: "Net Amount",
      amount: formatPounds(
        fetchPropertyExpenditureData.data?.data?.total_credit -
          fetchPropertyExpenditureData.data?.data?.total_debit || 0
      ),
      imgSrc: grossIncome,
      type: "all",
    },
    {
      title: "Total Income",
      amount: formatPounds(
        fetchPropertyExpenditureData.data?.data?.total_credit || 0
      ),
      imgSrc: income,
      type: "income",
    },
    {
      title: "Total Expenditure",
      amount: formatPounds(
        fetchPropertyExpenditureData.data?.data?.total_debit || 0
      ),
      imgSrc: expenditure,
      type: "expense",
    },
  ];

  return (
    <div
      className={`popup_main_wrapper ${
        propertyPopup === true ? "popup-open" : "popup-close"
      } `}
    >
      <div
        className={`content_wrapper ${
          propertyPopup === true ? "popup-open" : "popup-close"
        } `}
      >
        <div className="contact_popup_header">
          <div
            className="header_content-wrapper"
            style={{ position: "relative" }}
          >
            <div className="contact_popup_head_img">
              <img
                src={
                  // rowvalue?.media?.[0]?.url
                  //   ? rowvalue?.media?.[0]?.url
                  //   : rowvalue?.image?.[0]
                  //   ? rowvalue?.image?.[0]
                  //   : defaultPropertyalt
                  rowvalue?.media?.[0]?.url ||
                  rowvalue?.image?.[0] ||
                  defaultPropertyalt
                }
              ></img>
            </div>
            <div className="contact_title_wrapper">
              <div className="contact_pop_head_text">
                <h1 style={{ textTransform: "capitalize", fontWeight: "400" }}>
                  {formatAddress([
                    rowvalue.name + " " + rowvalue.street,
                    rowvalue.city,
                    rowvalue.postcode,
                  ])}
                </h1>
              </div>

              <div className="contact_buttons_cover">
                <button
                  id={"1"}
                  onClick={handlebuttonclick}
                  className={tagactive == "1" ? "active" : "inActive"}
                >
                  Contact Details
                </button>
                <button
                  id={"2"}
                  onClick={(e) => {
                    handlebuttonclick(e);
                    setPropertyCompliance(
                      fetchComplianceMaster.data?.data?.property_documents_data
                    );
                    setTenancyCompliance(
                      fetchComplianceMaster.data?.data
                        ?.tenant_tenancy_files_data
                    );
                  }}
                  className={tagactive === "2" ? "active" : "inActive"}
                >
                  {" "}
                  Documents
                </button>
                <button
                  id={"7"}
                  onClick={(e) => {
                    handlebuttonclick(e);
                    console.log(
                      fetchPropertyExpenditureData.data?.data?.data,
                      "ajlsdfklouoin3ejkjaseofjdfil"
                    );

                    setTableData(fetchPropertyExpenditureData.data?.data?.data);
                    setCount(fetchPropertyExpenditureData.data?.data?.count);
                  }}
                  className={tagactive === "7" ? "active" : "inActive"}
                >
                  Income and Expenditure
                </button>
                <button
                  id={"3"}
                  onClick={(e) => {
                    handlebuttonclick(e);
                    setTableData(
                      fetchMaintenanceProperties.data?.data?.results
                    );
                    setCount(fetchMaintenanceProperties.data?.data?.count);
                  }}
                  className={tagactive === "3" ? "active" : "inActive"}
                >
                  Maintenance
                </button>

                <button
                  id={"4"}
                  onClick={(e) => {
                    handlebuttonclick(e);
                    setTableData(propertyData.tenancies?.[0]?.tenancies);
                  }}
                  className={tagactive === "4" ? "active" : "inActive"}
                >
                  Tenancies
                </button>
                <button
                  id={"5"}
                  className={tagactive === "5" ? "active" : "inActive"}
                  onClick={(e) => {
                    console.log(
                      fetchPropertyPropertyTransactionDetails.data?.data
                        ?.results,
                      "aojsdjflasfdaljflajs"
                    );
                    handlebuttonclick(e);
                  }}
                >
                  Transactions
                </button>
                <button
                  id={"6"}
                  onClick={handlebuttonclick}
                  className={tagactive === "6" ? "active" : "inActive"}
                >
                  Upload Documents
                </button>
                {/* {arrButton.map((e, index) => {
                  return (
                    <button
                      id={index + 1}
                      key={e}
                      onClick={handlebuttonclick}
                      className={tagactive == { index } ? "active" : "inActive"}
                    >
                      {e}
                    </button>
                  );
                })} */}
              </div>
              {/* {tagactive == "3" && (
                <div className="com_table_props">
                  <Search
                    setsearch={setsearch}
                    style={{ width: "200px", height: "1.6rem" }}
                    setPageIndex={setPageIndex}
                  />
                  <GenerateReport setGenerate={setGenerate} />
                </div>
              )} */}
            </div>
            <div
              className="close_button_wrapper"
              style={{
                position: "absolute",
                top: "0",
                right: "0",
                paddingBottom: "0px",
              }}
            >
              <img
                className="close"
                style={{ borderRadius: "0px" }}
                onClick={(e) => {
                  props.setpopup(false);
                  // props.setSelectedLandLordId("");
                }}
                src={closeSolidIcon}
              ></img>
            </div>
          </div>
        </div>
        {tagactive == "3" && (
          <div className="com_table_props" style={{ width: "100%" }}>
            <Search
              setsearch={setsearch}
              style={{ width: "100%", height: "1.6rem" }}
              setPageIndex={setPageIndex}
            />
            <GenerateReport setGenerate={setGenerate} />
          </div>
        )}
        <div className="contact_body_content_data">
          {tagactive == "2" ? (
            <div className="doc_con_cover">
              <Documents
                title="Property Documents"
                documents={propertyCompliance}
                setSelectedArchivedData={setSelectedArchivedData}
                setArchivePopup={setArchivePopup}
                setPayloadForArchiveApi={setPayloadForArchiveApi}
                type={"property"}
                rowvalue={rowvalue}
              />
              <Documents
                title="Tenancy Documents"
                documents={tenancyCompliance}
                setSelectedArchivedData={setSelectedArchivedData}
                setArchivePopup={setArchivePopup}
                setPayloadForArchiveApi={setPayloadForArchiveApi}
                type={"tenancy"}
                rowvalue={rowvalue}
              />
            </div>
          ) : tagactive == "1" ? (
            <PropertyPropertyContacts
              dataFromParent={rowvalue}
              fromContact={fromContact}
            />
          ) : tagactive == "6" ? (
            <UploadPropertyDocument
              dataFromParent={rowvalue}
              propertyApprovalFolderId={propertyApprovalFolder}
            />
          ) : (
            <div className="con_popuptable" style={{ height: "100%" }}>
              {tagactive == "7" && (
                <div
                  className="con_incom_cards con_landlord_popup_cards"
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    gap: "20px",
                    width: "97%",
                    margin: "0 10px",
                    height: "auto",
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      gap: "10px",
                      width: "80%",
                      maxWidth: "1500px",
                    }}
                  >
                    {cardData.map((card, index) => (
                      <div
                        className={`landlordPopupCard ${
                          selectedExpenditureType === card.type ? "active" : ""
                        }`}
                        key={index}
                        onClick={() => {
                          setSelectedExpenditureType(card.type);
                        }}
                        // style={{ width: "max-content" }}
                        style={{
                          width: "100%",
                        }}
                      >
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                          }}
                        >
                          <p>{card.title}</p>
                          <img
                            src={card.imgSrc}
                            alt={card.title}
                            style={{
                              width: "max(16px, 2.3vw)",
                              height: "max(16px, 2.3vw)",
                            }}
                          />
                        </div>
                        <h1>£{card.amount}</h1>
                      </div>
                    ))}
                  </div>
                  {/* <div
                    className=""
                    style={{
                      alignItems: "flex-end",
                      display: "flex",
                      height: "100%",
                    }}
                  >
                    <button
                      style={{
                        backgroundColor: "rgb(129, 126, 255)",
                        padding: "10px 15px",
                        borderRadius: "5px",
                        cursor: "pointer",
                        color: "white",
                        fontWeight: "500",
                        fontSize: "13px",
                        width: "max-content",
                        ":hover": {
                          backgroundColor: "rgb(129, 126, 255, 0.7)",
                        },
                      }}
                      onClick={() => {
                        setGeneratePeriodicStatement(true);
                      }}
                    >
                      Generate Statement
                    </button>
                  </div> */}
                </div>
              )}
              {tagactive == "5" && (
                <div
                  style={{
                    width: "100%",
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  <div
                    className="accountBalanceCard"
                    style={{
                      background: "#EEEEFF",
                      marginRight: "35px",
                    }}
                  >
                    <div className="wrapper">
                      <img
                        src={Money}
                        alt="wallet"
                        style={{
                          width: "clamp(14px, 2.2vw, 40px)",
                          height: "clamp(16px, 2.3vw, 48px)",
                        }}
                      />
                      <span
                        style={{
                          fontSize: "clamp(13px, 1.4vw, 34px)",
                          fontWeight: "500",
                          width: "max-content",
                          marginRight: "10px",
                        }}
                      >
                        Account Balance
                      </span>
                    </div>

                    <span>
                      <span
                        style={{
                          fontSize: "clamp(15px, 1.6vw, 40px)",
                          color:
                            tableData?.[0]?.balance > 0
                              ? "green"
                              : tableData?.[0]?.balance < 0
                              ? "red"
                              : "black",
                          fontWeight: "300",
                        }}
                      >
                        £{formatPounds(tableData ? tableData?.[0]?.balance : 0)}
                      </span>
                    </span>
                  </div>
                  <div
                    className=""
                    style={{
                      alignItems: "flex-end",
                      display: "flex",
                      height: "100%",
                    }}
                  >
                    <button
                      style={{
                        backgroundColor: "rgb(129, 126, 255)",
                        padding: "10px 15px",
                        borderRadius: "5px",
                        cursor: "pointer",
                        color: "white",
                        fontWeight: "500",
                        fontSize: "13px",
                        width: "max-content",
                        ":hover": {
                          backgroundColor: "rgb(129, 126, 255, 0.7)",
                        },
                      }}
                      onClick={() => {
                        setGeneratePeriodicStatement(true);
                      }}
                    >
                      Generate Statement
                    </button>
                  </div>
                </div>
              )}
              <TableComponent
                data={tableData ? tableData : []}
                columns={
                  tagactive == "4"
                    ? tenancyColumn
                    : tagactive == "5"
                    ? transactionColumn
                    : tagactive == "7"
                    ? incomeAndExpenditure
                    : columns
                }
                count={count}
                pageindex={pageindex}
                pagesize={pagesize}
                setPageIndex={setPageIndex}
                setPageSize={setPageSize}
              />
            </div>
          )}
        </div>
      </div>
      {payloadForArchiveApi !== "" && (
        <ArchivePopup
          isOpen={archivePopup}
          setIsOpen={setArchivePopup}
          dataFromParent={selectedArchivedData}
          payloadForArchiveApi={payloadForArchiveApi}
        />
      )}
      {generatePeriodicStatement && (
        <GeneratePeriodicStatement
          isOpen={generatePeriodicStatement}
          setIsOpen={setGeneratePeriodicStatement}
          type={"property"}
          propertyName={formatAddress([
            rowvalue.name + " " + rowvalue.street,
            rowvalue.city,
            rowvalue.postcode,
          ])}
          propertyId={rowvalue?.id}
          propertyData={rowvalue}
        />
      )}
    </div>
  );
}
