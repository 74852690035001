import React, { useCallback, useEffect, useState } from "react";
import logo from "./Assests/logo.svg";
import Search from "../../../MainComponent/fields/search";
import deleteIcon from "./Assests/delete.svg";
import pdfIcon from "./Assests/pdf.svg";
import greater from "./Assests/greater.svg";
import Dropzone, { useDropzone } from "react-dropzone";
import { useNavigate, useParams } from "react-router-dom";
import dragFile from "./Assests/dragfile.svg";
import ConformPopup from "./ConfirmPopup";
import "./Styles/InitialProperty.scss";
import {
  usePresignedUrlPost,
  usePresignedUrlPut,
  usePropertyFilePost,
} from "../../../Api/PropertiesQuery";
import { UseDeletePropertyDoc } from "../../../Api/InitialSetupQuery";
import { useDispatch, useSelector } from "react-redux";
import { setPropertyFileFilter } from "../../../redux/reducer/counterslice";
import { addLeadingZero } from "../../../Utils/Utils";
var filesloc = [];
var presignedURLloc = [];

const FileUploadView = ({ dataFromParent, setSearch, search }) => {
  const navigate = useNavigate();
  const [files, setFiles] = useState([]);
  const [pageIndex, setPageIndex] = useState(1);
  const [presignedURL, setPresignedURL] = useState("");
  const [confirmGas, setConfirmGas] = useState(false);
  const [selectedId, setSelectedId] = useState("");
  const dispatch = useDispatch();
  useEffect(() => {
    console.log(dataFromParent, "dataFromParent");
  }, [dataFromParent]);

  const [PresignedUrlPayload, setPresignedUrlPayload] = useState({
    multiple_files: [],
  });
  const propertyFileFilter = useSelector(
    (state) => state.counter.propertyFileFilter
  );
  const initialSelectedProperty = useSelector(
    (state) => state.counter.initialSelectedProperty
  );
  const { propertyId, id } = useParams();

  const [finalData, setFinalData] = useState("");
  const [uploadProgress, setUploadProgress] = useState({});
  const createPresignedURL = usePresignedUrlPost(PresignedUrlPayload);
  const propertyFilePost = usePropertyFilePost(finalData);
  const propertyFileDelete = UseDeletePropertyDoc(() => {
    setConfirmGas(false);
  });
  useEffect(() => {
    console.log(propertyFileFilter, "lkjasldjflajsdfjaslf");
  }, [propertyFileFilter]);

  useEffect(() => {
    dataFromParent.map((files) => {
      setUploadProgress((prev) => ({
        ...prev,
        [files.custom_name]: 0,
      }));
    });
  }, [dataFromParent]);

  const handleFileDrop = useCallback((acceptedFiles) => {
    setFiles((prevFiles) => [
      ...prevFiles,
      ...acceptedFiles.map((file) =>
        Object.assign(file, {
          preview: URL.createObjectURL(file),
        })
      ),
    ]);
    filesloc = acceptedFiles;
    const Files = acceptedFiles.map((file) =>
      Object.assign(file, {
        preview: URL.createObjectURL(file),
      })
    );
    if (Files.length > 0) {
      const formattedFiles = Files.map((e) => ({
        filename: `property/${e.path}`,
        file_type: e.type,
      }));
      console.log(presignedURL, "lakjsldfjlasjdlf");
      setPresignedUrlPayload({ multiple_files: formattedFiles });

      setTimeout(() => {
        createPresignedURL.mutate();
      }, 100);
    }
  }, []);
  var count = 0;
  const successFunction = (response) => {
    count++;

    if (response.status === 200) {
      if (presignedURLloc.length == filesloc.length) {
        filesloc.map((file, index) => {
          propertyFilePost.mutate({
            custom_name: file.name,
            document_type: propertyId,
            file: presignedURLloc[index],
            expiry_date: "",
            property: id,
          });
        });
      }
      console.log(count, response, response?.config, "count");
      const data = response?.config.data;
      const updatedFiles = filesloc.filter((item) => item.name !== data.name);

      console.log(updatedFiles, "123456765432", data.name);
      filesloc = updatedFiles;

      // if (index == files.length - 1) {
      //   // filesloc = [];
      // }

      // console.log(data, updatedFiles, files, response, "data");
      // setFiles([]);
    }
  };

  const updatePresignedUrlPUT = usePresignedUrlPut(successFunction);
  useEffect(() => {
    console.log(presignedURL?.data?.data, "2121212");
    if (createPresignedURL.data?.data?.length) {
      const output = createPresignedURL?.data?.data.map(
        (url) => url.split("?")[0]
      );
      // setPresignedURL(output);
      presignedURLloc = output;
      console.log("ywuioj", output);
      setPresignedUrlPayload({ multiple_files: [] });

      const binaryData = filesloc.map((file, index) => ({
        filename: file.name,
        type: file.type,
        url: output[index],
        data: file,
      }));

      if (binaryData.length > 0) {
        console.log(binaryData, "12121212121212121");

        binaryData.forEach((binary, index) => {
          updatePresignedUrlPUT.mutate({
            url: createPresignedURL.data.data[index],
            imagePayload: binary.data,
            type: binary.type,
            onUploadProgress: (progressEvent) => {
              console.log(
                progressEvent.loaded,
                progressEvent.total,
                "progressEvent"
              );
              const percentCompleted = Math.round(
                (progressEvent.loaded * 100) / progressEvent.total
              );
              console.log(
                `File ${binary.filename} Upload Progress: ${percentCompleted}%`
              );
              setUploadProgress((prev) => ({
                ...prev,
                [binary.filename]: percentCompleted,
              }));
            },
            successFunction: successFunction,
          });

          // setFiles([]);
        });
      }
    }
  }, [createPresignedURL.data]);

  return (
    <>
      <div className="card-contents" style={{ position: "relative" }}>
        <div className="card-logo">
          <img src={logo} alt="logo" />
        </div>
        <div className="init-card-top">
          <div style={{ display: "flex", alignItems: "center", gap: "10px" }}>
            <p
              style={{ fontSize: "24px", opacity: "50%", cursor: "pointer" }}
              onClick={() => {
                navigate("/initialSetup");
                dispatch(setPropertyFileFilter(propertyFileFilter));
              }}
            >
              Property Folders
            </p>
            <img
              style={{ height: "20px", width: "20px" }}
              src={greater}
              alt="greater"
            />
            <p style={{ fontSize: "24px" }}>{initialSelectedProperty?.name}</p>
          </div>
          <div className="card-search">
            <Search
              setsearch={setSearch}
              style={{ width: "200px" }}
              setPageIndex={setPageIndex}
            />
          </div>
        </div>
        <p
          className="init-headings"
          style={{ marginTop: "25px", textAlign: "right" }}
        >
          {"Total files uploaded"}
          <span
            style={{ color: "#817EFF", fontSize: "20px", marginLeft: "10px" }}
          >
            {addLeadingZero(dataFromParent.length || 0)}
          </span>
        </p>
        <div
          className="card-body-content"
          style={{ overflow: "auto", height: "600", marginTop: "0px" }}
        >
          <div className="property-content">
            <div
              className="file-body"
              style={{ height: "63vh", width: "100%", padding: "10px" }}
            >
              {dataFromParent.length === 0 && filesloc.length == 0 ? (
                <div
                  className="no-files"
                  style={{
                    display: "flex",
                    justifyContent: "center",
                  }}
                >
                  <img
                    src={dragFile}
                    style={{ marginTop: "70px" }}
                    alt="dropzone"
                  />
                </div>
              ) : (
                <>
                  {filesloc.map((file) => (
                    <div key={file.id}>
                      <div className="file-list">
                        <div className="file-left">
                          <img src={pdfIcon} alt="pdf" />
                          <p className="file-text">{file.name}</p>
                        </div>

                        <div
                          style={{
                            display: "flex",
                            alignItems: "center",
                            gap: "10px",
                          }}
                        >
                          {" "}
                          <div className="progress-percentage">
                            {uploadProgress[file.name] || 0}%
                          </div>{" "}
                          <div
                            style={{
                              display: "flex",
                              alignItems: "center",
                              // justifyContent: "center",
                              borderRadius: "10px",
                              // backgroundColor: "#D9D9D9",
                              border: "1px solid #D9D9D9",
                              width: "160px",
                              height: "15px",
                            }}
                          >
                            <div
                              className="progress-bar"
                              style={{
                                width: `${uploadProgress[file.name] || 0}%`,
                                backgroundColor: "#817EFF",
                                zIndex: "22",
                                height: "11px",
                                borderRadius: "10px",
                              }}
                            ></div>
                          </div>{" "}
                          <img
                            src={deleteIcon}
                            onClick={() => {}}
                            alt="deleteicon"
                          />
                        </div>
                      </div>
                    </div>
                  ))}

                  {dataFromParent.map((file) => {
                    const data = filesloc.map(
                      (ele) => ele.name !== file.custom_name
                    );
                    console.log(uploadProgress[file.custom_name], "data");
                    if (data) {
                      return (
                        <div key={file.id}>
                          <div
                            className="file-list"
                            onClick={() => {
                              window.open(file.file, "_blank");
                            }}
                          >
                            <div className="file-left">
                              <img src={pdfIcon} alt="pdf" />
                              <p className="file-text">{file.custom_name}</p>
                            </div>

                            <div
                              style={{
                                display: "flex",
                                alignItems: "center",
                                gap: "10px",
                              }}
                            >
                              {/* <div className="progress-percentage">
                            {uploadProgress[file.custom_name] || 0}%
                          </div>{" "}
                          <div
                            style={{
                              display: "flex",
                              alignItems: "center",
                              // justifyContent: "center",
                              borderRadius: "10px",
                              // backgroundColor: "#D9D9D9",
                              border: "1px solid #D9D9D9",
                              width: "160px",
                              height: "15px",
                            }}
                          >
                            <div
                              className="progress-bar"
                              style={{
                                width: `${
                                  uploadProgress[file.custom_name] || 0
                                }%`,
                                backgroundColor: "#817EFF",
                                zIndex: "22",
                                height: "11px",
                                borderRadius: "10px",
                              }}
                            ></div>
                          </div>{" "} */}
                              {propertyFileFilter === "Property" && (
                                <img
                                  className="delete-icon"
                                  src={deleteIcon}
                                  onClick={(e) => {
                                    console.log(file.id, "kjasjfdkjasdljf");
                                    e.stopPropagation();
                                    setConfirmGas(true);
                                    setSelectedId(file.id);
                                  }}
                                  alt="deleteicon"
                                />
                              )}
                            </div>
                          </div>
                        </div>
                      );
                    }
                  })}
                </>
              )}
            </div>
          </div>
        </div>
        {propertyFileFilter == "Property" && (
          <Dropzone onDrop={handleFileDrop}>
            {({ getRootProps, getInputProps }) => (
              <div
                style={{
                  borderRadius: "10px",
                  width: "100%",
                }}
              >
                <section
                  className="wfp--dropzone"
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    flexDirection: "column",
                    height: "72px",

                    background: "#fff",
                    border: "2px dashed #817EFF",
                    padding: "41px 25px",
                    borderRadius: "15px",
                    width: "100%",
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      gap: "30px",
                      width: "100%",
                    }}
                  ></div>
                  <div
                    {...getRootProps({
                      className: "wfp--dropzone__input",
                    })}
                    style={{
                      alignItems: "end",
                    }}
                  >
                    <input {...getInputProps()} />
                    <div
                      style={{
                        display: "flex",
                        // justifyContent: "center",
                        alignItems: "center",

                        // flexDirection: "column",
                        // gap: "5px",
                      }}
                    >
                      <>
                        <p style={{ fontSize: "16px" }}>
                          Drag and drop files to attach or
                        </p>
                        <button
                          className="Next_Button"
                          style={{
                            // outline: "1px solid #817eff",
                            // marginBottom: "20px",
                            width: "fit-content",
                            background: "white",
                            color: "#817eff",
                            fontSize: "16px",
                            // padding: "9px 26px",
                            borderRadius: "13px",
                          }}
                        >
                          Browse
                        </button>
                      </>
                    </div>
                  </div>
                </section>
              </div>
            )}
          </Dropzone>
        )}
        <ConformPopup
          isOpen={confirmGas}
          setIsOpen={setConfirmGas}
          handleConfirmSubmit={() => {
            propertyFileDelete.mutate({
              data: selectedId,
            });
          }}
          customMessage={"Are you sure you want to delete this file?"}
        />
      </div>
    </>
  );
};

export default FileUploadView;
