import React, { useEffect, useMemo, useState } from "react";
import Search from "../../../MainComponent/fields/search";
import "../../StyleSheet/clientmoney/paymentdue.scss";
import ConformationPopup from "../../../MainComponent/conformationpopup/Conformation";
import {
  usePaymentDueUpdate,
  usePaymentsDue,
} from "../../../Api/clientmonetQuery";
import { useDispatch, useSelector } from "react-redux";
import { addLeadingZero, formatPounds } from "../../../Utils/Utils";
import dayjs from "dayjs";
import { setClientPaymentDueFilter } from "../../../redux/reducer/counterslice";
import { createColumnHelper } from "@tanstack/react-table";
import TableComponent from "../../GlobalComponents/table-v8/reacttable";
import CustomCheckButtons from "../../GlobalComponents/CustomCheckButtons";
import { DateRangePicker } from "rsuite";
import { toast } from "sonner";

export default function PaymentDue() {
  const [search, setsearch] = useState("");
  const [approvePopup, setApprovePopup] = useState(false);
  const columnHelper = createColumnHelper();
  const loginUserData = useSelector((state) => state.counter.loginuserdata);

  const [toggle, setToggle] = useState("landlord pay");

  const filter = useSelector((state) => state.counter.clientPaymentDueFilter);
  const dispatch = useDispatch();

  const [tableData, setTableData] = useState([]);
  const [status, setStatus] = useState([]);
  const [pageIndex, setPageIndex] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [checkedRows, setCheckedRows] = useState({});
  const [updatecharge, setUpdateCharge] = useState([]);
  const [isChecked, setIsChecked] = useState(false);
  const [startDate, setStartDate] = useState(dayjs().format("YYYY-MM-DD"));
  const [endDate, setEndDate] = useState(dayjs().format("YYYY-MM-DD"));
  const getPaymentsDue = usePaymentsDue({
    params: {
      client: loginUserData.id,
      status: filter,
      search: search,
      type: toggle,
      ...(filter === "paid"
        ? {
            start_date: startDate,
            end_date: endDate,
          }
        : {}),
    },
  });
  const handlePaymentDueUpdate = usePaymentDueUpdate();
  const handleCheckboxChange = (index, amount, row) => {
    setCheckedRows((prevCheckedRows) => {
      const newCheckedRows = {
        ...prevCheckedRows,
        [index]: !prevCheckedRows[index],
      };
      if (newCheckedRows[index]) {
        setUpdateCharge((prevUpdateCharge) => [
          ...prevUpdateCharge,
          { id: row.id },
        ]);
      } else {
        setUpdateCharge((prevUpdateCharge) =>
          prevUpdateCharge.filter((ele) => ele.id !== row.id)
        );
      }
      return newCheckedRows;
    });
  };
  const checkAll = (row, checkselect) => {
    if (checkselect) {
      setCheckedRows((prevCheckedRows) => {
        const newCheckedRows = Array(row.length).fill(true);
        row.forEach((ele, index) => {
          setUpdateCharge((prevUpdateCharge) => [
            ...prevUpdateCharge,
            { id: ele.original.id },
          ]);
        });

        return newCheckedRows;
      });
    } else {
      setCheckedRows([]);
      setUpdateCharge([]);
    }
  };

  useEffect(() => {
    if (getPaymentsDue.data?.data) {
      setTableData(getPaymentsDue.data?.data?.[0]?.data);
      setStatus(getPaymentsDue.data?.data?.[0]?.status_counts);
    }
  }, [getPaymentsDue.data]);

  const columns = useMemo(() => {
    const columnArray = [];

    if (filter !== "paid") {
      columnArray.push(
        columnHelper.accessor((row) => row, {
          header: "",
          id: "checkedRows",
          enableSorting: false,
          cell: (info) => {
            return (
              <CustomCheckButtons
                setIsChecked={setIsChecked}
                isChecked={checkedRows[info.row.index]}
                styles={{ position: "relative", left: "10px" }}
                onChange={() => {
                  handleCheckboxChange(
                    info.row.index,
                    info.row.original.amount,
                    info.row.original
                  );
                }}
              />
            );
          },
        })
      );
    }

    columnArray.push(
      columnHelper.accessor((row) => row, {
        header: "Date",
        sortingFn: "basic",
        id: "data",
        cell: (info) => {
          const data = info.row.original?.recpit_date;
          return (
            <span style={{ wordBreak: "break-all", fontSize: "14px" }}>
              {dayjs(data).format("DD/MM/YYYY")}
            </span>
          );
        },
      }),
      columnHelper.accessor((row) => row, {
        header: "Name",
        sortingFn: "basic",
        id: "name",
        cell: (info) => {
          const data = info.row.original?.payer_name?.[0];
          return (
            <span style={{ wordBreak: "break-all", fontSize: "14px" }}>
              {data}
            </span>
          );
        },
      }),
      columnHelper.accessor((row) => row, {
        header: "Description",
        sortingFn: "basic",
        id: "description",
        cell: (info) => {
          const data = info.row.original?.type;
          return (
            <span style={{ wordBreak: "break-all", fontSize: "14px" }}>
              {data}
            </span>
          );
        },
      }),
      columnHelper.accessor((row) => row, {
        header: "Amount",
        sortingFn: "basic",
        id: "amount",
        cell: (info) => {
          const data = info.row.original?.debit;
          return (
            <span style={{ wordBreak: "break-all", fontSize: "14px" }}>
              {data > 0 ? `£${formatPounds(data)}` : "--"}
            </span>
          );
        },
      })
    );

    return columnArray;
  }, [toggle, checkedRows, setIsChecked, handleCheckboxChange, columnHelper]);
  const today = new Date();

  const disableFutureDates = (date) => {
    return date > today;
  };

  const handleDateChange = (dates) => {
    if (dates && dates.length === 2) {
      const [start, end] = dates;
      setStartDate(dayjs(start).format("YYYY-MM-DD"));
      setEndDate(dayjs(end).format("YYYY-MM-DD"));
    } else {
      setStartDate(dayjs().format("YYYY-MM-DD"));
      setEndDate(dayjs().format("YYYY-MM-DD"));
    }
  };

  const handleSubmit = () => {
    if (Object.keys(checkedRows).length > 0) {
      const convertToIdArray = (data) => {
        return data.map((item) => item.id);
      };
      const idArray = convertToIdArray(updatecharge);
      handlePaymentDueUpdate.mutate({
        data: { id: idArray },
        handleSuccess: (value) => {
          setApprovePopup(false);
        },
      });
    } else {
      toast.error("At least one payment must be selected.");
    }
    setUpdateCharge([]);
  };

  return (
    <>
      <div className="paymentdue">
        <div className="card_parent">
          <div
            className={`card ${toggle == "landlord pay" ? "active" : ""}`}
            onClick={(e) => {
              setToggle("landlord pay");
            }}
          >
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <p>Landlord Payment</p>
              <svg
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M18.6667 5H5.33333C4.97971 5 4.64057 5.13506 4.39052 5.37546C4.14048 5.61587 4 5.94192 4 6.28191V10.9849C4 18.1475 10.3167 20.5271 11.5833 20.9277C11.8521 21.0241 12.1479 21.0241 12.4167 20.9277C13.6833 20.5271 20 18.1475 20 10.9849V6.28191C20 5.94192 19.8595 5.61587 19.6095 5.37546C19.3594 5.13506 19.0203 5 18.6667 5ZM15.375 12.3069L13.075 13.0279L14.5 14.9107C14.5514 14.9784 14.5884 15.0552 14.6087 15.1367C14.629 15.2182 14.6323 15.3028 14.6183 15.3855C14.6044 15.4683 14.5735 15.5475 14.5274 15.6187C14.4814 15.6898 14.421 15.7515 14.35 15.8001C14.2381 15.8837 14.1001 15.9289 13.9583 15.9282C13.853 15.9273 13.7492 15.903 13.6553 15.8571C13.5613 15.8112 13.4796 15.7451 13.4167 15.6639L12 13.7811L10.5833 15.6639C10.5204 15.7451 10.4387 15.8112 10.3447 15.8571C10.2508 15.903 10.147 15.9273 10.0417 15.9282C9.89987 15.9289 9.76195 15.8837 9.65 15.8001C9.57895 15.7515 9.51865 15.6898 9.47258 15.6187C9.42652 15.5475 9.39561 15.4683 9.38165 15.3855C9.3677 15.3028 9.37098 15.2182 9.39129 15.1367C9.41161 15.0552 9.44856 14.9784 9.5 14.9107L10.925 13.0279L8.625 12.3069C8.45742 12.2553 8.31783 12.1422 8.23666 11.9921C8.15549 11.842 8.13931 11.6672 8.19167 11.5057C8.24707 11.344 8.36636 11.2097 8.5238 11.1317C8.68125 11.0538 8.86424 11.0385 9.03333 11.0891L11.3333 11.8101V9.48667C11.3333 9.31668 11.4036 9.15365 11.5286 9.03345C11.6536 8.91325 11.8232 8.84572 12 8.84572C12.1768 8.84572 12.3464 8.91325 12.4714 9.03345C12.5964 9.15365 12.6667 9.31668 12.6667 9.48667V11.8101L14.9667 11.0891C15.1358 11.0385 15.3188 11.0538 15.4762 11.1317C15.6336 11.2097 15.7529 11.344 15.8083 11.5057C15.8607 11.6672 15.8445 11.842 15.7633 11.9921C15.6822 12.1422 15.5426 12.2553 15.375 12.3069Z"
                  fill={toggle != "landlord pay" ? "#817EFF" : "#F7F9FB"}
                />
              </svg>
            </div>
            <h1>
              {status.total_landlord > 0
                ? `£${formatPounds(status.total_landlord)}`
                : "£0"}
            </h1>
          </div>

          <div
            className={`card ${toggle == "supplier pay" ? "active" : ""}`}
            onClick={(e) => {
              setToggle("supplier pay");
            }}
          >
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <p>Suppliers Payment</p>
              <svg
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M18.6667 5H5.33333C4.97971 5 4.64057 5.13513 4.39052 5.37565C4.14048 5.61618 4 5.9424 4 6.28256V10.9879C4 18.1542 10.3167 20.535 11.5833 20.9358C11.854 21.0214 12.146 21.0214 12.4167 20.9358C13.6833 20.535 20 18.1542 20 10.9879V6.28256C20 5.9424 19.8595 5.61618 19.6095 5.37565C19.3594 5.13513 19.0203 5 18.6667 5ZM16.125 10.5952L11.2417 15.0841C11.1154 15.1983 10.9483 15.2615 10.775 15.2605C10.6042 15.2611 10.4399 15.1978 10.3167 15.0841L7.875 12.8396C7.80728 12.7828 7.7522 12.7134 7.71308 12.6355C7.67396 12.5577 7.6516 12.4731 7.64736 12.3867C7.64311 12.3004 7.65706 12.2141 7.68836 12.133C7.71967 12.052 7.76769 11.9779 7.82953 11.9151C7.89137 11.8524 7.96576 11.8024 8.04823 11.768C8.13069 11.7336 8.21953 11.7156 8.30941 11.7151C8.39928 11.7146 8.48833 11.7316 8.57121 11.7651C8.65408 11.7985 8.72907 11.8477 8.79167 11.9098L10.775 13.7294L15.2083 9.6653C15.338 9.55651 15.5063 9.50014 15.6781 9.50796C15.85 9.51578 16.012 9.58719 16.1304 9.70726C16.2487 9.82733 16.3143 9.98677 16.3133 10.1522C16.3123 10.3177 16.2448 10.4764 16.125 10.5952Z"
                  fill={toggle != "supplier pay" ? "#817EFF" : "#F7F9FB"}
                />
              </svg>
            </div>
            <h1>
              {status.total_landlord > 0
                ? `£${formatPounds(status.total_supplier)}`
                : "£0"}
            </h1>
          </div>

          <div
            className={`card ${toggle == "Agency pay" ? "active" : ""}`}
            onClick={(e) => {
              setToggle("Agency pay");
            }}
          >
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <p>Agency Payment</p>
              <svg
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M17.1333 8.50909V8C17.1333 5.71818 13.6683 4 9.06667 4C4.465 4 1 5.71818 1 8V11.6364C1 13.5364 3.40167 15.0455 6.86667 15.5V16C6.86667 18.2818 10.3317 20 14.9333 20C19.535 20 23 18.2818 23 16V12.3636C23 10.4818 20.6717 8.97273 17.1333 8.50909ZM6.86667 14.0182V11.8636C7.59622 11.9566 8.33112 12.0022 9.06667 12C9.80221 12.0022 10.5371 11.9566 11.2667 11.8636V14.0182C10.5389 14.1307 9.80322 14.1854 9.06667 14.1818C8.33011 14.1854 7.59445 14.1307 6.86667 14.0182ZM15.6667 10.3545V11.6364C15.6667 12.4 14.53 13.2182 12.7333 13.7182V11.5909C13.9158 11.3091 14.915 10.8818 15.6667 10.3545ZM2.46667 11.6364V10.3545C3.21833 10.8818 4.2175 11.3091 5.4 11.5909V13.7182C3.60333 13.2182 2.46667 12.4 2.46667 11.6364ZM8.33333 16V15.6182L9.06667 15.6364C9.42722 15.6364 9.77556 15.6273 10.1117 15.6091C10.4783 15.7364 10.8633 15.8455 11.2667 15.9455V18.0818C9.47 17.5818 8.33333 16.7636 8.33333 16ZM12.7333 18.3818V16.2182C13.4628 16.3131 14.1976 16.3617 14.9333 16.3636C15.6689 16.3658 16.4038 16.3203 17.1333 16.2273V18.3818C15.6749 18.6 14.1918 18.6 12.7333 18.3818ZM18.6 18.0818V15.9545C19.7825 15.6727 20.7817 15.2455 21.5333 14.7182V16C21.5333 16.7636 20.3967 17.5818 18.6 18.0818Z"
                  fill={toggle != "Agency pay" ? "#817EFF" : "#F7F9FB"}
                />
              </svg>
            </div>
            <h1>
              {status.total_landlord > 0
                ? `£${formatPounds(status.total_agency)}`
                : "£0"}
            </h1>
          </div>
        </div>
        <div
          style={{
            background: "white",
            marginTop: "17px",
            borderRadius: "13px",
            height: "calc(100% - 82px)",
          }}
        >
          <div className="header">
            <div className="button_container">
              <button
                className={filter === "p_due" && "active"}
                onClick={() => {
                  dispatch(setClientPaymentDueFilter("p_due"));
                }}
              >
                Payments Due
                <span>{addLeadingZero(status?.payment_due) || 0}</span>
              </button>{" "}
              <button
                className={filter === "paid" && "active"}
                onClick={() => {
                  dispatch(setClientPaymentDueFilter("paid"));
                }}
              >
                Payments Processed
                <span>{addLeadingZero(status?.payment_proceed)}</span>
              </button>{" "}
            </div>
            <div className="table_filter">
              {filter === "paid" && (
                <div style={{ marginRight: "15px" }}>
                  <DateRangePicker
                    placeholder="Select Date Range"
                    format="dd MMM yyyy"
                    character="    –    "
                    // size="lg"
                    showHeader={false}
                    className="custom-date-range-picker"
                    disabledDate={disableFutureDates} // Disable future dates
                    value={[new Date(startDate), new Date(endDate)]} // Controlled value
                    onChange={handleDateChange} // Handle date changes
                  />
                </div>
              )}
              <div
                style={{ display: "flex", alignItems: "center", gap: "15px" }}
              >
                <Search
                  setsearch={setsearch}
                  style={{ width: "200px" }}
                  setPageIndex={setPageIndex}
                  placeholder={"Payment"}
                />
                <button
                  className="upload_button"
                  onClick={() => {
                    if (Object.keys(checkedRows).length > 0) {
                      setApprovePopup(true);
                    } else {
                      toast.error("At least one payment must be selected.");
                    }
                  }}
                >
                  <span style={{ padding: "3px 10px" }}>
                    Pay{" "}
                    {toggle === "supplier pay"
                      ? "Supplier"
                      : toggle === "landlord pay"
                      ? "Landlord"
                      : "Agency"}
                  </span>
                  &nbsp;
                </button>
              </div>
            </div>
          </div>
          <div className="tableComp">
            <TableComponent
              data={tableData}
              columns={columns}
              pageindex={pageIndex}
              pagesize={pageSize}
              setPageIndex={setPageIndex}
              setPageSize={setPageSize}
              checkall={checkAll}
            />
          </div>
        </div>
      </div>
      {approvePopup && (
        <ConformationPopup
          text={"Are you sure you want to pay ?"}
          accept={"Yes"}
          Decline={"No"}
          successtext={
            "Your Property Manager has been notified. We will be reaching you shortly!"
          }
          setApprovepopup={setApprovePopup}
          handleFunction={handleSubmit}
          style={{ height: "385px" }}
        />
      )}
    </>
  );
}
