import "../../StyleSheet/dashboard/periodicinsepction.scss";
import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { addLeadingZero } from "../../../Utils/Utils";
import { useNavigate } from "react-router-dom";
import { setPeriodicInspectionStatus } from "../../../redux/reducer/counterslice";
export default function PeriodicInspection() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [chartData, setChartData] = useState([0, 0, 0]);
  const [totalCount, setTotalCount] = useState();
  const colors = ["#4DDE87", "#FFB200", "#FF3A29"];
  const subColor = ["#dbf8e7", "#fff0cc", "#ffd8d4"];
  const text = ["Completed", "Due", "Overdue"];
  const propertycount = useSelector((state) => state.counter.ComplianceData);

  useEffect(() => {
    if (propertycount && propertycount.length > 0) {
      const filteredArray = propertycount.filter(
        (item) => item.is_periodic === true
      );
      if (filteredArray.length > 0) {
        const notDueCount = filteredArray.reduce(
          (sum, item) => sum + item.not_due_count,
          0
        );
        const dueCount = filteredArray.reduce(
          (sum, item) => sum + item.due_count,
          0
        );
        const overdueCount = filteredArray.reduce(
          (sum, item) => sum + item.overdue_count,
          0
        );

        setChartData([notDueCount, dueCount, overdueCount]);
        setTotalCount([notDueCount + dueCount + overdueCount]);
      }
    }
  }, [propertycount]);

  const statusData = ["not_due", "due", "overdue"];

  return (
    <div className="periodic_insepction">
      <h1 className="header">Periodic Inspection</h1>
      <div className="periodic_insepction_inner_wrapper">
        {chartData.map((item, index) => {
          const percentage = (item / totalCount) * 100;

          return (
            <div
              className={`progress_main ${
                chartData[index] !== 0 ? "animated" : "notAnimated"
              }`}
              key={index}
              onClick={() => {
                if (chartData[index] !== 0) {
                  dispatch(setPeriodicInspectionStatus(statusData[index]));
                  navigate("/insepection");
                }
              }}
              style={{ display: "flex", flexDirection: "column", gap: "10px" }}
            >
              <div className="main_text">
                <span className="text">{text[index % text.length]}</span>
                <span style={{ color: colors[index % colors.length] }}>
                  {addLeadingZero(item || 0)}
                </span>
              </div>

              <div className="progress_bar">
                <div
                  className="progress progress-sm"
                  style={{
                    height: "max(10.7px, 1.5vh)",
                    borderRadius: "1.25rem",
                    backgroundColor: subColor[index % subColor.length],
                  }}
                >
                  <div
                    className="progress-bar"
                    style={{
                      width: `${totalCount === 0 ? 0 : percentage}%`,
                      borderRadius: "1.25rem",
                      height: "max(10.7px, 1.5vh)",
                      backgroundColor: colors[index % colors.length],
                    }}
                    aria-valuenow={percentage}
                    aria-valuemin="0"
                    aria-valuemax="100"
                  ></div>
                </div>
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
}
