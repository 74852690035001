import "../../StyleSheet/Property/overview.scss";
import { CircularProgressbar, buildStyles } from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";
import ReactEcharts from "echarts-for-react";
import bulb from "../../../Assets/Client/property/Vector_light.svg";
import flash from "../../../Assets/Client/property/Vector_flash.svg";
import leaf from "../../../Assets/Client/property/Vector_leaf.svg";
import Union from "../../../Assets/Client/property/Vector_Union.svg";
// import L from "leaflet";
import "leaflet/dist/leaflet.css";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  useFetchProperties,
  useFetchPropertyUpcoming,
} from "../../../Api/PropertiesQuery";
import { useFetchCurrentOccupancyStatus } from "../../../Api/homeQuery";
import OccupancyPopup from "./popups/OccupancyPopup";
import { addLeadingZero } from "../../../Utils/Utils";
import PropertyMapContainer from "./mapcontainer";
import {
  setPropertyOccupancyPopup,
  setPropertyOccupancyPopupFilter,
} from "../../../redux/reducer/counterslice";

export default function PropertyOverView() {
  const clientId = useSelector((state) => state.counter.loginuserdata.id);
  const propertycount = useSelector((state) => state.counter.propertycounters);
  const upcomingDocumentStatus = useSelector(
    (state) => state.counter.PropertyOccupancyPopup
  );
  const activeTab = useSelector(
    (state) => state.counter.PropertyOccupancyPopupFilter
  );
  const [chartData, setChartData] = useState([0, 0, 0]);
  const dispatch = useDispatch();
  console.log(propertycount, "asjdahk");
  const [tableData, setTableData] = useState([]);
  // const [popupstate, setPopupstate] = useState(false);

  const fetchProperties = useFetchProperties({
    viewType: true,
    params: {
      client: clientId,
      page: 1,
      page_size: 1000,
      status: true,
    },
  });
  const clientid = useSelector((state) => state.counter.clientid);
  const fetchComplianceStats = useFetchCurrentOccupancyStatus({
    params: { client: clientid },
  });
  const fetchPropertyUpcoming = useFetchPropertyUpcoming(clientId);
  useEffect(() => {
    if (fetchComplianceStats.data?.data) {
      console.log(fetchComplianceStats.data.data, "dsd");
      setChartData([
        fetchComplianceStats.data.data.occupied,
        fetchComplianceStats.data.data.vacant_in_progress,
        fetchComplianceStats.data.data.vacant,
      ]);
    }
  }, [fetchComplianceStats.data]);
  const data = [
    {
      icon: <img src={bulb}></img>,
      title: "Electrical Certificate (EICR)",
      subtext: "28 Maple Close London SW6 9IJ",
    },
    {
      icon: <img src={flash}></img>,
      title: "Energy Performance Certificate (EPC)",
      subtext: "7 Elmwood Road London E17 3GH",
    },
    {
      icon: <img src={Union}></img>,
      title: "Portable Appliance Testing (PAT)",
      subtext: "36 Willow Crescent London NW8 7XY",
    },
    {
      icon: <img src={leaf}></img>,
      title: "Gas Safe Check/Certificate",
      subtext: "12 Primrose Avenue London SE1 4AB",
    },
  ];
  const option = {
    series: [
      {
        name: "Occupancy",
        type: "pie",
        radius: ["58%", "90%"],
        avoidLabelOverlap: false,
        itemStyle: {
          borderRadius: 10,
          borderColor: "#fff",
          borderWidth: 2,
        },
        label: {
          show: false,
          position: "center",
        },
        emphasis: {
          label: {
            show: false,
            fontSize: 10,
          },
        },
        labelLine: {
          show: false,
        },
        data: [
          {
            value: propertycount?.not_due,
            name: "Not Due",
            itemStyle: { color: "#4DDE87" },
          },
          {
            value: propertycount?.due,
            name: "Due",
            itemStyle: { color: "#FFB200" },
          },
          {
            value: propertycount?.over_due,
            name: "Overdue",
            itemStyle: { color: "#FF3A29" },
          },
        ],
      },
    ],
  };
  // useEffect(() => {
  //   var map = L.map("map").setView([51.505, -0.09], 13);
  //   L.tileLayer("https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png").addTo(
  //     map
  //   );
  // }, []);

  useEffect(() => {
    console.log(fetchProperties.data?.data?.count, "lajksldfjjsdlfjk");
    if (fetchProperties.data?.data?.results) {
      // seDataLoader(fetchProperties.data?.data?.count);
      setTableData(fetchProperties.data?.data?.count);
    }
  }, [fetchProperties.data]);

  const percentFinder = (value) => {
    return (value / tableData) * 100;
  };

  const loading = useSelector((state) => state.counter.loader);

  if (loading) {
    return null; // Or some loading component
  }

  return (
    <div className="overview" style={{ overflowX: "hidden" }}>
      <div className="header">
        <h1>Overview</h1>
      </div>
      <div className="content_wrapper">
        <div className="one">
          <div className=" Occupancy_left">
            <h2>Occupancy</h2>
            <div className="total_count_wrapper">
              <h2>{addLeadingZero(tableData)}</h2>
              <p>Total Number of Properties</p>
            </div>
          </div>
          <div className=" Occupancy_right">
            <div className="overPie">
              <div>
                <div
                  className={`loader_wrapper firstProgress ${
                    chartData?.[0] == 0 ? "notAnimated" : "animated"
                  }`}
                  style={{ cursor: "pointer" }}
                  onClick={() => {
                    if (chartData?.[0] !== 0) {
                      dispatch(setPropertyOccupancyPopup(true));
                      dispatch(setPropertyOccupancyPopupFilter("Occupied"));
                    }
                  }}
                >
                  <CircularProgressbar
                    value={percentFinder(chartData?.[0])}
                    text={`${chartData?.[0]}`}
                    styles={buildStyles({
                      rotation: 0,
                      textSize: "32px",
                      pathTransitionDuration: 0.5,
                      pathColor: `rgb(77,222,135)`,
                      textColor: "#000",
                      trailColor: "#d6d6d6",
                      backgroundColor: "#3e98c7",
                      strokeWidth: 10,
                      fontWeight: "300",
                    })}
                    strokeWidth={10}
                  />
                  <p>Occupied</p>
                </div>
              </div>
              <div className="">
                <div
                  style={{ cursor: "pointer" }}
                  // className="loader_wrapper secondProgress"
                  className={`loader_wrapper secondProgress ${
                    chartData?.[1] == 0 ? "notAnimated" : "animated"
                  }`}
                  onClick={() => {
                    if (chartData?.[1] !== 0) {
                      dispatch(setPropertyOccupancyPopup(true));
                      dispatch(
                        setPropertyOccupancyPopupFilter("Upcoming Vacancies")
                      );
                    }
                  }}
                >
                  <CircularProgressbar
                    value={percentFinder(chartData?.[1])}
                    text={`${chartData?.[1]}`}
                    styles={buildStyles({
                      // Rotation of path and trail, in number of turns (0-1)
                      rotation: 0,
                      // Text size
                      textSize: "32px",
                      // How long animation takes to go from one percentage to another, in seconds
                      pathTransitionDuration: 0.5,
                      // Can specify path transition in more detail, or remove it entirely
                      // pathTransition: 'none',

                      // Colors
                      pathColor: `#FFB200`,
                      textColor: "#000",
                      trailColor: "#d6d6d6",
                      backgroundColor: "#3e98c7",
                      strokeWidth: 10,
                      fontWeight: "300",
                    })}
                    strokeWidth={10}

                    // trailWidth={10}
                  />
                  <p>Upcoming Vacancies</p>
                </div>
              </div>
              <div className="">
                <div
                  // className="loader_wrapper thirdProgress"
                  className={`loader_wrapper thirdProgress ${
                    chartData?.[2] == 0 ? "notAnimated" : "animated"
                  }`}
                  style={{ cursor: "pointer" }}
                  onClick={() => {
                    if (chartData?.[2] !== 0) {
                      dispatch(setPropertyOccupancyPopup(true));
                      dispatch(setPropertyOccupancyPopupFilter("Vacant"));
                    }
                  }}
                >
                  <CircularProgressbar
                    value={percentFinder(chartData?.[2])}
                    text={`${chartData?.[2]}`}
                    styles={buildStyles({
                      // Rotation of path and trail, in number of turns (0-1)
                      rotation: 0,
                      // Text size
                      textSize: "32px",
                      // How long animation takes to go from one percentage to another, in seconds
                      pathTransitionDuration: 0.5,
                      // Can specify path transition in more detail, or remove it entirely
                      // pathTransition: 'none',

                      // Colors
                      pathColor: `#FF3A29`,
                      textColor: "#000",
                      trailColor: "#d6d6d6",
                      backgroundColor: "#3e98c7",
                      textWeight: "200",
                      strokeWidth: 10,
                      fontWeight: "300",
                    })}
                    strokeWidth={10}

                    // trailWidth={10}
                  />
                  <p>Vacant</p>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className=" two">
          <div className=" Property_left">
            <h2>Property Compliance</h2>
            <div className="left_inner_wrapper">
              <div className="graph_wrapper">
                <div className="graph">
                  <ReactEcharts
                    option={option}
                    style={{ width: "100%", height: "177px", top: "0" }}
                  />
                </div>
              </div>
              <ul>
                <li>
                  <span>
                    <div className="badge3"></div>
                    {"Not Due"}
                  </span>
                  <p>{addLeadingZero(propertycount?.not_due)}</p>
                </li>
                <li>
                  <span>
                    <div className="badge2"></div>
                    {"Due"}
                  </span>
                  <p>{addLeadingZero(propertycount?.due)}</p>
                </li>
                <li>
                  <span>
                    <div className="badge1"></div>
                    {"Overdue"}
                  </span>
                  <p>{addLeadingZero(propertycount?.over_due)}</p>
                </li>
              </ul>
            </div>
          </div>
          <div
            className="Property_right"
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "flex-start",
              flexDirection: "column",
              // width: "56%",
              // overflowX: "auto",
              // border: "1px solid black"
            }}
          >
            <h2>Upcoming</h2>

            <div
              className="propRightTabs"
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "flex-start",
                gap: "22px",
                flexWrap: "nowrap",
                // height: "200px",
              }}
            >
              {/* <div style={{ width: "100%" }}>
                hhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhh
              </div> */}
              {Array.isArray(fetchPropertyUpcoming?.data?.data)
                ? fetchPropertyUpcoming.data.data.map((ele, index) => {
                    console.log(ele, "laskjdfkljfsaldjf");
                    return (
                      <div className="card_main" key={index}>
                        <div className="card_inner">
                          <div className="icon_wrapper">
                            <img
                              src={ele.icon}
                              alt="flash"
                              width={"25px"}
                              height={"25px"}
                            />
                          </div>
                          <div
                            style={{
                              display: "flex",
                              flexDirection: "column",
                              justifyContent: "space-between",
                              height: "100%",
                            }}
                          >
                            <h4>{ele.d_name}</h4>
                            <p>
                              <p style={{ paddingBottom: "5px" }}>
                                {ele.p_name + " " + ele.p_street},
                              </p>
                              <p style={{ paddingBottom: "5px" }}>
                                {ele.p_city},
                              </p>{" "}
                              <p>{ele.p_postcode}</p>
                            </p>
                          </div>
                        </div>
                      </div>
                    );
                  })
                : null}
            </div>
          </div>
        </div>
        <div className="row three">
          <PropertyMapContainer />
        </div>
      </div>

      <OccupancyPopup
        activeTab={activeTab}
        popuptext={"Home Occupancy"}
        popupstate={upcomingDocumentStatus}
      />
    </div>
  );
}
