import { createSlice } from "@reduxjs/toolkit";
const initialState = {
  loader: false,
  loginuserdata: [],
  sidebar: 1,
  token: "",
  clientid: "",
  propertycounters: [],
  periodicInspection: "all",
  clientMoneyStatus: "Overview",
  clientMoneyAccountStatus: "Reserve Account",
  clientRentPopup: false,
  clientRentPopupData: {
    text: "",
    subtext: "",
  },
  clientPaymentDueFilter: "p_due",
  PropertyOccupancyPopupFilter: "Occupied",
  PropertyOccupancyPopup: false,
  maintenanceFilter: "all",
  clientMoneyPeriod: "7days",
  clientMoneyAgencyType: "Rent",
  contactsFilter: "Tenants",
  propertyDetailsFilter: "1",
  contactDetailsFilter: 1,
  propertyFileFilter: "Property",
  ComplianceData: [],
  initialSelectedProperty: "",
};
export const counterSlice = createSlice({
  name: "counterSlice",
  initialState,
  reducers: {
    sidebar: (state, action) => {
      state.sidebar = action.payload;
    },
    token: (state, action) => {
      state.token = action.payload;
    },

    loader: (state, action) => {
      state.loader = action.payload;
      // console.log(state.loaderstate,"kdsjh")
    },
    loginuserdata: (state, action) => {
      state.loginuserdata = action.payload;
    },
    clientid: (state, action) => {
      state.clientid = action.payload;
    },
    propertycounters: (state, action) => {
      state.propertycounters = action.payload;
    },
    setPeriodicInspectionStatus: (state, action) => {
      state.periodicInspection = action.payload;
    },
    setClientMoneyStatus: (state, action) => {
      state.clientMoneyStatus = action.payload;
    },
    setClientMoneyAccountStatus: (state, action) => {
      state.clientMoneyAccountStatus = action.payload;
    },
    setClientRentPopup: (state, action) => {
      state.clientRentPopup = action.payload;
    },
    setClientRentPopupData: (state, action) => {
      state.clientRentPopupData = action.payload;
    },
    setClientPaymentDueFilter: (state, action) => {
      state.clientPaymentDueFilter = action.payload;
    },
    setPropertyOccupancyPopup: (state, action) => {
      state.PropertyOccupancyPopup = action.payload;
    },
    setPropertyOccupancyPopupFilter: (state, action) => {
      state.PropertyOccupancyPopupFilter = action.payload;
    },
    setMaintenanceFilter: (state, action) => {
      state.maintenanceFilter = action.payload;
    },
    setClientMoneyPeriod: (state, action) => {
      state.clientMoneyPeriod = action.payload;
    },
    setClientMoneyAgencyType: (state, action) => {
      state.clientMoneyAgencyType = action.payload;
    },
    setContactsFilter: (state, action) => {
      state.contactsFilter = action.payload;
    },
    setPropertyDetailsFilter: (state, action) => {
      state.propertyDetailsFilter = action.payload;
    },
    setContactDetailsFilter: (state, action) => {
      state.contactDetailsFilter = action.payload;
    },
    setPropertyFileFilter: (state, action) => {
      state.propertyFileFilter = action.payload;
    },
    setComplianceData: (state, action) => {
      state.ComplianceData = action.payload;
    },
    setInitialSelectedProperty: (state, action) => {
      state.initialSelectedProperty = action.payload;
    },
  },
});

export const {
  loader,
  loginuserdata,
  sidebar,
  token,
  clientid,
  propertycounters,
  periodicInspection,
  setPeriodicInspectionStatus,
  setClientMoneyStatus,
  setClientMoneyAccountStatus,
  setClientRentPopup,
  setClientRentPopupData,
  setClientPaymentDueFilter,
  setPropertyOccupancyPopupFilter,
  setPropertyOccupancyPopup,
  setMaintenanceFilter,
  setClientMoneyPeriod,
  setClientMoneyAgencyType,
  setContactsFilter,
  setPropertyDetailsFilter,
  setContactDetailsFilter,
  setPropertyFileFilter,
  setComplianceData,
  setInitialSelectedProperty,
} = counterSlice.actions;

export default counterSlice.reducer;

// import { createSlice } from "@reduxjs/toolkit";

// const initialState = {
//   loader: false,
//   loginuserdata: [],
//   sidebar: 0,
//   token: "",
// };

// export const counterSlice = createSlice({
//   name: "counterSlice",
//   initialState,
//   reducers: {
//     setSidebar: (state, action) => {
//       state.sidebar = action.payload;
//     },
//     setToken: (state, action) => {
//       state.token = action.payload;
//     },
//     setLoader: (state, action) => {
//       state.loader = action.payload;
//     },
//     setLoginuserdata: (state, action) => {
//       state.loginuserdata = action.payload;
//     },
//   },
// });

// export const { setLoader, setLoginuserdata, setSidebar, setToken } =
//   counterSlice.actions;

// export default counterSlice.reducer;
