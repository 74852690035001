import React from "react";
import { Skeleton } from "antd";

const LoadingSkeleton = () => {
  return (
    <div className="skelitonDiv" style={{ width: "100%" }}>
      <Skeleton active style={{ height: "98px" }} rows={1} />
    </div>
  );
};

export default LoadingSkeleton;
