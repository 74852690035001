import { CustomButton } from "../../../GlobalComponents/CustomButton/CustomButton";
import React, { useEffect, useState } from "react";
import { CustomSelect } from "../../../GlobalComponents/CustomSelect";
import {
  useLandlordPropertyGet,
  useLandlordStatement,
} from "../../../../Api/PropertiesQuery";
import { formatAddress } from "../../../../Utils/Utils";
import DateRange from "../../../../MainComponent/fields/daterangepicker";
import dayjs from "dayjs";
import closeSolidIcon from "../../../../Assets/Common/closeSolidIcon.svg";
import { toast } from "sonner";
import LoadingScreen from "../../../GlobalComponents/DefaultLoader";

const GeneratePeriodicStatement = (props) => {
  const {
    isOpen,
    setIsOpen,
    landLordId,
    type,
    propertyName,
    propertyId,
    propertyData,
  } = props;

  const [paymentGroup, setPaymentGroup] = useState([
    { label: "all", value: "all" },
  ]);

  const groupsGet = useLandlordPropertyGet({
    params: { landlord: landLordId, page_size: "100000", page: "1" },
  });
  const [paymentGroupState, setPaymentGroupState] = useState(["all"]);
  const [paymentGroupList, setPaymentGroupList] = useState([]);
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");

  const generateLandlordStatement = useLandlordStatement({
    id: landLordId || propertyData?.landlord?.id,
    start_date: startDate,
    end_date: endDate,
    recepit_date: dayjs().format("YYYY-MM-DD"),
    property: paymentGroupState,
  });

  useEffect(() => {
    if (groupsGet?.data?.data.results !== undefined) {
      const value = [{ label: "all", value: "all" }];
      groupsGet.data.data?.results.forEach((item) => {
        value.push({
          label: formatAddress([
            item?.name,
            item?.street,
            item?.city,
            item?.postcode,
          ]),
          value: item.id,
        });
      });

      setPaymentGroupList(value);
      setPaymentGroup([value[0]]);
    }
  }, [groupsGet.data]);
  useEffect(() => {
    console.log(propertyName, "propertyName");
    if (type === "property") {
      setPaymentGroupState([propertyId]);
    }
  }, [type]);
  const handleChangePaymentGroup = (selectedOptions) => {
    const isAllSelected = selectedOptions.some(
      (option) => option.value === "all"
    );

    const paymentGroupList = paymentGroup.some(
      (option) => option.value === "all"
    );
    let updatedOptions;
    if (paymentGroupList && isAllSelected) {
      updatedOptions = selectedOptions.filter(
        (option) => option.value !== "all"
      );
    } else if (isAllSelected) {
      updatedOptions = [{ label: "all", value: "all" }];
    } else {
      updatedOptions = selectedOptions;
    }

    if (selectedOptions.length > 0) {
      setPaymentGroup(updatedOptions);
      setPaymentGroupState(updatedOptions.map((option) => option.value));
    } else {
      updatedOptions = [{ label: "all", value: "all" }];

      setPaymentGroup([{ label: "all", value: "all" }]);
      setPaymentGroupState(updatedOptions.map((option) => option.value));
    }
  };
  const handleGenerateStatement = () => {
    generateLandlordStatement.mutate();
  };
  useEffect(() => {
    if (generateLandlordStatement.data?.data) {
      if (generateLandlordStatement.data?.data?.Link) {
        console.log(
          generateLandlordStatement.data?.data?.Link,
          "generateLandlordStatement"
        );
        window.open(generateLandlordStatement.data?.data?.Link, "_blank");
      } else {
        toast.warning("No data found");
      }
    }
  }, [generateLandlordStatement.data]);
  return (
    <div>
      {generateLandlordStatement.isPending && <LoadingScreen />}
      {isOpen && (
        <>
          <div className="overlay" />
          <div
            className="popup"
            style={{ minWidth: "600px", width: "600px", height: "auto" }}
          >
            <div
              className="popup-header"
              style={{
                display: "flex",
                justifyContent: "space-between",
                borderBottom: "1px solid lightgray",
                paddingBottom: "10px",
              }}
            >
              <div></div>
              <h3 style={{ fontSize: "24px", fontWeight: "400" }}>
                Generate Periodic Statement
              </h3>
              <img
                src={closeSolidIcon}
                alt="closeIcon"
                style={{
                  width: "70%,",
                  height: "70%",
                  cursor: "pointer",
                }}
                onClick={() => setIsOpen(false)}
              />
            </div>
            <div className="popup-content">
              <div
                className="popup_center"
                style={{
                  gap: "30px",
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                <div className="formContainer" style={{ width: "97%" }}>
                  <div
                    className="flex"
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "flex-end",
                      gap: "10px",
                    }}
                  >
                    <label>Property</label>
                    {type === "property" ? (
                      <div style={{ width: "100%" }}>
                        <p
                          style={{
                            fontSize: "18px",
                            borderRadius: "7px",
                            padding: "10px",
                            backgroundColor: "rgb(212,212,243)",
                          }}
                        >
                          {propertyName}
                        </p>
                      </div>
                    ) : (
                      <CustomSelect
                        selectedOption={paymentGroup}
                        handleSelectChange={handleChangePaymentGroup}
                        options={paymentGroupList}
                        styles={{
                          minWidth: "400px",
                          maxHeight: "100px",
                          overflow: "scroll",
                          maxWidth: "500px",
                        }}
                        isMulti={true}
                      />
                    )}
                  </div>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "flex-end",
                      gap: "10px",
                      marginTop: "10px",
                    }}
                  >
                    <label htmlFor={"file"}>Date</label>
                    <DateRange
                      format={"DD/MM/YYYY"}
                      onChange={(e) => {
                        setStartDate(e[0]);
                        setEndDate(e[1]);
                      }}
                    />
                  </div>
                </div>
                <div
                  className=""
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    gap: "20px",
                  }}
                >
                  <CustomButton
                    type="submit"
                    title={"Generate"}
                    active
                    disabled={
                      startDate === "" ||
                      endDate === "" ||
                      paymentGroup.length === 0
                    }
                    handleClick={(e) => {
                      e.preventDefault();
                      handleGenerateStatement();
                    }}
                  />
                </div>
              </div>
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default GeneratePeriodicStatement;
