import { useState } from "react";
import "../../StyleSheet/Property/overview.scss";
import Map from "./Map";

const PropertyMapContainer = () => {
  const [mapCenter, setMapCenter] = useState([
    {
      lat: 51.500772,
      lng: 0.00728,
    },
    { lat: 51.509772, lng: 0.01128 },
    { lat: 51.510972, lng: 0.09428 },
    { lat: 51.515772, lng: 0.00128 },
  ]);

  const LocationChanges = (event) => {
    setMapCenter({
      ...mapCenter,
      lat: event.latLng.lat(),
      lng: event.latLng.lng(),
    });
    console.log(event, "events", String(mapCenter.lat));
  };
  ///---By----Searching----
  const SearchedLoc = (map) => {
    console.log(map, "Maps");
    setMapCenter({
      ...mapCenter,
      lat: map.lat,
      lng: map.lng,
    });
  };

  return (
    <>
      {/* <div
        style={{
          width: "95%",
          height: "280px",
          position: "absolute",
          zIndex: "5",
          bottom: "0",
          background: "transparent",
        }}
      ></div> */}
      <div className="col-sm-12 Map p-0 mb-4 margBottom">
        <Map
          mapCenter={mapCenter}
          setMapCenter={setMapCenter}
          ChangeLocation={LocationChanges}
          SearchedLocaitons={SearchedLoc}
        />
      </div>
    </>
  );
};
export default PropertyMapContainer;
