// import "./style.scss";
// import { DatePicker, Space } from "antd";
// import dayjs from "dayjs";
// import customParseFormat from "dayjs/plugin/customParseFormat";

// dayjs.extend(customParseFormat);

// const { RangePicker } = DatePicker;
// const dateFormat = "YYYY-MM-DD";

// export default function DateRange(props) {
//   const handleChange = (dates, dateStrings) => {
//     if (props.onChange) {
//       props.onChange(dateStrings);
//     }
//   };

//   return (
//     <div className="DateRange" style={{ ...props.style }}>
//       <RangePicker
//         size={"large"}
//         format={dateFormat}
//         onChange={handleChange}
//         style={{
//           fontSize: "max(12px, 1.1vw)",
//         }}
//       />
//     </div>
//   );
// }

import "./style.scss";
import { DatePicker } from "antd";
import dayjs from "dayjs";
import customParseFormat from "dayjs/plugin/customParseFormat";

dayjs.extend(customParseFormat);

const { RangePicker } = DatePicker;
const displayFormat = "DD-MM-YYYY"; // Format for display
const outputFormat = "YYYY-MM-DD"; // Format for onChange

export default function DateRange(props) {
  const handleChange = (dates) => {
    if (props.onChange) {
      // Convert the selected dates to the desired output format
      const formattedDates = dates
        ? dates.map((date) => date.format(outputFormat))
        : [];
      props.onChange(formattedDates);
    }
  };

  // Function to disable dates outside of the 2020 to today range
  const disabledDate = (current) => {
    // Disable dates before 2020 and future dates
    return current && (current < dayjs("2020-01-01") || current > dayjs());
  };

  return (
    <div className="DateRange" style={{ ...props.style }}>
      <RangePicker
        size="large"
        format={displayFormat} // Display format for the user
        onChange={handleChange}
        style={{
          fontSize: "max(12px, 1.1vw)",
        }}
        disabledDate={disabledDate} // Disable dates outside the range
        defaultValue={props.defaultValue} // Use the custom default value passed as a prop
      />
    </div>
  );
}
