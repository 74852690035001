// import { useEffect } from "react";
// import { useSelector } from "react-redux";
// import { Outlet, Navigate } from "react-router-dom";
// import { useNavigate, useLocation } from "react-router-dom";

// const PrivateRoutes = ({ children }) => {
//   const navigate = useNavigate();
//   const token = useSelector((state) => state.counter.token);
//   const user = useSelector((state) => state.counter.loginuserdata);

//   useEffect(() => {
//     if (token === "") {
//       navigate("/login", { replace: true });
//     } else if (token !== "") {
//       if (!user.is_release) {
//         navigate("/initialSetup", { replace: true });
//       }
//     }
//   }, [token, user]);
//   if (token !== "" && user.is_release) {
//     return children;
//   }
//   // if (token == "") {
//   //   navigate("/");
//   // }
// };
// export default PrivateRoutes;

import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";

export const PrivateRoutes = ({ children }) => {
  const navigate = useNavigate();
  const { token, loginuserdata: user } = useSelector((state) => state.counter);

  useEffect(() => {
    if (!token) {
      navigate("/login", { replace: true });
    } else if (token && !user.is_release) {
      navigate("/initialSetup", { replace: true });
    }
  }, [token, user.is_release, navigate]);

  return token && user.is_release ? children : null;
};

export const PublicRoutes = ({ children }) => {
  const navigate = useNavigate();
  const { token, loginuserdata: user } = useSelector((state) => state.counter);

  useEffect(() => {
    if (token) {
      if (user.is_release) {
        navigate("/", { replace: true });
      } else {
        navigate("/initialSetup", { replace: true });
      }
    }
  }, [token, user.is_release, navigate]);

  return !token ? children : null;
};
