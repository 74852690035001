import "./style.scss";
import { useEffect, useRef, useState } from "react";
import {
  createColumnHelper,
  flexRender,
  getCoreRowModel,
  useReactTable,
  getSortedRowModel,
} from "@tanstack/react-table";
import { Pagination } from "antd";
import { ConfigProvider } from "antd";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormHelperText from "@mui/material/FormHelperText";
import FormControl from "@mui/material/FormControl";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import tablesorting from "../../../Assets/Common/tablesorting.svg";
import up from "../../../Assets/Common/up.svg";
import down from "../../../Assets/Common/down.svg";
import CustomCheckButtons from "../CustomCheckButtons";
import LoadingSkeleton from "../LoadingSkeleton";

export default function TableComponent(props) {
  const {
    addons = [],
    filterList,
    selectedFilter,
    setSelectedFilter,
    count,
    pageindex,
    pagesize,
    setPageSize,
    setPageIndex,
    checkall,
    skeLetonLoading,
    checked = false,
  } = props;

  const [sorting, setSorting] = useState([]);

  const [data, setData] = useState(() => [...props.data]);
  const columns = props.columns;
  console.log(props.data, "jhfdkjhfkjkhd");

  useEffect(() => {
    setData(props.data);
  }, [props.data, columns]);

  const table = useReactTable({
    data,
    columns,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    state: {
      sorting,
      pagination: {
        pageIndex: pageindex,
        pageSize: pagesize,
      },
    },
    onSortingChange: setSorting,
    // onPaginationChange: ({ pageIndex, pageSize }) => {
    //   setPageIndex(pageIndex || 1);
    //   setPageSize(pageSize || 10);
    // },
    debugTable: true,
  });

  useEffect(() => {
    console.log(count, "akjfhdjhakjhkjhkdsa");
  }, [count]);

  const [isChecked, setIsChecked] = useState(false);

  const tableRef = useRef(null);
  const [isOverflowing, setIsOverflowing] = useState(false);

  useEffect(() => {
    // Check if the table is overflowing when the component mounts or when resized
    const checkOverflow = () => {
      if (tableRef.current) {
        setIsOverflowing(
          tableRef.current.scrollHeight > tableRef.current.clientHeight
        );
      }
    };
    checkOverflow();
    window.addEventListener("resize", checkOverflow);

    return () => {
      window.removeEventListener("resize", checkOverflow);
    };
  }, []);

  return (
    <>
      {addons.includes("Filters") && (
        <div className="filterDiv">
          {filterList.map((filter, index) => (
            <span
              key={index}
              className={`mainSpan ${
                selectedFilter === filter.value && "active"
              }`}
              onClick={() => setSelectedFilter(filter.value)}
            >
              {filter.label}
              <span
                className="subSpan"
                style={{ background: filter.background }}
              >
                {filter.count}
              </span>
            </span>
          ))}
        </div>
      )}
      <div
        ref={tableRef}
        className="main_table"
        style={{
          marginRight: isOverflowing && "7px", // Adjust margin dynamically
          padding: "0px 26px",
        }}
      >
        <table className="custom-table" style={{ position: "relative" }}>
          <thead>
            {table.getHeaderGroups().map((headerGroup) => (
              <tr key={headerGroup.id}>
                {headerGroup.headers.map((header, index) => (
                  <th
                    key={header.id}
                    colSpan={header.colSpan}
                    style={{ fontWeight: "500" }}
                  >
                    {header.id === "checkedRows" && (
                      <div
                        style={{
                          display: "flex",
                          gap: "31px",
                          // flexDirection: "row-reverse",
                          width: "36%",
                          marginLeft: "25px",
                          justifyContent: "flex-start",
                        }}
                      >
                        <CustomCheckButtons
                          setIsChecked={setIsChecked}
                          isChecked={isChecked}
                          onChange={(e) => {
                            checkall(table.getRowModel().rows, e);
                          }}
                        />
                      </div>
                    )}
                    {header.isPlaceholder ? null : (
                      <div
                        className={
                          header.column.getCanSort()
                            ? "cursor-pointer select-none"
                            : ""
                        }
                        style={{
                          display: "flex",
                          gap: "10px",
                          // cursor: header.column.getCanSort()
                          //   ? "pointer"
                          //   : "auto",
                          // fontSize: "10px",
                          // fontSize: "max(14px, 1.2vw)",
                        }}
                        // onClick={header.column.getToggleSortingHandler()}
                        // title={
                        //   header.column.getCanSort()
                        //     ? header.column.getNextSortingOrder() === "asc"
                        //       ? "Sort ascending"
                        //       : header.column.getNextSortingOrder() === "desc"
                        //       ? "Sort descending"
                        //       : "Clear sort"
                        //     : undefined
                        // }
                      >
                        {flexRender(
                          header.column.columnDef.header,
                          header.getContext()
                        )}
                        {/* {{
                          asc: (
                            <img
                              src={up}
                              style={{ width: "11px", height: "18px" }}
                              alt="up arrow"
                            />
                          ),
                          desc: (
                            <img
                              src={down}
                              style={{ width: "11px", height: "18px" }}
                              alt="down arrow"
                            />
                          ),
                        }
                        [header.column.getIsSorted()] ??
                          (header.column.getCanSort() && (
                            <img
                              src={tablesorting}
                              alt="sort icon"
                              style={{ width: "11px", height: "18px" }}
                            />
                          ))
                          } */}
                      </div>
                    )}
                  </th>
                ))}
              </tr>
            ))}
          </thead>
          {table.getRowModel().rows.length > 0 ? (
            <tbody className="custom-table-body">
              {table.getRowModel().rows.map((row) => (
                <tr
                  key={row.id}
                  onClick={() =>
                    props?.onclickrow && props.onclickrow(row.original)
                  }
                  style={{ cursor: props?.onclickrow ? "pointer" : "auto" }}
                >
                  {row.getVisibleCells().map((cell) => (
                    <td key={cell.id}>
                      <dir style={{ margin: "0 10px 0 0", padding: "0" }}>
                        {flexRender(
                          cell.column.columnDef.cell,
                          cell.getContext()
                        )}
                      </dir>
                    </td>
                  ))}
                </tr>
              ))}
            </tbody>
          ) : (
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                position: "absolute",
                left: "0%",
                top: "138px",
                width: "100%",
              }}
            >
              {skeLetonLoading ? (
                <LoadingSkeleton />
              ) : (
                // <LoadingSkeleton />
                <p style={{ fontSize: "max(16px, 1.2vw)" }}>
                  No Data Available
                </p>
              )}
            </div>
          )}
          <tfoot>
            {table.getFooterGroups().map((footerGroup) => (
              <tr key={footerGroup.id}>
                {footerGroup.headers.map((header) => (
                  <th key={header.id}>
                    {header.isPlaceholder
                      ? null
                      : flexRender(
                          header.column.columnDef.footer,
                          header.getContext()
                        )}
                  </th>
                ))}
              </tr>
            ))}
          </tfoot>
        </table>
      </div>
      {count > 10 && (
        <div className="pagination">
          <FormControl
            sx={{ m: 1, minWidth: 150 }}
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              gap: "15px",
            }}
          >
            <span className="pagination_text">Show entries</span>
            <Select
              className="pagination_select"
              value={pagesize}
              onChange={(e) => {
                setPageSize(e.target.value);
                setPageIndex(1);
              }}
              displayEmpty
              inputProps={{ "aria-label": "Without label" }}
              sx={{
                width: "70px",
                height: "40px",
                borderRadius: "5px",
              }}
            >
              <MenuItem className="pagination_option" value={10}>
                10
              </MenuItem>
              <MenuItem className="pagination_option" value={20}>
                20
              </MenuItem>
              <MenuItem className="pagination_option" value={30}>
                30
              </MenuItem>
            </Select>
          </FormControl>
          <ConfigProvider
            theme={{
              components: {
                Pagination: {
                  itemSize: 50,
                },
              },
            }}
          >
            <Pagination
              className="pagination_font"
              defaultCurrent={1}
              current={pageindex}
              pageSize={pagesize}
              total={count}
              size="default"
              showSizeChanger={false}
              onChange={(e) => {
                console.log(e, "dfjhdf");
                setPageIndex(e);
              }}
            />
          </ConfigProvider>
        </div>
      )}
    </>
  );
}
