import "./style.scss";
import Close from "../../Assets/Common/popupclose.svg";
import { useState } from "react";
import success from "../../Assets/Client/maintenence/success.svg";
export default function ConformationPopup(props) {
  const [acceptstate, setAcceptstate] = useState("");
  return (
    <>
      <div className="ConformationPopup_main_wrapper">
        {acceptstate != "Yes" ? (
          <div className="popup_main">
            <div className="header">
              <button
                onClick={(e) => {
                  props.setApprovepopup(false);
                }}
              >
                <img src={Close} alt="close"></img>
              </button>
            </div>
            <div
              className="content"
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                flexDirection: "column",
              }}
            >
              <h1>{props.text}</h1>
              <div className="button_wrapper">
                <button
                  onClick={(e) => {
                    // setAcceptstate("Yes");
                    props.handleFunction();
                  }}
                >
                  {props.accept}
                </button>
                <button
                  onClick={(e) => {
                    props.setApprovepopup(false);
                  }}
                >
                  {props.Decline}
                </button>
              </div>
            </div>
          </div>
        ) : (
          <div className="popup_main" style={{ ...props.style }}>
            <div className="header">
              <button
                onClick={(e) => {
                  props.setApprovepopup(false);
                }}
              >
                <img src={Close} alt="close"></img>
              </button>
            </div>
            <div className="content">
              <img src={success} alt="success"></img>
              <h1>{props.successtext}</h1>
              <div className="button_wrapper">
                <button
                  onClick={(e) => {
                    props.setApprovepopup(false);
                  }}
                >
                  Close
                </button>
              </div>
            </div>
          </div>
        )}
      </div>
    </>
  );
}
