import { useSelector } from "react-redux";
import closeSolidIcon from "../../../Assets/Common/closeSolidIcon.svg";
import { formatName } from "../../../Utils/Utils";
export default function InfoQuestionMark(props) {
  const { setPopupstate } = props;

  const loginuserdata = useSelector((state) => state.counter.loginuserdata);
  const details = loginuserdata?.contact_detail?.[0];
  return (
    <div
      className={`popup_main_wrapper ${
        props.popupstate === true ? "popup-open" : "popup-close"
      } `}
    >
      <div
        className={`main_popup ${
          props.popupstate === true ? "popup-open" : "popup-close"
        } `}
        style={{
          width: "550px",
          height: "310px",
          position: "relative",
          top: "36%",
          left: "30%",
          padding: "15px",
        }}
      >
        <div
          className="header"
          style={{ borderBottom: "1px solid black", padding: "10px" }}
        >
          <div className="content" style={{ justifyContent: "flex-end" }}>
            <div className="col-1 close_button_wrapper">
              <img
                className="close"
                alt="close"
                onClick={() => setPopupstate(false)}
                src={closeSolidIcon}
              />
            </div>
          </div>
        </div>
        <div className="infoConfig">
          <h2>
            Dear{" "}
            {formatName(
              details?.title,
              details?.first_name,
              details?.last_name
            )}
            ,
          </h2>
          <p style={{ lineHeight: "30px", textAlign: "justify" }}>
            <span style={{ fontWeight: "bold" }}>
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            </span>
            We are excited to welcome you to the new and improved Rent
            Management System. This platform is designed to streamline your
            rental property management experience, making it easier and more
            efficient than ever before.
          </p>
        </div>
      </div>
    </div>
  );
}
