// import "../../../StyleSheet/clientmoney/accounts/reservedaccount.scss";
// import wallet from "../../../../Assets/Client/clientmoney/Wallet.svg";
// import Search from "../../../../MainComponent/fields/search";
// import DateRange from "../../../../MainComponent/fields/daterangepicker";
// import GenerateReport from "../../../../MainComponent/fields/generatereport";
// import TableComponent from "../../../GlobalComponents/table-v8/reacttable";
// import { createColumnHelper } from "@tanstack/react-table";
// import { useEffect, useMemo, useState } from "react";
// import {
//   useFetchReserveAccount,
//   useReserveAccountDetails,
// } from "../../../../Api/clientmonetQuery";
// import { useSelector } from "react-redux";
// import dayjs from "dayjs";
// import { formatPounds } from "../../../../Utils/Utils";
// import { Skeleton } from "antd";

// export default function ReserveAccount() {
//   const columnHelper = createColumnHelper();
//   const [search, setsearch] = useState("");
//   const [Generate, setGenerate] = useState("");
//   const clientid = useSelector((state) => state.counter.clientid);
//   const [count, setCount] = useState(0);
//   const [Balance, setbalance] = useState(0);

//   const [pageindex, setPageIndex] = useState(1);
//   const [pagesize, setPageSize] = useState(10);
//   const [table, setTable] = useState([]);

//   const columns = useMemo(
//     () => [
//       columnHelper.accessor((row) => row, {
//         id: "receipt_date",
//         header: "Date",
//         sortingFn: "basic",
//         cell: (info) => (
//           <span>
//             {dayjs(info.row.original.recpit_date).format("DD/MM/YYYY")}
//           </span>
//         ),
//       }),
//       // columnHelper.accessor((row) => row, {
//       //   id: "payer_name",
//       //   header: "Name",
//       //   sortingFn: "basic",
//       //   cell: (info) => <span>{info.row.original.payer_name}</span>,
//       // }),

//       columnHelper.accessor((row) => row, {
//         id: "type",
//         header: "Type",
//         sortingFn: "basic",
//         cell: (info) => (
//           <span>{info.row.original.credit > 0 ? "Receipt" : "Expenses"}</span>
//         ),
//       }),
//       columnHelper.accessor((row) => row, {
//         id: "debit",
//         header: "In",
//         sortingFn: "basic",
//         cell: (info) => (
//           <span>
//             {info.row.original.credit
//               ? `£${formatPounds(info.row.original.credit)}`
//               : ""}
//           </span>
//         ),
//       }),
//       columnHelper.accessor((row) => row, {
//         id: "credit",
//         header: "Out",
//         sortingFn: "basic",
//         cell: (info) => (
//           <span>
//             {info.row.original.debit
//               ? `£${formatPounds(info.row.original.debit)}`
//               : ""}
//           </span>
//         ),
//       }),
//       columnHelper.accessor((row) => row, {
//         id: "balance",
//         header: "Balance",
//         sortingFn: "basic",
//         cell: (info) => (
//           <span>{`£${formatPounds(info.row.original.balance)}`}</span>
//         ),
//       }),
//     ],
//     []
//   );
//   const getclientaccountdetails = useFetchReserveAccount({
//     params: {
//       client: clientid,
//       page: pageindex,
//       page_size: pagesize,
//       search: search,
//     },
//   });
//   useEffect(() => {
//     console.log(
//       getclientaccountdetails.data?.data?.results,
//       "lkjalslfjsladjlfajdslj"
//     );
//     if (getclientaccountdetails.data?.data?.results != undefined) {
//       setTable(getclientaccountdetails.data?.data?.results);
//       setCount(getclientaccountdetails.data?.data?.count);
//       setbalance(getclientaccountdetails?.data?.data?.results[0]?.balance || 0);
//     }
//   }, [getclientaccountdetails.data, pageindex, pagesize]);
//   return (
//     <div className="reserve">
//       <div
//         className="revHead"
//         // style={{
//         //   display: "flex",
//         //   justifyContent: "space-between",
//         //   alignItems: "center",
//         // }}
//       >
//         <h1>Reserve Account</h1>
//         <div className="searchReserve">
//           <Search
//             setsearch={setsearch}
//             style={{ width: "100%" }}
//             setPageIndex={setPageIndex}
//             placeholder={"Account"}
//           />
//         </div>
//       </div>
//       <div className="table_headers">
//         <div className="card">
//           <div
//             style={{
//               display: "flex",
//               justifyContent: "space-around",
//               alignItems: "center",
//               gap: "4px",
//               width: "80%",
//             }}
//           >
//             <img src={wallet} />
//             <p>Reserve Balance</p>
//           </div>
//           <h1>£{formatPounds(Balance)} </h1>
//         </div>
//         <div>
//           <DateRange style={{ width: "250px" }} />

//           <GenerateReport
//             style={{
//               padding: "0 30px",
//               fontSize: "14px",
//               display: "flex",
//               alignItems: "center",
//             }}
//             setGenerate={setGenerate}
//           />
//         </div>
//       </div>
//       <div className="main_content">
//         {getclientaccountdetails.isFetching ? (
//           <div className="skelitonDiv">
//             <Skeleton active style={{ height: "98px" }} rows={1} />
//           </div>
//         ) : (
//           <TableComponent
//             columns={columns}
//             data={table}
//             count={count}
//             pageindex={pageindex}
//             pagesize={pagesize}
//             setPageIndex={setPageIndex}
//             setPageSize={setPageSize}
//             // onclickrow={row => console.log(row)}
//           />
//         )}
//       </div>
//     </div>
//   );
// }

// import "../../../StyleSheet/clientmoney/accounts/reservedaccount.scss";
// import wallet from "../../../../Assets/Client/clientmoney/Wallet.svg";
// import Search from "../../../../MainComponent/fields/search";
// import DateRange from "../../../../MainComponent/fields/daterangepicker";
// import GenerateReport from "../../../../MainComponent/fields/generatereport";
// import TableComponent from "../../../GlobalComponents/table-v8/reacttable";
// import { createColumnHelper } from "@tanstack/react-table";
// import { useEffect, useMemo, useState } from "react";
// import {
//   useFetchReserveAccount,
//   useReserveAccountDetails,
// } from "../../../../Api/clientmonetQuery";
// import { useSelector } from "react-redux";
// import dayjs from "dayjs";
// import { formatPounds } from "../../../../Utils/Utils";
// import { Skeleton } from "antd";

// export default function ReserveAccount() {
//   const columnHelper = createColumnHelper();
//   const [search, setsearch] = useState("");
//   const [Generate, setGenerate] = useState("");
//   const clientid = useSelector((state) => state.counter.clientid);
//   const [count, setCount] = useState(0);
//   const [Balance, setbalance] = useState(0);

//   const [pageindex, setPageIndex] = useState(1);
//   const [pagesize, setPageSize] = useState(10);
//   const [table, setTable] = useState([]);

//   const columns = useMemo(
//     () => [
//       columnHelper.accessor((row) => row, {
//         id: "receipt_date",
//         header: "Date",
//         sortingFn: "basic",
//         cell: (info) => (
//           <span>
//             {dayjs(info.row.original.recpit_date).format("DD/MM/YYYY")}
//           </span>
//         ),
//       }),
//       // columnHelper.accessor((row) => row, {
//       //   id: "payer_name",
//       //   header: "Name",
//       //   sortingFn: "basic",
//       //   cell: (info) => <span>{info.row.original.payer_name}</span>,
//       // }),

//       columnHelper.accessor((row) => row, {
//         id: "type",
//         header: "Type",
//         sortingFn: "basic",
//         cell: (info) => (
//           <span>{info.row.original.credit > 0 ? "Receipt" : "Expenses"}</span>
//         ),
//       }),
//       columnHelper.accessor((row) => row, {
//         id: "debit",
//         header: "In",
//         sortingFn: "basic",
//         cell: (info) => (
//           <span>
//             {info.row.original.credit
//               ? `£${formatPounds(info.row.original.credit)}`
//               : ""}
//           </span>
//         ),
//       }),
//       columnHelper.accessor((row) => row, {
//         id: "credit",
//         header: "Out",
//         sortingFn: "basic",
//         cell: (info) => (
//           <span>
//             {info.row.original.debit
//               ? `£${formatPounds(info.row.original.debit)}`
//               : ""}
//           </span>
//         ),
//       }),
//       columnHelper.accessor((row) => row, {
//         id: "balance",
//         header: "Balance",
//         sortingFn: "basic",
//         cell: (info) => (
//           <span>{`£${formatPounds(info.row.original.balance)}`}</span>
//         ),
//       }),
//     ],
//     []
//   );
//   const getclientaccountdetails = useFetchReserveAccount({
//     params: {
//       client: clientid,
//       page: pageindex,
//       page_size: pagesize,
//       search: search,
//     },
//   });
//   useEffect(() => {
//     // console.log(
//     //   getclientaccountdetails.data?.data?.results,
//     //   "lkjalslfjsladjlfajdslj"
//     // );
//     if (getclientaccountdetails.data?.data?.results != undefined) {
//       setTable(getclientaccountdetails.data?.data?.results);
//       setCount(getclientaccountdetails.data?.data?.count);
//       setbalance(getclientaccountdetails?.data?.data?.results[0]?.balance || 0);
//     }
//   }, [getclientaccountdetails.data, pageindex, pagesize]);
//   return (
//     <div className="reserve">
//       <div className="card_parent">
//         <div className="card">
//           <div style={{ display: "flex", justifyContent: "space-between" }}>
//             <p>Reserve Balance</p>
//             <img src={wallet}></img>
//           </div>
//           <h1>£{formatPounds(Balance)}</h1>
//         </div>
//       </div>
//       <h1>Reserve Accounts</h1>
//       <div className="table_headers">
//         <div>
//           <Search
//             setsearch={setsearch}
//             style={{ width: "316px" }}
//             setPageIndex={setPageIndex}
//           />
//           <DateRange style={{ width: "300px" }} />
//         </div>
//         <GenerateReport
//           style={{ padding: "5px 30px", fontSize: "14px" }}
//           setGenerate={setGenerate}
//         />
//       </div>
//       <div className="main_content">
//         {getclientaccountdetails.isFetching ? (
//           <div className="skelitonDiv">
//             <Skeleton active style={{ height: "98px" }} rows={1} />
//           </div>
//         ) : (
//           <TableComponent
//             columns={columns}
//             data={table}
//             count={count}
//             pageindex={pageindex}
//             pagesize={pagesize}
//             setPageIndex={setPageIndex}
//             setPageSize={setPageSize}
//             // onclickrow={row => console.log(row)}
//           />
//         )}
//       </div>
//     </div>
//   );
// }

import "../../../StyleSheet/clientmoney/accounts/reservedaccount.scss";
import wallet from "../../../../Assets/Client/clientmoney/Wallet.svg";
import Search from "../../../../MainComponent/fields/search";
import DateRange from "../../../../MainComponent/fields/daterangepicker";
import GenerateReport from "../../../../MainComponent/fields/generatereport";
import TableComponent from "../../../GlobalComponents/table-v8/reacttable";
import { createColumnHelper } from "@tanstack/react-table";
import { useEffect, useMemo, useState } from "react";
import {
  useFetchReserveAccount,
  useReserveAccountDetails,
} from "../../../../Api/clientmonetQuery";
import { useSelector } from "react-redux";
import dayjs from "dayjs";
import { formatPounds } from "../../../../Utils/Utils";
import { Skeleton } from "antd";

export default function ReserveAccount() {
  const columnHelper = createColumnHelper();
  const [search, setsearch] = useState("");
  const [Generate, setGenerate] = useState("");
  const clientid = useSelector((state) => state.counter.clientid);
  const [count, setCount] = useState(0);
  const [Balance, setbalance] = useState(0);
  const [range, setRange] = useState([]);

  const [pageindex, setPageIndex] = useState(1);
  const [pagesize, setPageSize] = useState(10);
  const [table, setTable] = useState([]);

  const columns = useMemo(
    () => [
      columnHelper.accessor((row) => row, {
        id: "receipt_date",
        header: "Date",
        sortingFn: "basic",
        cell: (info) => (
          <span>
            {dayjs(info.row.original.recpit_date).format("DD/MM/YYYY")}
          </span>
        ),
      }),
      // columnHelper.accessor((row) => row, {
      //   id: "payer_name",
      //   header: "Name",
      //   sortingFn: "basic",
      //   cell: (info) => <span>{info.row.original.payer_name}</span>,
      // }),

      columnHelper.accessor((row) => row, {
        id: "type",
        header: "Type",
        sortingFn: "basic",
        cell: (info) => (
          <span>{info.row.original.credit > 0 ? "Receipt" : "Expenses"}</span>
        ),
      }),
      columnHelper.accessor((row) => row, {
        id: "debit",
        header: "In",
        sortingFn: "basic",
        cell: (info) => (
          <span>
            {info.row.original.credit
              ? `£${formatPounds(info.row.original.credit)}`
              : ""}
          </span>
        ),
      }),
      columnHelper.accessor((row) => row, {
        id: "credit",
        header: "Out",
        sortingFn: "basic",
        cell: (info) => (
          <span>
            {info.row.original.debit
              ? `£${formatPounds(info.row.original.debit)}`
              : ""}
          </span>
        ),
      }),
      columnHelper.accessor((row) => row, {
        id: "balance",
        header: "Balance",
        sortingFn: "basic",
        cell: (info) => (
          <span>{`£${formatPounds(info.row.original.balance)}`}</span>
        ),
      }),
    ],
    []
  );
  const getclientaccountdetails = useFetchReserveAccount({
    params: {
      client: clientid,
      page: pageindex,
      page_size: pagesize,
      search: search,
      from_date: range[0],
      to_date: range[1],
    },
  });
  useEffect(() => {
    if (getclientaccountdetails.data?.data?.results != undefined) {
      setTable(getclientaccountdetails.data?.data?.results);
      setCount(getclientaccountdetails.data?.data?.count);
      setbalance(getclientaccountdetails?.data?.data?.results[0]?.balance || 0);
    }
  }, [getclientaccountdetails.data, pageindex, pagesize]);
  return (
    <div className="reserve">
      <div
        className="revHead"
        // style={{
        //   display: "flex",
        //   justifyContent: "space-between",
        //   alignItems: "center",
        // }}
      >
        <h1>Reserve Account</h1>
        <div className="searchReserve">
          <Search
            setsearch={setsearch}
            style={{ width: "100%" }}
            setPageIndex={setPageIndex}
            placeholder={"Account"}
          />
        </div>
      </div>
      <div className="table_headers">
        <div className="card">
          <div
            style={{
              display: "flex",
              justifyContent: "space-around",
              alignItems: "center",
              gap: "4px",
            }}
          >
            <img src={wallet} alt="wallet" />
            <p>Reserve Balance</p>
          </div>
          <h1>£{formatPounds(Balance)} </h1>
        </div>
        <div>
          {/* <DateRange style={{ width: "250px" }} /> */}
          <DateRange
            onChange={(e) => {
              setRange(e);
            }}
            minDate={new Date(2020, 0, 1)}
            maxDate={new Date()}
            style={{ width: "250px" }}
          />
        </div>
      </div>
      <div className="main_content">
        {getclientaccountdetails.isFetching ? (
          <div className="skelitonDiv">
            <Skeleton active style={{ height: "98px" }} rows={1} />
          </div>
        ) : (
          <TableComponent
            columns={columns}
            data={table}
            count={count}
            pageindex={pageindex}
            pagesize={pagesize}
            setPageIndex={setPageIndex}
            setPageSize={setPageSize}
            // onclickrow={row => console.log(row)}
          />
        )}
      </div>
    </div>
  );
}
