import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import Routes from "./Routes/routes";
import reportWebVitals from "./reportWebVitals";
import store, { persistor } from "../src/redux/store/store";
import { Provider } from "react-redux";
import { RouterProvider } from "react-router-dom";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { Toaster } from "sonner";
import { PersistGate } from "redux-persist/integration/react";
import CircularIndeterminate from "./MainComponent/Loader";
import { useState, useEffect } from "react";
import LowWidthPopup from "./Client/GlobalComponents/LowWidthPopup";
// import SideBarComponent from "./Client/GlobalComponents/Sidebar/sidebar";

// const root = ReactDOM.createRoot(document.getElementById("root"));

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      retry: 1,
    },
  },
});

// root.render(
//   <Provider store={store}>
//     <PersistGate loading={null} persistor={persistor}>
//       <Toaster richColors />
//       <QueryClientProvider client={queryClient}>
//         <RouterProvider router={Routes}>
//           <CircularIndeterminate />
//         </RouterProvider>
//       </QueryClientProvider>
//     </PersistGate>
//   </Provider>
// );

// reportWebVitals();

const App = () => {
  const [lowWidthPopup, setLowWidthPopup] = useState(false);

  const checkScreenWidth = () => {
    if (window.innerWidth < 750) {
      setLowWidthPopup(true); // Show the popup if width < 785px
    } else {
      setLowWidthPopup(false); // Hide the popup if width >= 785px
    }
  };

  useEffect(() => {
    checkScreenWidth(); // Check on load
    window.addEventListener("resize", checkScreenWidth); // Check on resize

    return () => {
      window.removeEventListener("resize", checkScreenWidth); // Cleanup listener on unmount
    };
  }, []);

  return (
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <Toaster richColors />
        <QueryClientProvider client={queryClient}>
          {lowWidthPopup ? (
            <LowWidthPopup />
          ) : (
            <RouterProvider router={Routes}>
              <CircularIndeterminate />
            </RouterProvider>
          )}
        </QueryClientProvider>
      </PersistGate>
    </Provider>
  );
};

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(<App />);

reportWebVitals();
