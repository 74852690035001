import { useState, useEffect } from "react";
import "./App.css";
import SideBarComponent from "./Client/GlobalComponents/Sidebar/sidebar";
import { Outlet } from "react-router-dom";
import CircularIndeterminate from "./MainComponent/Loader";
import { useSelector } from "react-redux";
import LowWidthPopup from "./Client/GlobalComponents/LowWidthPopup";

function App() {
  const loading = useSelector((state) => state.counter.loader);

  return (
    <>
      {/* {loading && <CircularIndeterminate />} */}

      <div className="App">
        <div className="sidebar">
          <SideBarComponent />
        </div>
        <div className="outlet">
          <Outlet />
        </div>

        <CircularIndeterminate />
      </div>
    </>
  );
}

export default App;
