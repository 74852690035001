import { useEffect, useState } from "react";
import "./style.scss";
import { useDebounce } from "use-debounce";

export default function Search(props) {
  const [searchValues, setSearchValues] = useState(props?.search || "");
  const [searchValue] = useDebounce(searchValues, 600);

  // const isCharacterAllowed = (char) => {
  //   const regex = /^[a-zA-Z0-9 ]*$/;
  //   return regex.test(char);
  // };
  const isCharacterAllowed = (char) => {
    const regex = /^[a-zA-Z0-9 ]*$/;
    return regex.test(char);
  };

  useEffect(() => {
    props.setsearch(searchValue);
    props.setPageIndex(1);
  }, [searchValue]);

  useEffect(() => {
    console.log(props?.placeholder, "lkjasdfajslfdjsld");
  });
  return (
    <div
      className="search_main"
      style={{
        backgroundColor: props?.backgroundColor
          ? props?.backgroundColor
          : "#F7F9FB",
      }}
    >
      <i className="bi bi-search" style={{ color: "#A8C5DA" }}></i>

      <input
        className="search_field"
        placeholder={
          props?.placeholder ? `Search ${props?.placeholder}` : "Search"
        }
        value={searchValues}
        style={{
          border: "none",
          backgroundColor: "transparent",
          // height: "40px",
          cursor: "pointer",
          ...props?.style,
        }}
        // onKeyDown={(e) => {
        //   if (!isCharacterAllowed(e.key)) {
        //     e.preventDefault();
        //   }
        //   if (e.target.value.length === 0 && e.key === " ") {
        //     e.preventDefault();
        //   }
        // }}
        onKeyDown={(e) => {
          if (
            !isCharacterAllowed(e.key) ||
            (e.target.value.length === 0 && e.key === " ") ||
            (e.target.value[e.target.value.length - 1] === " " && e.key === " ")
          ) {
            e.preventDefault();
          }
        }}
        onChange={(e) => {
          setSearchValues(e.target.value);
        }}
        maxLength="50"
      />
    </div>
  );
}
