import React, { useState } from "react";
import "../StyleSheet/CustomCheckButtons.scss";
import tickwhite from "../../Assets/Customcheckbuttons/tickwhite.svg";
import tick from "../../Assets/Customcheckbuttons/tick.svg";
import crosswhite from "../../Assets/Customcheckbuttons/crosswhite.svg";
import cross from "../../Assets/Customcheckbuttons/cross.svg";

export default function CustomCheckButtons(props) {
  const {
    isChecked,
    setIsChecked,
    onChange,
    inputStyles,
    boxStyles,
    disabled,
    styles,
  } = props;
  // const [isChecked, setIsChecked] = useState(true);
  const handleChange = (e) => {
    // setIsChecked(!isChecked);
    if (onChange) {
      setIsChecked(e);
      onChange(e);
    }
  };
  return (
    <div className="CheckButtons_wrapper" style={styles}>
      <button
        className={`cross ${!isChecked ? "active" : ""}`}
        onClick={(e) => {
          e.stopPropagation();
          handleChange(false);
        }}
      >
        {!isChecked ? <img src={crosswhite}></img> : <img src={cross}></img>}
      </button>
      <button
        className={`Tick ${isChecked ? "active" : ""}`}
        onClick={(e) => {
          e.stopPropagation();
          handleChange(true);
        }}
      >
        {isChecked ? <img src={tickwhite}></img> : <img src={tick}></img>}
      </button>
    </div>
  );
}
