import React, { useMemo, useState, useEffect } from "react";
import "../../StyleSheet/Maintenance/maintenancearchive.scss";
import logo from "../../../Assets/Client/maintenence/maintanence_t_ogo.svg";
import arrow from "../../../Assets/Common/Frame 427319102.svg";
import TableComponent from "../../GlobalComponents/table-v8/reacttable";
import { createColumnHelper } from "@tanstack/react-table";
import Search from "../../../MainComponent/fields/search";
import DateRange from "../../../MainComponent/fields/daterangepicker";
import { useFetchMaintenanceArchive } from "../../../Api/MaintenanceQuery";
import { useSelector } from "react-redux";
import dayjs from "dayjs";
import { formatAddress, formatPounds } from "../../../Utils/Utils";
import { Skeleton } from "antd";
const MaintenanceArchive = () => {
  const columnHelper = createColumnHelper();
  const [search, setsearch] = useState("");
  const [switcher, setswitcher] = useState(false);
  const [popup, setpopup] = useState(false);
  const [rowvalue, setrowvalue] = useState([]);
  const loginuserdata = useSelector((state) => state.counter.loginuserdata);
  const [tableData, setTableData] = useState([]);
  const [count, setCount] = useState(0);
  const [pageindex, setPageIndex] = useState(1);
  const [pagesize, setPageSize] = useState(10);
  const fetchMaintenanceData = useFetchMaintenanceArchive({
    id: loginuserdata.id,
    status: "Resolved",
    page: pageindex,
    page_size: pagesize,
    search: search,
  });
  const columns = useMemo(
    () => [
      columnHelper.accessor((row) => row, {
        id: "date",
        sortingFn: "basic",
        cell: (info) => (
          <p style={{ fontSize: "14px" }}>
            {dayjs(info.row.original.created_date).format("DD/MM/YYYY")}
          </p>
        ),
        header: () => <span>Date</span>,
      }),
      columnHelper.accessor((row) => row, {
        id: "address",
        sortingFn: "basic",
        cell: (info) => {
          const data = info.row.original.property;
          return (
            <p style={{ fontSize: "14px" }}>
              {formatAddress([
                data.name,
                data.street,
                data.city,
                data.postcode,
              ])}
            </p>
          );
        },
        header: () => <span>Address</span>,
      }),
      columnHelper.accessor((row) => row, {
        id: "summary",
        enableSorting: false,
        cell: (info) => {
          return (
            <p style={{ fontSize: "14px" }}>{info.row.original.description}</p>
          );
        },
        header: () => <span>Summary</span>,
      }),
      columnHelper.accessor((row) => row, {
        id: "amount",
        sortingFn: "basic",
        cell: (info) => {
          return (
            <p style={{ fontSize: "14px" }}>
              £{formatPounds(info.row.original.invoice_amount)}
            </p>
          );
        },
        header: () => <span>Amount</span>,
      }),
    ],
    []
  );
  const clickrow = (row) => {
    console.log(row);
    setpopup(true);
    setrowvalue(row);
  };
  useEffect(() => {
    const statusCount = fetchMaintenanceData?.data?.data?.status_count;
    console.log(fetchMaintenanceData.data, "fetchMaintenanceData");
    if (statusCount) {
      setTableData(fetchMaintenanceData?.data?.data?.results);
      setCount(fetchMaintenanceData?.data?.data?.count);
    }
  }, [fetchMaintenanceData.data]);
  return (
    <div className="maintenace_archive">
      <div className="maintenance_content_wrapper">
        <div className="header">
          <img src={logo}></img>
          <h1>Maintenance History</h1>
        </div>
        <div className="maintenace_log_wrapper">
          <div
            // className="maintance_log_headwrap"
            style={{
              display: "flex",
              justifyContent: "space-between",
              // alignItems: "center",
              marginBottom: "20px",
              // padding: "0 24px",
            }}
          >
            <div
              className="maintance_log_headwrap"
              style={{ display: "flex", gap: "15px" }}
            >
              <div className="ssseee">
                <Search
                  setsearch={setsearch}
                  style={{ width: "215px" }}
                  setPageIndex={setPageIndex}
                  placeholder={"Maintance"}
                />
              </div>
              <DateRange />
            </div>
            <button
              style={{
                border: "none",
                padding: "10px max(12px, 1.3vw)",
                borderRadius: "5px",
                background: "rgba(129, 126, 255, 1)",
                color: "white",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                cursor: "pointer",
                gap: "5px",
                flexWrap: "nowrap",
                height: "fit-content",
                textWrap: "nowrap",
              }}
            >
              Generate Report
              <img src={arrow} alt="arrow" width={"12px"} />
            </button>
          </div>
          <div className="main_content">
            {fetchMaintenanceData.isFetching ? (
              <div className="skelitonDiv">
                <Skeleton active style={{ height: "98px" }} rows={1} />
              </div>
            ) : (
              <TableComponent
                data={tableData}
                columns={columns}
                count={count}
                pageindex={pageindex}
                pagesize={pagesize}
                setPageIndex={setPageIndex}
                setPageSize={setPageSize}
              />
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default MaintenanceArchive;
