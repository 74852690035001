import React, { useEffect } from "react";
import { useParams } from "react-router-dom";
import { useAdminLogin } from "../../Api/homeQuery";
import { loginuserdata, token } from "../../redux/reducer/counterslice";
import { useDispatch } from "react-redux";

const AdminLogin = () => {
  const { id } = useParams();
  const dispatch = useDispatch();
  const payload = {
    id: id,
    request_type: "client",
  };
  useEffect(() => {
    localStorage.clear();
  }, []);

  const adminLogin = useAdminLogin(payload);

  useEffect(() => {
    console.log(id, "jsdsdsjkd");
    adminLogin.mutate();
  }, [id]);

  return;
};

export default AdminLogin;
