import "./style.scss";
export default function GenerateReport(props) {
  return (
    <button
      className="generate_report"
      style={{ ...props?.style }}
      onClick={(e) => {
        props?.setGenerate(e.target.value);
      }}
    >
      <span>Generate</span> <i className="bi bi-arrow-up-right"></i>
    </button>
  );
}
