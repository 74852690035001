import "../../StyleSheet/dashboard/payments.scss";
import { payments } from "../../Content/image";
import { useDispatch, useSelector } from "react-redux";
import {
  useArrears,
  useFetchWorkOrderNumber,
  usePayments,
} from "../../../Api/homeQuery";
import { useEffect, useState } from "react";
import { formatPoundsNoDec } from "../../../Utils/Utils";
import { useNavigate } from "react-router-dom";
import {
  setClientMoneyAccountStatus,
  setClientMoneyStatus,
  setClientPaymentDueFilter,
  setClientRentPopup,
  setClientRentPopupData,
} from "../../../redux/reducer/counterslice";
import ClientMoneyOverviewPopup from "../clientmoney/popups/clientmoneyoverviewpopup";
import { useTodaysRentAgency } from "../../../Api/clientmonetQuery";

export default function PaymentsHome() {
  const clientid = useSelector((state) => state.counter.clientid);
  const [Data, setData] = useState([]);
  const navigate = useNavigate();
  const [amountData, setamountData] = useState([]);
  const [awaitingapprovalcount, setawaitingapprovalcount] = useState(0);
  const fetchMaintenanceCount = useFetchWorkOrderNumber({
    params: { client: clientid },
    loading: true,
  });
  const getAgencyrent = useTodaysRentAgency({
    params: {
      client: clientid,
      type: "Rent",
    },
  });
  const keydata = useSelector((state) => state.counter.clientRentPopupData);
  const popup = useSelector((state) => state.counter.clientRentPopup);
  const dispatch = useDispatch();

  useEffect(() => {
    setawaitingapprovalcount(fetchMaintenanceCount?.data?.data?.[3]?.count);
  }, [fetchMaintenanceCount?.data]);
  const [todaysrent, setTodaysrent] = useState(0);

  useEffect(() => {
    if (getAgencyrent.data) {
      var totaltodaysrent = 0;
      getAgencyrent?.data?.data?.data.map((ele) => {
        totaltodaysrent += ele.amount;
      });
      setTodaysrent(totaltodaysrent);
    }
  }, [getAgencyrent.data]);

  const getpayments = usePayments({
    params: { client: clientid },
  });

  useEffect(() => {
    if (getpayments.data != undefined) {
      setamountData(getpayments.data.data);
    }
  }, [getpayments.data]);

  return (
    <div className="payents_main_wrapper">
      <div className="listdata">
        <ul>
          <li
            className={`${
              amountData?.payments_received !== 0 ? "animated" : "notAnimated"
            }`}
            style={{ paddingTop: "3.5%" }}
            onClick={() => {
              // navigate("/clientmoney");
              // dispatch(setClientMoneyStatus("Overview"));
              if (amountData?.payments_received > 0) {
                dispatch(
                  setClientRentPopupData({
                    text: "Today’s Rents",
                    subtext: "",
                  })
                );
                setTimeout(() => {
                  dispatch(setClientRentPopup(true));
                }, 200);
              }
            }}
          >
            <div>
              <img src={payments[0]}></img>
              <h2>Rent Received</h2>
            </div>
            <p>£{formatPoundsNoDec(amountData?.payments_received)}</p>
          </li>
          <li
            className={`${
              amountData?.payment_due !== 0 ? "animated" : "notAnimated"
            }`}
            onClick={() => {
              if (amountData?.payment_due > 0) {
                dispatch(setClientMoneyStatus("Payments"));
                dispatch(setClientPaymentDueFilter("p_due"));
                navigate("/clientmoney");
              }
            }}
          >
            <div>
              <img src={payments[1]}></img>
              <h2>Payments Due</h2>
            </div>
            {/* <p>
              £ 
              {formatPounds(
                Data?.charges_0_7_days +
                  Data?.charges_8_14_days +
                  Data?.charges_15_30_days +
                  Data?.charges_31_plus_days || 0
              )}
            </p> */}
            <p>£{formatPoundsNoDec(amountData?.payment_due)}</p>
          </li>
          <li
            className={`${
              amountData?.reserve_funds !== 0 ? "animated" : "notAnimated"
            }`}
            onClick={() => {
              if (amountData?.reserve_funds > 0) {
                dispatch(setClientMoneyStatus("Accounts"));
                dispatch(setClientMoneyAccountStatus("Reserve Account"));
                navigate("/clientmoney");
              }
            }}
          >
            <div>
              <img src={payments[2]}></img>
              <h2>Reserve Funds</h2>
            </div>
            <p>£{formatPoundsNoDec(amountData?.reserve_funds)}</p>
          </li>
          <li
            className={`${
              awaitingapprovalcount !== 0 ? "animated" : "notAnimated"
            }`}
            style={{ border: "none", paddingBottom: "14px" }}
            onClick={() => {
              navigate("/maintenance/approval");
            }}
          >
            <div>
              <img src={payments[3]}></img>
              <h2>Awaiting Approval</h2>
            </div>
            <p>{awaitingapprovalcount || 0}</p>
          </li>
        </ul>
      </div>
      {popup && (
        <ClientMoneyOverviewPopup
          keydata={keydata}
          todayRent={todaysrent}
          todaysAgencyIncome={""}
        />
      )}
    </div>
  );
}
