import React, { useEffect, useState } from "react";
import "../popups/PropertyCreate.scss";
import closeIcon from "../../../../Assets/Common/closeIcon.svg";
import archiveDownloadIcon from "../../../../Assets/Common/archiveDownloadIcon.svg";
import regularPdfIcon from "../../../../Assets/Common/regularPdfIcon.svg";
import { useFetchArchive_document } from "../../../../Api/PropertiesQuery";
import dayjs from "dayjs";

const ArchivePopup = (props) => {
  const { isOpen, setIsOpen, dataFromParent, payloadForArchiveApi } = props;
  const fetchArchiveDoc = useFetchArchive_document(payloadForArchiveApi);
  const [data, setData] = useState([]);

  useEffect(() => {
    console.log(payloadForArchiveApi, "payloadForArchiveApi");
    if (fetchArchiveDoc.data?.data !== undefined) {
      console.log(fetchArchiveDoc.data, "fetchArchiveDoc.data");
      setData(
        Array.isArray(fetchArchiveDoc.data?.data)
          ? fetchArchiveDoc.data?.data
          : []
      );
    }
  }, [payloadForArchiveApi, fetchArchiveDoc.data]);

  return (
    <div>
      {isOpen && (
        <>
          <div className="overlay" />
          <div className="popup">
            <div className="popup-content">
              <div className="popup_top">
                <div />
                <h2>Archived: {dataFromParent?.custom_name}</h2>
                <button
                  type="button"
                  onClick={() => {
                    setIsOpen(false);
                  }}
                >
                  <img
                    src={closeIcon}
                    alt="closeIcon"
                    style={{
                      width: "70%,",
                      height: "70%",
                    }}
                  />
                </button>
              </div>
              <div
                className="popup_center"
                style={{
                  height: "400px",
                  overflow: "auto",
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                {data?.map((card) => {
                  return (
                    <div
                      style={{
                        borderBottom: "1px solid #808080",
                        width: "100%",
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                        padding: "0px 0 20px 0",
                        marginBottom: "10px",
                      }}
                    >
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                          alignItems: "center",
                          padding: "5px 0",
                          gap: "20px",
                        }}
                      >
                        <img
                          src={regularPdfIcon}
                          alt="regularPdfIcon"
                          style={{ width: "80%", height: "80%" }}
                        />
                        <span style={{ fontSize: "max(14px, 1.2vw)" }}>
                          {/* Energy Performance Certificate (EPC)
                           */}
                          {card.custom_name}
                        </span>
                      </div>
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                          alignItems: "center",
                          padding: "5px 0",
                          gap: "10px",
                        }}
                      >
                        <span
                          style={{
                            fontSize: "max(12px, 1.2)",
                            color: "#817EFF",
                          }}
                        >
                          Expiry Date
                        </span>
                        <span style={{ fontSize: "max(12px, 1.2)" }}>
                          {dayjs(card?.expiry_date).format("DD MMMM YYYY")}
                        </span>
                        <img
                          src={archiveDownloadIcon}
                          alt="archiveDownloadIcon"
                          style={{
                            width: "30px",
                            height: "30px",
                            cursor: "pointer",
                          }}
                          onClick={() => {
                            window.open(card.file?.[0], "_blank");
                          }}
                        />
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default ArchivePopup;
