import {
  keepPreviousData,
  useMutation,
  useQuery,
  useQueryClient,
} from "@tanstack/react-query";
// import { toast } from "sonner";
import { useResponseHandler } from "./useResponseHandler";
import { toast } from "sonner";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { clientid, loginuserdata, token } from "../redux/reducer/counterslice";

// Reusable query function
function useFetchData(queryKey, url, payload) {
  const { handleResponse } = useResponseHandler();

  return useQuery({
    queryKey,
    queryFn: async () =>
      handleResponse(url + (payload ? `/${payload}` : ""), "get"),
    placeholderData: keepPreviousData,
  });
}

export function useArrears(payload) {
  const { handleResponse } = useResponseHandler();
  return useQuery({
    queryKey: ["Arrearsget", payload.params.id],
    queryFn: async () => {
      // if (payload.params?.date_range) {
      const uploadpayload = {
        params: { client: payload.params.id },
      };

      const response = handleResponse(
        "accounts/arrears/v1/",
        "get",
        uploadpayload,
        payload.loading
      );
      return response;
      // }
    },
    placeholderData: keepPreviousData,
  });
}

export function useFetchArrearsSelected(payload) {
  const { handleResponse } = useResponseHandler();
  return useQuery({
    queryKey: ["ArrearsGetSelected", payload],
    queryFn: async () => {
      if (payload.date_range) {
        const uploadPayload = {
          params: payload,
        };
        const response = handleResponse(
          "accounts/arrears_summary/v1",
          "get",
          uploadPayload,
          false
        );
        return response;
      }
    },
    placeholderData: keepPreviousData,
  });
}

export function useFetchComplienceSelected(payload) {
  const { handleResponse } = useResponseHandler();
  return useQuery({
    queryKey: ["CompliencegetSelected", payload?.params],
    queryFn: async () => {
      if (payload?.params?.page && payload?.params?.id) {
        console.log(payload, "lkajsdjasjdf");
        const uploadPayload = {
          params: payload.params,
        };
        const response = handleResponse(
          "property/document_status/v1/",
          "get",
          uploadPayload,
          false
        );
        return response;
      }
    },
    // placeholderData: keepPreviousData,
  });
}

export function usePayments(payload) {
  const { handleResponse } = useResponseHandler();
  return useQuery({
    queryKey: ["getPayments", payload.client],
    queryFn: async () => {
      const uploadPayload = {
        params: payload.params,
      };
      const response = handleResponse(
        "accounts/client_payd_account/v1/",
        "get",
        uploadPayload
      );
      return response;
    },
    placeholderData: keepPreviousData,
  });
}

export function useFetchWorkOrderNumber(payload) {
  const { handleResponse } = useResponseHandler();
  return useQuery({
    queryKey: ["workOrderNumber", payload],
    queryFn: async () => {
      const uploadPayload = {
        params: payload.params,
      };
      const response = handleResponse(
        "workorder/status_counts/",
        "get",
        uploadPayload,
        payload.loading
      );
      return response;
    },
    placeholderData: keepPreviousData,
  });
}

export function useFetchWorkOrderBasedStatus(payload) {
  const { handleResponse } = useResponseHandler();

  return useQuery({
    queryKey: ["getworkorderdetails", payload],
    queryFn: async () => {
      const uploadPayload = {
        params: payload.params,
      };
      const response = handleResponse(
        "workorder/workorder/v1/",
        "get",
        uploadPayload,
        false
      );
      return response;
    },
    placeholderData: keepPreviousData,
  });
}

export function useFetchCurrentOccupancyStatus(payload) {
  const { handleResponse } = useResponseHandler();

  return useQuery({
    queryKey: ["CurrentOccupancy", payload.params.client],
    queryFn: async () => {
      const uploadPayload = {
        params: payload.params,
      };
      const response = handleResponse(
        "property/property_occupency/v1/",
        "get",
        uploadPayload
      );
      return response;
    },
    placeholderData: keepPreviousData,
  });
}

export function useCreateInvite(payload) {
  const { handleResponse } = useResponseHandler();
  const queryClient = useQueryClient();
  console.log(payload, "8239812739");
  return useMutation({
    mutationFn: async () => {
      const uploadPayload = {
        data: payload.data,
      };
      return handleResponse(
        `manager/c_password_update/${payload.id}`,
        "put",
        uploadPayload
      );
    },
    onSuccess: () => {
      toast.success(`Account has been created successfully.`);
      payload.setAccountSuccess(true);
    },
    onError: (error) => {
      console.log(error, "error in progression");
      toast.error("Error occurred while creating account.");
    },
  });
}
// export function useLinkValidation(payload) {
//   const { handleResponse } = useResponseHandler();

//   return useQuery({
//     queryKey: ["validLink", payload],
//     queryFn: async () => {
//       const uploadPayload = {
//         params: payload.params,
//       };
//       const response = handleResponse(
//         "manager/link_valid/",
//         "get",
//         uploadPayload
//       );
//       return response;
//     },
//     onError: (error) => {
//       console.log(error, "error in progression");
//       toast.error("Error occurred while creating account.");
//     },
//     placeholderData: keepPreviousData,
//   });
// }

export function useLinkValidation(payload) {
  const { handleResponse } = useResponseHandler();

  return useQuery({
    queryKey: ["validate", payload],
    queryFn: async () => {
      const uploadPayload = {
        params: payload.params,
      };

      return handleResponse("manager/link_valid/", "get", uploadPayload);
      // console.log(response, "payload");

      // console.log(response, "kajsflkjasjdklkfjlj");
      // return response;
    },
    onError: (error) => {
      console.log(error, "error in progression");
      toast.error("Error occurred while processing.");
    },
    // onSuccess
    placeholderData: keepPreviousData,
  });
}

// export function useForgetPassword(payload) {
//   const { handleResponse } = useResponseHandler();
//   const navigate = useNavigate();

//   return useMutation({
//     mutationFn: async () =>
//       handleResponse(`manager/forgetinvite_manager/`, "post", {
//         data: payload,
//       }),
//     onSuccess: () => {
//       toast.success(`Resent mail has been sent to ${payload.email}.`);
//       navigate("/login");
//     },
//     onError: (error) => {
//       console.log(error, "error login");
//       toast.error(`${payload.email} is not associated with this portal.`);
//     },
//   });
// }

export function useAdminLogin(payload) {
  const { handleResponse } = useResponseHandler();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  console.log(payload, "pradap");
  return useMutation({
    mutationFn: async () => {
      if (payload.id) {
        return handleResponse("manager/admin_login/", "post", {
          data: payload,
        });
      }
    },
    onSuccess: (data) => {
      console.log(data, "data object");
      toast.success(`Logged as admin.`);
      localStorage.setItem("login", "false");
      localStorage.setItem("token", data.session.token);
      dispatch(token(data.session.token));
      dispatch(loginuserdata(data.data));
      dispatch(clientid(data.data.id));
      // toast.success("Login Success");
      navigate("/");
    },
    onError: (error) => {
      console.log(" invalid error login");
      navigate("/login");

      toast.error("");
    },
  });
}
