import React, { useState } from "react";
import "./CustomButton.scss";

const CustomButton = (props) => {
  const {
    title,
    handleClick,
    active,
    disabled = false, // Default to false if not provided
    styles = {},
    enterStyle = {},
    leaveStyle = {},
  } = props;

  const [isHovered, setHovered] = useState(false);
  const calculatedStyle = {
    ...styles,
    ...(isHovered ? enterStyle : leaveStyle),
  };

  return (
    <span>
      <button
        className={`customButton ${active ? "activeCustomButton" : ""}`}
        onMouseEnter={() => setHovered(true)}
        onMouseLeave={() => setHovered(false)}
        onClick={(e) => {
          e.preventDefault();
          handleClick(e);
        }}
        disabled={disabled}
        style={calculatedStyle}
      >
        {title}
      </button>
    </span>
  );
};

export { CustomButton };
