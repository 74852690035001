import "../../StyleSheet/clientmoney/clientmoney.scss";
import ClientMoneyOverview from "./clientmoneyoverview";
import Accounts from "./accounts";
import PaymentDue from "./paymentdue";
import Reports from "./reports";
import { useDispatch, useSelector } from "react-redux";
import { setClientMoneyStatus } from "../../../redux/reducer/counterslice";
export default function ClientMoney() {
  const toggle = useSelector((state) => state.counter.clientMoneyStatus);
  const dispatch = useDispatch();
  return (
    <div className="ClientMoney">
      <div className="ClientMoney_main">
        <div
          className="tab-container"
          style={{
            caretShape: "unset",
            caretColor: "transparent",
            width: "100%",
          }}
        >
          {["Overview", "Accounts", "Payments", "Reports"].map((tab) => (
            <div
              key={tab}
              className={`tab ${toggle === tab ? "active" : ""}`}
              onClick={() => dispatch(setClientMoneyStatus(tab))}
              style={{ caretShape: "unset", caretColor: "transparent" }}
            >
              <p style={{ caretShape: "unset", caretColor: "transparent" }}>
                {tab}
              </p>
            </div>
          ))}
        </div>
        <div className="overview_main">
          {toggle === "Overview" ? (
            <>
              <ClientMoneyOverview />
            </>
          ) : toggle === "Accounts" ? (
            <Accounts />
          ) : toggle === "Payments" ? (
            <PaymentDue />
          ) : toggle === "Reports" ? (
            <Reports />
          ) : (
            ""
          )}
        </div>
      </div>
    </div>
  );
}
