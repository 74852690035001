import React, { useDebugValue, useEffect, useMemo, useState } from "react";
import "../../StyleSheet/Maintenance/maintenancelog.scss";
import logo from "../../../Assets/Client/maintenence/maintanence_t_ogo.svg";
import arrow from "../../../Assets/Common/Frame 427319102.svg";
import TableComponent from "../../GlobalComponents/table-v8/reacttable";
import { createColumnHelper } from "@tanstack/react-table";
import eximg from "../../../Assets/Common/property_eximg.png";
import eximg1 from "../../../Assets/Common/property_eximg1.png";
import Search from "../../../MainComponent/fields/search";
import {
  useFetchMaintenanceFilterStatus,
  useFetchMaintenanceTable,
} from "../../../Api/MaintenanceQuery";
import { useDispatch, useSelector } from "react-redux";
import dayjs from "dayjs";
import { addLeadingZero } from "../../../Utils/Utils";
import {
  setContactDetailsFilter,
  setMaintenanceFilter,
  setPropertyDetailsFilter,
} from "../../../redux/reducer/counterslice";
import { useFetchPropertiesCompliance } from "../../../Api/PropertiesQuery";
import Propertydetails from "../Property/popups/propertydetails";
import { Skeleton } from "antd";
// import ""
const MaintenanceLog = () => {
  const columnHelper = createColumnHelper();
  const [rowvalue, setrowvalue] = useState([]);
  const loginuserdata = useSelector((state) => state.counter.loginuserdata);
  const filterStatus = useSelector((state) => state.counter.maintenanceFilter);
  const [tableData, setTableData] = useState([]);
  const [search, setSearch] = useState("");
  const [count, setCount] = useState(0);
  const [pageindex, setPageIndex] = useState(1);
  const [pagesize, setPageSize] = useState(10);
  const [filtersOptions, setFilterOptions] = useState();
  const [selectedPropertyId, setSelectedPropertyId] = useState("");
  const [popup, setpopup] = useState(false);
  const dispatch = useDispatch();
  const fetchProperties = useFetchPropertiesCompliance(selectedPropertyId);
  function formatAddress(addressParts) {
    return addressParts
      .filter((part) => part) // Filters out undefined, null, and empty strings
      .join(", ");
  }
  const formatPounds = (amount) => {
    return new Intl.NumberFormat("en-GB", {
      style: "currency",
      currency: "GBP",
      minimumFractionDigits: 0,
      maximumFractionDigits: 0,
    })
      .format(amount)
      .replace("£", "")
      .trim();
  };
  const columns = useMemo(
    () => [
      columnHelper.accessor((row) => row.created_date, {
        id: "date",
        cell: (info) => (
          <p style={{ fontSize: "14px" }}>
            {dayjs(info.row.original.created_date).format("DD/MM/YYYY")}
          </p>
        ),
        header: () => <span>Reported Date</span>,
      }),
      columnHelper.accessor((row) => row.status, {
        id: "status",
        cell: (info) => {
          console.log(info.getValue(), "laksdjflasdlfjlaj");
          const data = info.row.original.status;

          return (
            <div
              style={{
                width: "195px",
                display: "flex",
                justifyContent: "flex-start",
                alignItems: "center",
                padding: "8px 0px",
                borderRadius: "10px",
                fontWeight: "500",
                fontSize: "16px",
                gap: "6px",
                marginLeft: "6px",
              }}
            >
              <span
                style={{
                  width: "10px",
                  height: "10px",
                  borderRadius: "2px",
                  background:
                    data === "Quote Requested"
                      ? "#FFD47C"
                      : data === "New Requests"
                      ? "#97E9AC"
                      : data === "Awaiting payment"
                      ? "#FFB693"
                      : data === "Awaiting Approval"
                      ? "#FF8485"
                      : data === "Approved"
                      ? "#7DD1F3"
                      : data === "Booked"
                      ? "#8889F4"
                      : data === "Rejected"
                      ? "#EFA6A6"
                      : data === "Resolved"
                      ? "#A6EFC3"
                      : data === "More Info"
                      ? "#AC7AFF"
                      : "",
                }}
              ></span>
              <span style={{ fontSize: "14px" }}>{info.getValue()}</span>
            </div>
          );
        },
        header: () => <span>Status</span>,
      }),
      columnHelper.accessor((row) => row, {
        id: "address",
        cell: (info) => {
          const data = info.row.original.property;
          return (
            <span
              className="contentHover"
              style={{ wordBreak: "break-all" }}
              onClick={() => {
                dispatch(setPropertyDetailsFilter("1"));
                dispatch(setContactDetailsFilter(1));
                setSelectedPropertyId(info.row.original?.property?.id);
                setpopup(true);
              }}
            >
              {formatAddress([
                data.name + " " + data.street,
                data.city,
                data.postcode,
              ])}
            </span>
          );
        },
        header: () => <span>Address</span>,
      }),
      columnHelper.accessor((row) => row, {
        id: "summary",
        cell: (info) => {
          return (
            <p style={{ fontSize: "14px", display: "flex", maxWidth: "350px" }}>
              {/* {info.row.original.description} */}
              {info.row.original.title}
            </p>
          );
        },
        header: () => <span>Summary</span>,
      }),
      columnHelper.accessor((row) => row, {
        id: "amount",
        cell: (info) => {
          return (
            <p style={{ fontSize: "14px" }}>
              £
              {info.row.original.invoice_amount == 0
                ? 0.0
                : formatPounds(info.row.original.invoice_amount)}
            </p>
          );
        },
        header: () => <span>Amount</span>,
      }),
    ],
    []
  );
  const fetchMaintenanceData = useFetchMaintenanceTable({
    id: loginuserdata.id,
    status: filterStatus,
    search: search,
    page: pageindex,
    page_size: pagesize,
    type: false,
  });
  const fetchStatus = useFetchMaintenanceFilterStatus({
    id: loginuserdata.id,
    type: false,
  });
  const clickrow = (row) => {
    console.log(row);
    setrowvalue(row);
  };

  useEffect(() => {
    if (fetchMaintenanceData?.data?.data?.results) {
      setTableData(fetchMaintenanceData?.data?.data?.results);
      setCount(fetchMaintenanceData?.data?.data?.count);
    }
  }, [fetchMaintenanceData?.data]);

  useEffect(() => {
    const statusCount = fetchStatus?.data?.data;

    if (statusCount) {
      // const totalCount = statusCount.reduce(
      //   (total, item) => total + (item.count || 0),
      //   0
      // );
      const totalCount = statusCount
        .slice(0, -2)
        .reduce((total, item) => total + (item.count || 0), 0);

      const filterOptions = [
        {
          label: "All",
          count: addLeadingZero(totalCount),
          background: "#E6E7FF",
          value: "all",
        },
        {
          label: "New Request",
          count: addLeadingZero(
            statusCount
              .slice(0, 2)
              .reduce((total, item) => total + (item.count || 0), 0)
          ),
          background: "#97E9AC",
          value: "New Requests",
        },
        {
          label: "Quote Requested",
          count: addLeadingZero(statusCount[2]?.count || 0),
          background: "#FFD47C",
          value: "Quote Requested",
        },
        {
          label: "Awaiting Approval",
          count: addLeadingZero(statusCount[3]?.count || 0),
          background: "#FF8485",
          value: "Awaiting Approval",
        },
        {
          label: "Approved",
          count: addLeadingZero(statusCount[4]?.count || 0),
          background: "#7DD1F3",
          value: "Approved",
        },
        {
          label: "Booked",
          // count: addLeadingZero(statusCount[5]?.count || 0),
          count: addLeadingZero(statusCount[5]?.count || 0),
          background: "#8889F4",
          value: "Booked",
        },
        {
          label: "Awaiting payment",
          count: addLeadingZero(statusCount[6]?.count || 0),
          background: "#FFB693",
          value: "Awaiting payment",
        },
      ];

      setFilterOptions(filterOptions);
    }
  }, [fetchStatus.data]);
  return (
    <div className="maintenace_log">
      <div className="maintenance_content_wrapper">
        <div className="header">
          <img src={logo}></img>
          <h1>Maintenance</h1>
        </div>
        <div className="maintenace_log_wrapper">
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              marginBottom: "20px",
              // padding: "0 24px",
            }}
          >
            <div className="search_bar_maintain">
              <Search
                setsearch={setSearch}
                style={{ width: "315px" }}
                setPageIndex={setPageIndex}
                placeholder={"Maintance"}
              />
            </div>
            <button
              style={{
                border: "none",
                padding: "8px 20px",
                borderRadius: "5px",
                background: "rgba(129, 126, 255, 1)",
                color: "white",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                cursor: "pointer",
                gap: "5px",
                flexWrap: "nowrap",
                marginLeft: "20px",
              }}
            >
              Generate Report
              <img src={arrow} alt="arrow" width={"12px"} />
            </button>
          </div>

          <div className="filterbuttonmaintain">
            <div className="maintainfilterDiv">
              {filtersOptions?.map((filter, index) => (
                <span
                  key={index}
                  className={`mainSpan ${
                    filterStatus === filter.value && "active"
                  }`}
                  onClick={() => {
                    dispatch(setMaintenanceFilter(filter.value));
                  }}
                >
                  {filter.label}
                  <span
                    className="subSpan"
                    style={{
                      background: filter.background,
                      // padding: "5px 5px",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      borderRadius: "3px",
                    }}
                  >
                    {filter.count}
                  </span>
                </span>
              ))}
            </div>
          </div>

          <div className="main_content">
            {fetchMaintenanceData.isFetching ? (
              <div className="skelitonDiv">
                <Skeleton active style={{ height: "98px" }} rows={1} />
              </div>
            ) : (
              <TableComponent
                data={tableData}
                columns={columns}
                count={count}
                pageindex={pageindex}
                pagesize={pagesize}
                setPageIndex={setPageIndex}
                setPageSize={setPageSize}
              />
            )}
          </div>
        </div>
      </div>
      {fetchProperties.data?.data && popup && (
        <Propertydetails
          rowvalue={fetchProperties.data?.data}
          setpopup={setpopup}
          selectedData={"selectedData"}
          fromContact={true}
          setSelectedLandLordId={() => {}}
          propertyPopup={popup}
        />
      )}
    </div>
  );
};

export default MaintenanceLog;
