import { createSlice } from "@reduxjs/toolkit";
const initialState = {
  loader: "false",
  loginuserdata: [],
  sidebar: 1,
  token: "",
};
export const complianceSlice = createSlice({
  name: "complianceSlice",
  initialState,
  reducers: {
    sidebar: (state, action) => {
      state.sidebar = action.payload;
    },
    token: (state, action) => {
      state.token = action.payload;
    },
    loader: (state, action) => {
      state.loaderstate = action.payload;
    },
    loginuserdata: (state, action) => {
      state.loginuserdata = action.payload;
    },
  },
});

export const { loader, loginuserdata, sidebar, token,propertycounters } =
  complianceSlice.actions;

export default complianceSlice.reducer;
