import { useEffect, useState } from "react";
import "../../StyleSheet/compliance/compliancelist.scss";
import { useFetchCompliance } from "../../../Api/PropertiesQuery";
import dummy1 from "../../../Assets/dummy/dummy1.svg";
import { useSelector } from "react-redux";
export default function ComplianceList(props) {
  const {
    setFirstLoad,
    firstLoad,
    setComplianceList,
    complianceList,
    setsearch,
    setPageSize,
    setPageIndex,
  } = props;
  const [listActive, setListActive] = useState("0");
  const [payload, setPayload] = useState([]);
  const fetchCompliance = useFetchCompliance({
    params: {
      page: 1,
      page_size: 1000,
      type:
        complianceList.complianceType === "property" ? "Property" : "Tenancy",
    },
  });
  useEffect(() => {
    if (fetchCompliance.data?.data?.results) {
      setPayload(fetchCompliance.data.data.results);
      if (firstLoad) {
        setListActive(fetchCompliance.data.data.results?.[0]?.id);
        setComplianceList((prev) => ({
          ...prev,
          complianceSelected: fetchCompliance.data.data.results?.[0]?.id,
          selectedPayload: fetchCompliance.data.data.results?.[0],
          selectedIcon: fetchCompliance.data.data.results?.[0]?.icon,
        }));
        setListActive(fetchCompliance.data.data.results?.[0]?.id);
        setFirstLoad(false);
        console.log(
          fetchCompliance.data.data.results,
          "aksjfkjalsjdfljkasdjfasjf"
        );
      }
    }
  }, [fetchCompliance.data]);

  useEffect(() => {
    if (fetchCompliance.data?.data?.results) {
      console.log(fetchCompliance.data.data.results, "LKAJSDLFJASJDLJLAJ");
      setComplianceList((prev) => ({
        ...prev,
        complianceSelected: fetchCompliance.data.data.results?.[0]?.id,
        selectedPayload: fetchCompliance.data.data.results?.[0],
        selectedIcon: fetchCompliance.data.data.results?.[0]?.icon,
      }));
      setListActive(fetchCompliance.data.data.results?.[0]?.id);
    }
  }, [fetchCompliance.data, complianceList.complianceType]);

  const loading = useSelector((state) => state.counter.loader);

  if (loading) {
    return null; // Or some loading component
  }

  return (
    <div className="compliance_List">
      <div className="toggle_header">
        <p
          onClick={(e) => {
            setComplianceList((prev) => ({
              ...prev,
              complianceType: "property",
            }));
            setsearch("");
          }}
          className={
            complianceList.complianceType === "property" ? "active" : ""
          }
        >
          Property
        </p>
        <p
          onClick={(e) => {
            setComplianceList((prev) => ({
              ...prev,
              complianceType: "tenancy",
            }));
            setsearch("");
          }}
          className={
            complianceList.complianceType === "tenancy" ? "active" : ""
          }
        >
          Tenancy
        </p>
      </div>
      <div className="content_wrapper">
        <ul>
          {payload.map((ele, index) => {
            return (
              <li
                className={listActive == ele.id ? "active" : "inactive"}
                onClick={(e) => {
                  setComplianceList((prev) => ({
                    ...prev,
                    complianceSelected: ele.id,
                    selectedPayload: ele,
                    selectedIcon: ele.icon,
                  }));
                  setListActive(ele.id);
                  setsearch("");
                  setPageSize(10);
                  setPageIndex(1);
                }}
                key={index}
              >
                <span
                  style={{
                    display: "flex",
                    justifyContent: "flex-start",
                    alignItems: "center",
                    gap: "20px",
                  }}
                >
                  {" "}
                  {ele?.icon ? (
                    <div className="complainListIcons">
                      <img src={ele?.icon}></img>
                    </div>
                  ) : (
                    <img src={dummy1} />
                  )}
                  {ele.document_type}
                </span>
                <p>{ele.name}</p>
              </li>
            );
          })}
        </ul>
      </div>
    </div>
  );
}
