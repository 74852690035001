import React, { useEffect, useState } from "react";
import "../Signup/passwordUpdate.scss";
import { useNavigate, useParams } from "react-router-dom";
import { Toaster, toast } from "sonner";
import { useMutation } from "@tanstack/react-query";
import blTopLeft from "../Assets/bl_TopLeft.svg";
import bl_BottomRight from "../Assets/bl_BottomRight.svg";
import solace_Logo from "../Assets/solace_Logo.svg";
import success_check from "../Assets/success_check.svg";
import { PasswordInput } from "../../GlobalComponents/othercomponents/PasswordInput";
import { CustomButton } from "../CustomButton/CustomButton";
import invalidLink from "../../../Assets/Common/invalidLink.svg";

import { mutateData, validatePassword } from "../../../Utils/Utils";
// import { useStore } from "../../../redux/store";
import axios from "axios";
import { baseURL } from "../../../Api/useResponseHandler";
import { useLinkValidation } from "../../../Api/homeQuery";
import DefaultLoader from "../DefaultLoader";
// import { baseURL } from "../../Context/useResponseHandler";

function PasswordUpdatePortal() {
  const { id } = useParams();
  // const { token } = useStore((state) => ({
  //   token: state.token,
  // }));
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [accountSuccess, setAccountSuccess] = useState(false);
  const [validateUrl, setValidateUrl] = useState(false);
  const [firstTime, setFirstTime] = useState(true);
  const navigate = useNavigate();
  const handlePasswordChange = (e) => {
    setPassword(e.target.value);
  };
  const handleConfirmPasswordChange = (e) => {
    setConfirmPassword(e.target.value);
  };
  const handleCreateInvite = useMutation({
    mutationFn: async () =>
      axios.put(`${baseURL}manager/c_password_update/${id}`, {
        password,
        is_active: true,
      }),
    onSuccess: (data) => {
      toast.success("Account has been created successfully.");
      setAccountSuccess(true);
    },
    onError: (error) => {
      console.log(error, "error login");
      // toast.error('Error occurred while processing.(ADMPOP)');
    },
  });

  // Validate the password inputs and create the new SuperAdmin account
  const validate = () => {
    if (password === "" || confirmPassword === "") {
      toast.error("Password fields cannot be empty.");
      return;
    }

    if (password !== confirmPassword) {
      toast.error("Passwords do not match.");
      return;
    }

    const response = validatePassword(confirmPassword);
    if (response === "Password is valid.") {
      console.log("Response:", response);
      handleCreateInvite.mutate();
    } else {
      toast.error(response);
    }
  };

  const validateLink = useLinkValidation({
    params: { type: "client", id: id },
  });

  const [isValidating, setIsValidating] = useState(true);

  useEffect(() => {
    if (validateLink.isLoading) {
      setIsValidating(true);
    } else {
      setIsValidating(false);
      if (
        validateLink?.error &&
        validateLink.error.includes("400") &&
        firstTime
      ) {
        setValidateUrl(true);
        setFirstTime(false);
      }
    }
  }, [validateLink, firstTime]);

  if (isValidating) {
    return (
      <div className="passwordCreate">
        <img className="topLeft" src={blTopLeft} alt="blTopLeft" />
        <img className="bottomLeft" src={bl_BottomRight} alt="bl_BottomRight" />
        <div className="skeleton-container">
          <div className="skeleton-logo skeleton-animate"></div>
          <div className="skeleton-text skeleton-animate"></div>
          <div className="skeleton-text skeleton-animate"></div>
          <div className="skeleton-input skeleton-animate"></div>
          <div className="skeleton-input skeleton-animate"></div>
          <div className="skeleton-button skeleton-animate"></div>
        </div>
      </div>
    );
  }

  return (
    <>
      {handleCreateInvite?.isPending && <DefaultLoader />}
      <Toaster richColors />
      {!validateUrl ? (
        <div className="passwordCreate">
          <img className="topLeft" src={blTopLeft} alt="blTopLeft" />
          <img
            className="bottomLeft"
            src={bl_BottomRight}
            alt="bl_BottomRight"
          />
          <div className="innerPasswordCreate">
            <div className="topSection">
              <img src={solace_Logo} alt="solace_Logo" />
            </div>
            {!accountSuccess && (
              <div className="textSection">
                <p style={{ fontSize: "24px", fontWeight: "500" }}>
                  Password Update Portal
                </p>
                <p style={{ fontSize: "16px", fontWeight: "400" }}>
                  Please enter your new password to update your account
                  credentials.
                </p>
              </div>
            )}
            {accountSuccess && (
              <div className="successSection">
                <img src={success_check} alt="success_check" />
                <p style={{ textAlign: "center" }}>
                  <strong>
                    Your Account credentials has been updated Successfully
                  </strong>
                </p>
              </div>
            )}

            <div className="mainPasswordSection">
              {accountSuccess ? (
                // <p
                //   style={{ color: "black", fontSize: "16px", lineHeight: "35px" }}
                // >
                //   Lorem Ipsum has been the industry's standard dummy text ever
                //   since the 1500s, when an unknown printer took a galley of type
                //   and scrambled
                // </p>
                ""
              ) : (
                <>
                  <div
                    style={{
                      backgroundColor: "white",
                      flexDirection: "column",
                      alignItems: "flex-start",
                      gap: "10px",
                    }}
                  >
                    <label style={{ fontSize: "20px", fontWeight: "400" }}>
                      Password
                    </label>
                    <PasswordInput
                      value={password}
                      onChange={handlePasswordChange}
                      placeholder="Password"
                      name="password"
                      width="100%"
                    />
                  </div>
                  <div
                    style={{
                      backgroundColor: "white",
                      flexDirection: "column",
                      alignItems: "flex-start",
                      gap: "10px",
                      marginTop: "25px",
                    }}
                  >
                    <label style={{ fontSize: "20px", fontWeight: "400" }}>
                      Confirm Password
                    </label>

                    <PasswordInput
                      value={confirmPassword}
                      onChange={handleConfirmPasswordChange}
                      placeholder="Confirm Password"
                      name="confPassword"
                      width="100%"
                    />
                  </div>
                </>
              )}
            </div>
            <div className="footerSection">
              {accountSuccess ? (
                <CustomButton
                  title="Login"
                  handleClick={() => navigate("/login")}
                  active
                  // disabeled={handleCreateInvite.isPending}
                />
              ) : (
                <CustomButton
                  title="Submit"
                  handleClick={() => {
                    validate();
                  }}
                  active
                />
              )}
            </div>
          </div>
        </div>
      ) : (
        <div className="passwordCreate">
          <img className="topLeft" src={blTopLeft} alt="blTopLeft" />
          <img
            className="bottomLeft"
            src={bl_BottomRight}
            alt="bl_BottomRight"
          />
          <div className="innerPasswordCreate" style={{ minHeight: "200px" }}>
            <div className="topSection">
              <img src={solace_Logo} alt="solace_Logo" />
            </div>

            <div className="successSection">
              <img src={invalidLink} alt="success_check" />
              <p style={{ textAlign: "center" }}>
                <strong style={{ textAlign: "center" }}>Link Expired</strong>
              </p>
            </div>

            <div className="mainPasswordSection">
              <p
                style={{
                  color: "black",
                  fontSize: "16px",
                  lineHeight: "35px",
                }}
              >
                Your link has expired. If you encounter any issues, please
                contact the respective property manager for further assistance.{" "}
              </p>
            </div>
          </div>
        </div>
      )}
    </>
  );
}

export default PasswordUpdatePortal;
