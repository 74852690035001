import React, { useState } from "react";
import "./style/filterpanel.scss";

const FilterPanel = (props) => {
  const { tabs, activeTab, setActiveTab } = props;

  const handleTabClick = (tab) => {
    setActiveTab(tab);
    // callBackFunction()
  };

  return (
    <div
      className="tab-container"
      style={{ caretShape: "unset", caretColor: "transparent", width: "100%" }}
    >
      {tabs.map((tab) => (
        <div
          key={tab}
          className={`tab ${activeTab === tab ? "active" : ""}`}
          onClick={() => handleTabClick(tab)}
          style={{ caretShape: "unset", caretColor: "transparent" }}
        >
          <p style={{ caretShape: "unset", caretColor: "transparent" }}>
            {tab}
          </p>
        </div>
      ))}
    </div>
  );
};

export default FilterPanel;
