import password_eye from "../../../Assets/Common/password_eye.svg";
import hide_password_eye from "../../../Assets/Common/eye-password-hide-svgrepo-com.svg";
import React, { useState } from "react";
import "./PasswordInput.scss";

const PasswordInput = ({ value, onChange, placeholder, name, width }) => {
  const [isPasswordVisible, setPasswordVisible] = useState(false);

  const togglePasswordVisibility = () => {
    setPasswordVisible((prev) => !prev);
  };

  return (
    <div
      className="passwordInput"
      style={{
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        width: width ? width : "90%",
        background: "rgba(217, 217, 217, 0.4)",
        // height: "max(43px, 4vw)",
        border: "none",
        borderRadius: "8px",
      }}
    >
      <input
        // className="customPasswordButton"
        type={isPasswordVisible ? "text" : "password"}
        value={value}
        onChange={(e) => {
          const newValue = e.target.value.replace(/\s/g, "");
          onChange({ ...e, target: { ...e.target, value: newValue } });
        }}
        placeholder={placeholder}
        name={name}
        style={{
          background: "#F0F0F0",
          border: "none",
          height: "100%",
        }}
        autoComplete="off"
        onKeyDown={(e) => {
          if (e.key === " ") {
            e.preventDefault();
          }
        }}
      />
      <img
        src={isPasswordVisible ? password_eye : hide_password_eye}
        alt="password_eye"
        onClick={togglePasswordVisibility}
        style={{
          cursor: "pointer",
          width: "20px",
          height: "max(16px, 1.4vw)",
          marginRight: "10px",
        }}
      />
    </div>
  );
};

export { PasswordInput };
