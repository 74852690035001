import React from "react";
import solace from "../../Assets/Common/solace_short.svg";
import styled from "styled-components";

const LoadingScreen = () => {
  const LoadingClass = styled.div`
    @keyframes rotation {
      from {
        transform: rotate(0deg) scale(1);
      }
      to {
        transform: rotate(180deg) scale(1);
      }
    }

    img {
      animation: rotation 0.8s infinite ease-in-out;
    }
  `;
  return (
    <LoadingClass
      style={{
        width: "100%",
        height: "100%",
        background: "rgb(247,249,251, 1)",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        zIndex: "10000000000",
        position: "absolute",
        top: "0",
        right: "0",
        opacity: "0.5",
        transition: "opacity 5s ease, width 0.5s ease",
      }}
    >
      <img
        src={solace}
        alt="solace"
        style={{
          width: "70px",
        }}
      />
    </LoadingClass>
  );
};

export default LoadingScreen;
