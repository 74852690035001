import "../../StyleSheet/dashboard/home.scss";
import ComplianceHome from "./compliance";
import ArrearsHome from "./arrears";
import PaymentsHome from "./payments";
import CurrentOccpancy from "./currentoccupancy";
import MaintanenceDashboard from "./Maintanence";
import { useSelector } from "react-redux";
import PeriodicInspection from "./periodicInspection";

export default function Home() {
  const loading = useSelector((state) => state.counter.loader);

  if (loading) {
    return null; // Or some loading component
  }

  return (
    <div className="home_wrapper">
      <div className="home_inner_wrapper">
        <div className="complains-tab">
          <ComplianceHome />
        </div>

        <div className="arrearRecept">
          <div className="arrearHome">
            <ArrearsHome />
          </div>
          <div className="payHome">
            <PaymentsHome />
          </div>
        </div>
      </div>

      <div className="home-below">
        <div className="periodHome">
          <PeriodicInspection />
        </div>
        <div className="currentHome">
          <CurrentOccpancy />
        </div>
        <div className="mainDashDown">
          <MaintanenceDashboard />
        </div>
      </div>
    </div>
  );
}
