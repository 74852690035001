import React, { useEffect, useState } from "react";
import logo from "./Assests/logo.svg";
import info_initial from "../../../Assets/Common/question_mark.svg";
import Search from "../../../MainComponent/fields/search";
import { CustomButton } from "../../GlobalComponents/CustomButton/CustomButton";
import PropertyFiles from "./PropertyFiles";
import "./Styles/InitialProperty.scss";
import { useDebounce } from "use-debounce";
import { useDispatch, useSelector } from "react-redux";
import { setPropertyFileFilter } from "../../../redux/reducer/counterslice";
import { useNavigate } from "react-router-dom";
import InfoQuestionMark from "./InfoQuestionMark";

const PropertyFolder = () => {
  const [selectedButton, setSelectedButton] = useState("Property");
  const [search, setSearch] = useState("");
  const [searchValue] = useDebounce(search, 600);
  const dispatch = useDispatch();
  const [pageIndex, setPageIndex] = useState(1);
  const navigate = useNavigate();
  const [popupState, setPopupState] = useState(false);

  const propertyFileFilters = useSelector(
    (state) => state.counter.propertyFileFilter
  );

  return (
    <>
      <div className="card-contents" style={{ position: "relative" }}>
        <div className="card-logo">
          <img src={logo} alt="logo" />
          <img
            src={info_initial}
            alt="logo"
            style={{
              width: "22px",
              position: "absolute",
              right: "0",
              top: "4px",
              cursor: "pointer",
            }}
            onClick={() => setPopupState(true)}
          />
        </div>
        <div className="init-card-top">
          <div className="top-button">
            {["Property", "Shared"].map((item, index) => (
              <div Key={index}>
                <CustomButton
                  title={item}
                  active={item === propertyFileFilters}
                  type="submit"
                  handleClick={() => {
                    setSelectedButton(item);
                    dispatch(setPropertyFileFilter(item));
                  }}
                  styles={{
                    padding: "9px 35px",
                    fontSize: "15.2px",
                    borderRadius: "10px",
                    color: "black",
                  }}
                />
              </div>
            ))}
          </div>
          <div className="card-search">
            <Search
              setsearch={setSearch}
              setPageIndex={setPageIndex}
              style={{ width: "100%" }}
            />
          </div>
        </div>
        <div className="card-body-content">
          <div className="property-content">
            <PropertyFiles filter={selectedButton} searchString={searchValue} />
          </div>
        </div>
      </div>
      <InfoQuestionMark />
      {popupState && (
        <>
          <InfoQuestionMark
            // setActiveTab={setActiveTab}
            // activeTab={activeTab}
            setPopupState={setPopupState}
            popuptext={""}
            // dataFromData={Data}
            popupState={popupState}
          />
        </>
      )}
    </>
  );
};

export default PropertyFolder;
