import {
  keepPreviousData,
  useMutation,
  useQuery,
  useQueryClient,
} from "@tanstack/react-query";
// import { toast } from "sonner";
import { useNavigate } from "react-router-dom";
import { useResponseHandler } from "./useResponseHandler";
import { toast } from "sonner";
import { useDispatch } from "react-redux";
import { loginuserdata, token } from "../redux/reducer/counterslice";
import axios from "axios";

export function useFetchOnboardingFolder(payload) {
  const { handleResponse } = useResponseHandler();
  return useQuery({
    queryKey: ["getReleaseFolder", payload],
    queryFn: async () => {
      const uploadPayload = {
        params: payload,
      };
      const response = handleResponse(
        "property/Get_releasefolder/v1/",
        "get",
        uploadPayload,
        false
      );
      return response;
    },
    placeholderData: keepPreviousData,
  });
}
export function useFetchOnboardingFiles(payload) {
  const { handleResponse } = useResponseHandler();
  return useQuery({
    queryKey: ["getOnboardingFiles", payload],
    queryFn: async () => {
      const uploadPayload = {
        params: payload,
      };
      const response = handleResponse(
        "property/propertyfile/v1/",
        "get",
        uploadPayload,
        false
      );
      return response;
    },
    placeholderData: keepPreviousData,
  });
}
export function UseDeletePropertyDoc(successFunction) {
  const { handleResponse } = useResponseHandler();
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async (payload) => {
      return handleResponse(
        `property/propertyfile/v1/${payload.data}/`,
        "delete"
      );
    },
    onSuccess: (payload) => {
      console.log(payload, "lkjasdfjjladsjlasf");
      toast.success(`Document deleted successful.`);
      successFunction();
    },
    onError: (error) => {
      console.log(error, "error while updating progression");
      toast.error("Error while updating progression.");
    },
    onSettled: async (_, error) => {
      if (error) {
        console.log(error);
      } else {
        await queryClient.invalidateQueries({
          queryKey: ["getOnboardingFiles"],
        });
      }
    },
  });
}

export function useComplianceUpdateTitle(payload) {
  const { handleResponse } = useResponseHandler();
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async () => {
      const uploadPayload = {
        data: payload.data,
        params: payload.params,
      };
      console.log(payload, "lkajsldfjksdaiflj");
      return handleResponse(`property/document_edit/`, "put", uploadPayload);
    },
    onSuccess: () => {
      toast.success(`Folder Updated successful.`);
      payload.handelSuccess();
    },
    onError: (error) => {
      console.log(error, "error login");
      toast.error("Error occurred while updating folder.");
    },
    onSettled: async (_, error) => {
      if (error) {
        console.log(error);
      } else {
        await queryClient.invalidateQueries({
          queryKey: ["getReleaseFolder"],
        });
      }
    },
  });
}
