import {
  keepPreviousData,
  useMutation,
  useQuery,
  useQueryClient,
} from "@tanstack/react-query";
// import { toast } from "sonner";
import { useNavigate } from "react-router-dom";
import { useResponseHandler } from "./useResponseHandler";
import { toast } from "sonner";
import { useDispatch } from "react-redux";
import { loginuserdata, token } from "../redux/reducer/counterslice";
import axios from "axios";

// Reusable query function
function useFetchData(queryKey, url, payload) {
  const { handleResponse } = useResponseHandler();

  return useQuery({
    queryKey,
    queryFn: async () =>
      handleResponse(url + (payload ? `/${payload}` : ""), "get"),
    placeholderData: keepPreviousData,
  });
}

export function useFetchComplianceSpecId(payload) {
  const { handleResponse } = useResponseHandler();
  return useQuery({
    queryKey: ["compliance", payload],
    queryFn: async () => {
      // const uploadPayload = {
      //   payload,
      // };
      const uploadPayload = {
        params: payload,
      };
      const response = handleResponse(
        "property/document_status/v1/",
        "get",
        uploadPayload,
        payload.whenToLoad
      );
      return response;
    },
  });
}
export function useFetchPeriodicId(payload) {
  const { handleResponse } = useResponseHandler();
  return useQuery({
    queryKey: ["periodic", payload],
    queryFn: async () => {
      const uploadPayload = {
        params: payload,
      };
      const response = handleResponse(
        "property/periodic_inspection/v1/",
        "get",
        uploadPayload,
        false
      );
      return response;
    },
    placeholderData: keepPreviousData,
  });
}

export function useFetchComplianceStats(payload) {
  const { handleResponse } = useResponseHandler();
  return useQuery({
    queryKey: ["complianceStats", payload],
    queryFn: async () => {
      const uploadPayload = {
        params: { client: payload },
      };
      const response = handleResponse(
        "property/document_status_count/v1/",
        "get",
        uploadPayload
      );
      return response;
    },
    placeholderData: keepPreviousData,
  });
}
export function useFetchComplianceStatscount(payload) {
  const { handleResponse } = useResponseHandler();
  return useQuery({
    queryKey: ["complianceStatscount", payload.params.doc_id],
    queryFn: async () => {
      // const uploadPayload = {
      //   payload,
      // };
      const uploadPayload = {
        params: payload.params,
      };
      const response = handleResponse(
        "property/pdocument_status_count/v1/",
        "get",
        uploadPayload,
        false
      );
      return response;
    },
    placeholderData: keepPreviousData,
  });
}
