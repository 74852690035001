import { useInfiniteQuery } from "@tanstack/react-query";
import { useResponseHandler } from "./useResponseHandler";

function usePaginationMaster(queryKey, url, payload) {
  const { handleResponse } = useResponseHandler();

  return useInfiniteQuery({
    queryKey: [queryKey, payload.params],
    queryFn: async ({ pageParam = 1 }) => {
      if (
        payload.params.status &&
        payload.params.page &&
        payload.params.page_size
      ) {
        const uploadPayload = {
          params: {
            ...payload.params,
            page: pageParam,
          },
        };
        const response = await handleResponse(url, "get", uploadPayload, false);
        console.log(response, "laksfd;fsalkl;fdk;ka;sf");
        return response.data;
      }
    },
    getNextPageParam: (lastPage, pages) => {
      console.log(lastPage, pages, "Checking next page parameter...");
      const totalItems = lastPage?.count;
      const currentItemCount = pages?.reduce(
        (acc, page) => acc + page?.results?.length,
        0
      );
      console.log(currentItemCount, totalItems, "kajsl;jfaklsdjfal");

      if (currentItemCount && totalItems && currentItemCount < totalItems) {
        return pages.length + 1; // Increment the page number correctly
      } else {
        return undefined; // No more pages to fetch
      }
    },
    initialPageParam: 1,
    placeholderData: { pages: [], pageParams: [] },
  });
}

export function useFetchPropertiesPagination(payload) {
  return usePaginationMaster(
    "propertiesListPagination",
    "property/property/v1/",
    payload
  );
}
export function useFetchTenantsContacts(payload) {
  return usePaginationMaster(
    "managerTenantPagination",
    "manager/tenant/v1/",
    payload
  );
}
export function useFetchLandlordContactsPagination(payload) {
  return usePaginationMaster(
    "managerLandlordPagination",
    "manager/landlord/v1/",
    payload
  );
}
