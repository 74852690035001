import homelogo from "../../Assets/Sidebar/Home.png";
import propertylogo from "../../Assets/Sidebar/MyProperties.png";
import clientmoneylogo from "../../Assets/Sidebar/ClientMoney.png";
import compliancelogo from "../../Assets/Sidebar/Compliance.png";
import maintanencelogo from "../../Assets/Sidebar/Maintenance.png";
import insepctionlogo from "../../Assets/Sidebar/Inspections.png";

import homelogoactive from "../../Assets/Sidebar/active/Home_Active.png";
import propertylogoactive from "../../Assets/Sidebar/active/My Properties_Active.png";
import clientmoneylogoactive from "../../Assets/Sidebar/active/ClientMoney_Active.png";
import compliancelogoactive from "../../Assets/Sidebar/active/Compliance_Active.png";
import maintanencelogoactive from "../../Assets/Sidebar/active/Maintenance_Active.png";
import insepctionlogoactive from "../../Assets/Sidebar/active/Home_Active.png";
import Inspections from "../../Assets/Sidebar/active/Inspections.svg";
import contactNavIconSelected from "../../Assets/Common/contactNavIconSelected.svg";
import contactNavIconUnSelected from "../../Assets/Common/contactNavIconUnSelected.svg";

export const SideBarData = [
  {
    logo: homelogo,
    logoactive: homelogoactive,
    data: "Home",
    drop: false,
    href: "/",
  },
  {
    logo: contactNavIconUnSelected,
    logoactive: contactNavIconSelected,
    data: "Contacts",
    drop: false,
    href: "/contacts",
  },
  {
    logo: propertylogo,
    data: "My Properties",
    logoactive: propertylogoactive,
    drop: true,
    href: "/property/overview",
    children: [
      { data: "Overview", href: "/property/overview", match: "overview" },
      {
        data: "All Properties",
        href: "/property/allproperties",
        match: "allproperties",
      },
    ],
  },
  {
    logo: clientmoneylogo,
    logoactive: clientmoneylogoactive,
    data: "Client Money",
    drop: false,
    href: "/clientmoney",
  },
  {
    logo: compliancelogo,
    logoactive: compliancelogoactive,
    data: "Compliance",
    drop: false,
    href: "/compliance",
    // children: [{ data: "All Compliance" }, { data: "Info" }]
  },
  {
    logo: maintanencelogo,
    logoactive: maintanencelogoactive,
    data: "Maintenance",
    drop: true,
    href: "/maintenance/overview",
    children: [
      { data: "Overview", href: "/maintenance/overview", match: "overview" },
      { data: "Maintenance log", href: "/maintenance/log", match: "log" },
      {
        data: "Awaiting Approval",
        href: "/maintenance/approval",
        match: "approval",
      },
      { data: "Archive", href: "/maintenance/archive", match: "archive" },
    ],
  },
  {
    logo: insepctionlogo,
    logoactive: Inspections,
    data: "Inspections",
    drop: false,
    href: "/insepection",
  },
];
