import { useState, useEffect } from "react";
import "../../StyleSheet/dashboard/currentoccupancy.scss";
import { useDispatch, useSelector } from "react-redux";
import { useFetchCurrentOccupancyStatus } from "../../../Api/homeQuery";
import { addLeadingZero } from "../../../Utils/Utils";
import {
  setPropertyOccupancyPopup,
  setPropertyOccupancyPopupFilter,
} from "../../../redux/reducer/counterslice";
import { useNavigate } from "react-router-dom";

export default function CurrentOccpancy() {
  const [chartData, setChartData] = useState([0, 0, 0]);
  const [totalProperties, setTotalProperties] = useState(0);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const colors = ["#4DDE87", "#FFB200", "#FF3A29"];
  const text = [
    "Occupied",
    `Upcoming
     Vacancies`,
    "Vacant",
  ];
  const userId = useSelector((state) => state.counter.loginuserdata.id);
  const clientid = useSelector((state) => state.counter.clientid);
  const fetchComplianceStats = useFetchCurrentOccupancyStatus({
    params: { client: clientid },
  });
  useEffect(() => {
    if (fetchComplianceStats.data?.data) {
      console.log(fetchComplianceStats.data.data, "dsd");
      setChartData([
        fetchComplianceStats.data.data.occupied,
        fetchComplianceStats.data.data.vacant_in_progress,
        fetchComplianceStats.data.data.vacant,
      ]);
      setTotalProperties(
        fetchComplianceStats.data.data.occupied +
          fetchComplianceStats.data.data.vacant_in_progress +
          fetchComplianceStats.data.data.vacant
      );
    }
  }, [fetchComplianceStats.data]);
  const filters = ["Occupied", "Upcoming Vacancies", "Vacant"];

  return (
    <div className="currentoccupancy_wrapper_main">
      <h1 className="header">Current Occupancy</h1>
      <div className="currentoccupancy_inner_wrapper">
        {chartData.length > 0 &&
          chartData.map((item, index) => {
            // Calculate the percentage width based on the total number of properties
            const percentage = (item / totalProperties) * 100;

            return (
              <div
                className={`progress_main ${
                  chartData[index] !== 0 ? "animated" : "notAnimated"
                }`}
                key={index}
                style={{ padding: index === 1 ? "9px 0" : "20px 0" }}
                onClick={() => {
                  if (chartData[index] !== 0) {
                    navigate("/property/overview");
                    dispatch(setPropertyOccupancyPopup(true));
                    dispatch(setPropertyOccupancyPopupFilter(filters[index]));
                  }
                }}
              >
                <div className="main_text">
                  <span className="text" style={{ fontSize: "17px" }}>
                    {text[index % text.length]}
                  </span>
                </div>

                <div className="progress_bar" style={{ height: "14px" }}>
                  <span
                    style={{
                      color: colors[index % colors.length],
                      width: "25px",
                      marginRight: "10px",
                      fontSize: "clamp(10px, 0.9vw, 24px)",
                    }}
                  >
                    {addLeadingZero(item)}
                  </span>
                  <div style={{ width: "100%" }}>
                    <div
                      className="progress progress-sm w-100"
                      style={{
                        borderRadius: "3px",
                        background:
                          index === 0
                            ? "#DBF8E7"
                            : index === 1
                            ? "#FFF0CC"
                            : "rgb(255,58,41, 0.3)",
                        // height: "14px",
                        height: "max(10.7px, 1.5vh)",
                      }}
                    >
                      <div
                        className="progress-bar"
                        style={{
                          width: `${totalProperties === 0 ? 0 : percentage}%`,
                          borderRadius: "max(20px, 1.8vw)",
                          backgroundColor: colors[index % colors.length],
                          height: "max(10.7px, 1.5vh)",
                        }}
                        aria-valuenow={percentage}
                        aria-valuemin="0"
                        aria-valuemax="100"
                      ></div>
                    </div>
                  </div>
                </div>
              </div>
            );
          })}
      </div>
    </div>
  );
}
