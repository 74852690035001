import { useEffect, useState } from "react";
import "../../StyleSheet/dashboard/maintanencedashboard.scss";
import { useFetchWorkOrderNumber } from "../../../Api/homeQuery";
import { useSelector } from "react-redux";
import MaintenancePopup from "./popups/maintenancepopup";
import { addLeadingZero } from "../../../Utils/Utils";
import Chart from "react-apexcharts";

export default function MaintanenceDashboard() {
  const [Data, setData] = useState([
    { name: "New Request", count: "0" },
    { name: "Quote Requested", count: "0" },
    { name: "Awaiting Approval", count: "0" },
    { name: "Approved", count: "0" },
    { name: "Booked", count: "0" },
    { name: "Awaiting Payment", count: "0" },
  ]);
  const clientid = useSelector((state) => state.counter.clientid);
  const [maintenancePopup, setMaintenancePopup] = useState(false);
  const [titleColor, setTitleColor] = useState("");
  const [maintenanceCount, setMaintenanceCount] = useState(0);
  const [maintenanceStage, setMaintenanceStage] = useState("");
  const [type, setType] = useState("Maintenance");
  const [hoveredIndex, setHoveredIndex] = useState(null); // State to track the hovered index

  const fetchMaintenanceCount = useFetchWorkOrderNumber({
    params: {
      client: clientid,
      type: type === "Maintenance" ? "false" : "true",
    },
    loading: false,
  });

  const [pieHeight, setPieHeight] = useState("380px");
  const [pieWidth, setPieWidth] = useState("280px");

  const checkWindowWidth = () => {
    if (window.innerWidth > 3280) {
      setPieHeight("500px");
      setPieWidth("400px");
    } else if (window.innerWidth < 3280 && window.innerWidth > 3050) {
      setPieHeight("480px");
      setPieWidth("380px");
    } else if (window.innerWidth < 3050 && window.innerWidth > 2800) {
      setPieHeight("460px");
      setPieWidth("360px");
    } else if (window.innerWidth < 2800 && window.innerWidth > 2565) {
      setPieHeight("440px");
      setPieWidth("340px");
    } else if (window.innerWidth < 2565 && window.innerWidth > 2200) {
      setPieHeight("420px");
      setPieWidth("320px");
    } else if (window.innerWidth < 2200 && window.innerWidth > 1950) {
      setPieHeight("405px");
      setPieWidth("305px");
    } else if (window.innerWidth < 1950 && window.innerWidth > 1750) {
      setPieHeight("390px");
      setPieWidth("290px");
    } else if (window.innerWidth < 1750 && window.innerWidth > 1450) {
      setPieHeight("330px");
      setPieWidth("230px");
    } else if (window.innerWidth < 1450 && window.innerWidth > 1150) {
      setPieHeight("305px");
      setPieWidth("205px");
    } else if (window.innerWidth < 1150 && window.innerWidth > 1015) {
      setPieHeight("280px");
      setPieWidth("180px");
    } else {
      setPieHeight("380px");
      setPieWidth("280px");
    }
  };

  useEffect(() => {
    checkWindowWidth();
    window.addEventListener("resize", checkWindowWidth); // Add event listener on resize
    return () => {
      window.removeEventListener("resize", checkWindowWidth);
    };
  }, []);

  useEffect(() => {
    setMaintenanceCount(
      fetchMaintenanceCount?.data?.data?.[0]?.count +
        fetchMaintenanceCount?.data?.data?.[1]?.count +
        fetchMaintenanceCount?.data?.data?.[2]?.count +
        fetchMaintenanceCount?.data?.data?.[3]?.count +
        fetchMaintenanceCount?.data?.data?.[4]?.count +
        fetchMaintenanceCount?.data?.data?.[5]?.count +
        fetchMaintenanceCount?.data?.data?.[6]?.count
    );
    const createDataItem = (name, index, color) => ({
      name,
      count: fetchMaintenanceCount?.data?.data?.[index]?.count,
      color: `rgba(${color}, 1)`,
      hoverColor: `rgba(${color}, 0.5)`,
    });

    const toggleData =
      type === "Compliance"
        ? [
            createDataItem("Quote Requested", 2, "255, 198, 80"),
            createDataItem("Approved", 4, "82, 194, 239"),
            createDataItem("Booked", 5, "96, 98, 240"),
            createDataItem("Awaiting payment", 6, "255, 158, 111"),
          ]
        : [
            createDataItem("New Requests", 0, "117, 226, 145"),
            createDataItem("Quote Requested", 2, "255, 198, 80"),
            createDataItem("Awaiting Approval", 3, "255, 91, 92"),
            createDataItem("Approved", 4, "82, 194, 239"),
            createDataItem("Booked", 5, "96, 98, 240"),
            createDataItem("Awaiting payment", 6, "255, 158, 111"),
          ];
    setData(toggleData);
  }, [fetchMaintenanceCount?.data, type]);

  // const series = [
  //   Math.round(
  //     ((fetchMaintenanceCount?.data?.data?.[0]?.count +
  //       fetchMaintenanceCount?.data?.data?.[1]?.count) /
  //       maintenanceCount) *
  //       100
  //   ),
  //   Math.round(
  //     (fetchMaintenanceCount?.data?.data?.[2]?.count / maintenanceCount) * 100
  //   ),
  //   Math.round(
  //     (fetchMaintenanceCount?.data?.data?.[3]?.count / maintenanceCount) * 100
  //   ),
  //   Math.round(
  //     (fetchMaintenanceCount?.data?.data?.[4]?.count / maintenanceCount) * 100
  //   ),
  //   Math.round(
  //     (fetchMaintenanceCount?.data?.data?.[5]?.count / maintenanceCount) * 100
  //   ),
  //   Math.round(
  //     (fetchMaintenanceCount?.data?.data?.[6]?.count / maintenanceCount) * 100
  //   ),
  // ];

  const calculatePercentage = (index) =>
    Math.round(
      (fetchMaintenanceCount?.data?.data?.[index]?.count / maintenanceCount) *
        100
    );

  const complianceSeries = [2, 4, 5, 6].map(calculatePercentage);
  const series = [0, 2, 3, 4, 5, 6].map(calculatePercentage);
  const options = {
    chart: {
      type: "radialBar",
    },
    colors: Data.map((item, index) =>
      index === hoveredIndex ? item.hoverColor : item.color
    ),
    plotOptions: {
      radialBar: {
        hollow: {
          margin: 5,
          size: "17%",
        },
        track: {
          show: true,
          background: "#D8D8D8",
          strokeWidth: "100%",
          opacity: 1,
          margin: 6, // Gap between bars
        },
        dataLabels: {
          show: false,
          total: {
            show: hoveredIndex !== null,
            label: "",
            formatter: () => {
              if (hoveredIndex !== null) {
                return <p>{Data[hoveredIndex]?.count}</p>;
                // const value =
                //   (Data[hoveredIndex]?.count / maintenanceCount) * 100;
                // return `${Math.trunc(value)}%`;
              }
              return "";
            },
            color: hoveredIndex !== null ? Data[hoveredIndex].color : "#000",
          },
        },
      },
    },
    labels: [
      "New Requests",
      "Quote Requested",
      "Awaiting Approval",
      "Approved",
      "Booked",
      "Awaiting Payment",
    ],
  };
  return (
    <>
      <div
        className="MaintanenceDash_wrapper_main"
        style={{ position: "relative" }}
      >
        <div className="arrear_header">
          <h1
            className={type === "Maintenance" && "active"}
            onClick={() => {
              setType("Maintenance");
            }}
            style={{ borderRadius: "10px 0px 0 0" }}
          >
            Maintenance
          </h1>
          <h1
            className={type === "Compliance" && "active"}
            onClick={() => {
              setType("Compliance");
            }}
            style={{ borderRadius: "0px 10px 0 0" }}
          >
            Compliance
          </h1>
        </div>
        <div
          className="MaintanenceDash_graph_wrapper"
          style={{ justifyContent: "flex-end" }}
        >
          <div className="">
            <div
              className="graph"
              style={{ position: "absolute", top: "28%", left: "-17px" }}
            >
              <Chart
                options={options}
                series={type === "Compliance" ? complianceSeries : series}
                type="radialBar"
                height={pieHeight}
                width={pieWidth}
              />
            </div>
          </div>

          <ul>
            {Data.map((item, index) => {
              return (
                <li
                  style={{
                    marginTop: index === 0 ? "16px" : "",
                  }}
                  className={item.count > 0 ? "animated" : "notAnimated"}
                  key={index}
                  onMouseEnter={() => setHoveredIndex(index)} // Set the hovered index on mouse enter
                  onMouseLeave={() => setHoveredIndex(null)} // Reset the hovered index on mouse leave
                  onClick={() => {
                    if (item.count > 0) {
                      if (item.name !== "") {
                        setMaintenancePopup(true);
                        setMaintenanceStage(item.name);
                        setTitleColor(item.color);
                      }
                    }
                  }}
                >
                  <span>
                    <div className={`badge${index + 1}`}></div>
                    {item.name}
                  </span>
                  <p>{addLeadingZero(item.count)}</p>
                </li>
              );
            })}
          </ul>
        </div>
      </div>
      {/* {maintenancePopup && ( */}
      <MaintenancePopup
        setPopup={setMaintenancePopup}
        maintenanceStage={maintenanceStage}
        titleColor={titleColor}
        popupstate={maintenancePopup}
        type={type}
      />
      {/* )} */}
    </>
  );
}
