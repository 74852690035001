import notificationbell from "../../Assets/Sidebar/Bell.png";
import Paymentsreceivedlogo from "../../Assets/Common/hj.svg";
import Paymentsduelogo from "../../Assets/Common/Payments Due.svg";
import ReserveFundslogo from "../../Assets/Common/Reserve Fund.svg";
import AwaitingApprovallogo from "../../Assets/Common/Awaiting Approval.svg";
export const notificationbellactive = notificationbell;
export const payments = [
  Paymentsreceivedlogo,
  Paymentsduelogo,
  ReserveFundslogo,
  AwaitingApprovallogo,
];
