import { useEffect, useState } from "react";
import "../../StyleSheet/clientmoney/clientmoneyoverview.scss";
import ReactEcharts from "echarts-for-react";
import arrowraise from "../../../Assets/Client/clientmoney/ArrowRise-s.svg";
import DateRange from "../../../MainComponent/fields/daterangepicker";
import ClientMoneyOverviewPopup from "./popups/clientmoneyoverviewpopup";
import { useDispatch, useSelector } from "react-redux";
import { useArrears } from "../../../Api/homeQuery";
import {
  useTodaysRentAgency,
  useBussinessStatus,
} from "../../../Api/clientmonetQuery";
import { formatPounds } from "../../../Utils/Utils";
import {
  setClientMoneyAgencyType,
  setClientMoneyPeriod,
  setClientRentPopup,
  setClientRentPopupData,
} from "../../../redux/reducer/counterslice";
import ArrearsPopup from "../Dashboard/popups/arrearspopup";
import FilterPanel from "../../../MainComponent/FilterPanel";
import dayjs from "dayjs";

export default function ClientMoneyOverview() {
  const [toggle, setToggle] = useState(true);
  // const [popup, setPopup] = useState(false);
  const popup = useSelector((state) => state.counter.clientRentPopup);
  const keydata = useSelector((state) => state.counter.clientRentPopupData);
  const period = useSelector((state) => state.counter.clientMoneyPeriod);
  const type = useSelector((state) => state.counter.clientMoneyAgencyType);
  const [activeTab, setActiveTab] = useState("0 - 7 Days");
  const [popupstate, setPopupstate] = useState(false);

  const dispatch = useDispatch();
  const [todaysrent, setTodaysrent] = useState(0);
  const [todaysagencyincome, settodaysagencyincome] = useState(0);
  const [Data, setData] = useState([]);
  const clientid = useSelector((state) => state.counter.clientid);
  console.log(type, "ljasl;dfojelkjrlsejirlsjifdlsjdif");
  const [chartWidth, setChartWidth] = useState("100vw");
  const [linegraphdata, setlinegraphdata] = useState([]);
  function convert(str) {
    var date = new Date(str),
      mnth = ("0" + (date.getMonth() + 1)).slice(-2),
      day = ("0" + date.getDate()).slice(-2);
    return [date.getFullYear(), mnth, day].join("-");
  }
  const [range, setRange] = useState([
    dayjs().format("YYYY-MM-DD"),
    dayjs().format("YYYY-MM-DD"),
  ]);
  const getAgencyrent = useTodaysRentAgency({
    params: {
      client: clientid,
      type: "Rent",
      from_date: range?.[0] || "",
      to_date: range?.[1] || "",
    },
  });
  const getAgencyManagementFee = useTodaysRentAgency({
    params: {
      client: clientid,
      type: "Management Fee",
      from_date: range?.[0] || "",
      to_date: range?.[1] || "",
    },
  });
  const getarrears = useArrears({
    params: { id: clientid },
    loading: false,
  });

  const getbussinessstatus = useBussinessStatus({
    params: {
      id: clientid,
      period: period,
      type: type,
    },
  });

  useEffect(() => {
    if (getAgencyrent.data) {
      var totaltodaysrent = 0;
      getAgencyrent.data.data?.data.map((ele) => {
        totaltodaysrent += ele.amount;
      });
      setTodaysrent(totaltodaysrent);
    }
  }, [getAgencyrent.data]);

  useEffect(() => {
    if (getAgencyManagementFee.data) {
      var totaltodaysagi = 0;
      getAgencyManagementFee.data.data.data.map((ele) => {
        totaltodaysagi += ele.amount;
      });
      settodaysagencyincome(totaltodaysagi);
    }
  }, [getAgencyManagementFee.data]);

  useEffect(() => {
    if (getarrears.data != undefined) {
      setData(getarrears.data.data[0]);
    }
  }, [getarrears.data]);

  const option = {
    series: [
      {
        name: "Arrears",
        type: "pie",
        radius: ["48%", "90%"],
        avoidLabelOverlap: false,
        itemStyle: {
          borderRadius: 8,
          borderColor: "#fff",
          borderWidth: 3,
        },
        label: {
          show: false,
          position: "center",
        },
        emphasis: {
          label: {
            show: false,
            fontSize: 10,
          },
        },
        labelLine: {
          show: false,
        },
        data: [
          {
            value: Data?.charges_0_7_days || "",
            name: "0-7 Days",
            itemStyle: { color: "#000047" },
            type: "0_7_days",
          },
          {
            value: Data?.charges_8_14_days || "",
            name: "8-14 Days",
            itemStyle: { color: "#4DDE87" },
            type: "8_14_days",
          },
          {
            value: Data?.charges_15_30_days || "",
            name: "15-30 Days",
            itemStyle: { color: "#4FC3F4" },
            type: "15_30_days",
          },
          {
            value: Data?.charges_31_plus_days || "",
            name: "31Days+",
            itemStyle: { color: "#817EFF" },
            type: "31_plus_days",
          },
        ],
      },
    ],
  };

  useEffect(() => {
    if (getbussinessstatus.data != undefined) {
      setlinegraphdata(getbussinessstatus.data.data);
    }
  }, [clientid, period, range, type, getbussinessstatus.data]);
  const optionline = {
    xAxis: {
      type: "category",
      // data: ["Mon", "Tue", "Wed", "Thu", "Fri", "Sat", "Sun"],
      data: Object.keys(linegraphdata).map((key) => {
        return key;
      }),
    },
    yAxis: {
      type: "value",
    },
    series: [
      {
        emphasis: {
          label: {
            show: true,
            fontSize: 10,
          },
        },
        // data: [1000, 2000, 1000, 5000, 3000, 4000, 1000],
        data: Object.keys(linegraphdata).map((key) => {
          return linegraphdata[key];
        }),

        type: "line",
      },
    ],
  };

  const onChartClick = (params) => {
    console.log("Chart clicked", params);
    dispatch(setClientRentPopup(true));
    dispatch(
      setClientRentPopupData({
        ...keydata,
        text: "Tenancy Arrears",
        subtext: params.data.type,
      })
    );
  };
  const onEvents = {
    // click: onChartClick,
  };

  const loading = useSelector((state) => state.counter.loader);

  if (loading) {
    return null; // Or some loading component
  }

  // const checkWindowWidth = () => {
  //   if (window.innerWidth < 1030) {
  //     setGraphWidth("55vw");
  //   }
  //   else {
  //     setGraphWidth("46vw");
  //   }
  // };

  // useEffect(() => {
  //   checkWindowWidth();
  //   window.addEventListener('resize', checkWindowWidth); // Add event listener on resize
  //   return () => {
  //     window.removeEventListener('resize', checkWindowWidth);
  //   };
  // }, []);

  // const checkWindowWidth = () => {
  //   if (window.innerWidth < 800) {
  //     setChartWidth("55vw");
  //   } else {
  //     setChartWidth("47vw");
  //   }
  // };

  // useEffect(() => {
  //   checkWindowWidth(); // Set initial width
  //   window.addEventListener('resize', checkWindowWidth); // Add resize listener

  //   return () => {
  //     window.removeEventListener('resize', checkWindowWidth); // Cleanup listener on unmount
  //   };
  // }, []);
  const getDateWithSuffix = (date) => {
    const day = dayjs(date).date();
    const suffix = (day) => {
      if (day > 3 && day < 21) return "th"; // handle 11th-13th
      switch (day % 10) {
        case 1:
          return "st";
        case 2:
          return "nd";
        case 3:
          return "rd";
        default:
          return "th";
      }
    };

    return `${day}${suffix(day)} ${dayjs(date).format("MMM YYYY")}`;
  };

  const handleBusinessStats = (todayText, rentalPeriodText) => {
    const dateRangeData = range;
    const todayDate = new Date();

    if (
      dayjs(todayDate).format("YYYY-MM-DD") === dateRangeData[0] &&
      dayjs(todayDate).format("YYYY-MM-DD") === dateRangeData[1]
    ) {
      return todayText;
    } else {
      return (
        <>
          <span>{rentalPeriodText}</span>
          <span style={{ fontWeight: "600", color: "#817EFF" }}>
            {getDateWithSuffix(dateRangeData[0])}
          </span>
          <span style={{ fontWeight: "500" }}> to </span>
          <span style={{ fontWeight: "600", color: "#817EFF" }}>
            {getDateWithSuffix(dateRangeData[1])}
          </span>
        </>
      );
    }
  };
  const getScreenWidth = () => {
    return window.innerWidth;
  };
  const screenWidth = getScreenWidth();
  console.log(`The screen width is: ${screenWidth}px`);

  return (
    <>
      <div className="ClientMoneyOverview">
        <div className="cmoMain">
          <div className="arrcardMain">
            <div className="arrears_card">
              <h1>Arrears</h1>
              <div className="overGraphWrap">
                <div className="graph_wrapper">
                  <div className="graph">
                    <ReactEcharts
                      style={{
                        height: "17vw",
                        top: "0px",
                        width: "600px",
                      }}
                      option={option}
                      onEvents={onEvents}
                    />
                  </div>
                </div>

                <div className="content_wrapper">
                  <ul>
                    <li
                      className={`${
                        Data?.charges_0_7_days == 0 ? "notAnimated" : "animated"
                      }`}
                      onClick={(e) => {
                        setPopupstate(true);
                        setActiveTab("0 - 7 Days");
                      }}
                    >
                      <span>
                        <div className="badge07"></div>
                        {"0 - 7 Days"}
                      </span>
                      <p>{`£${formatPounds(Data?.charges_0_7_days || 0)}`}</p>
                    </li>
                    <li
                      className={`${
                        Data?.charges_8_14_days == 0
                          ? "notAnimated"
                          : "animated"
                      }`}
                      onClick={(e) => {
                        setPopupstate(true);
                        setActiveTab("8 - 14 Days");
                      }}
                    >
                      <span>
                        <div className="badge814"></div>
                        {"8 - 14 Days"}
                      </span>
                      <p>{`£${formatPounds(Data?.charges_8_14_days || 0)}`}</p>
                    </li>
                    <li
                      className={`${
                        Data?.charges_15_30_days == 0
                          ? "notAnimated"
                          : "animated"
                      }`}
                      onClick={(e) => {
                        setPopupstate(true);
                        setActiveTab("15 - 30 Days");
                      }}
                    >
                      <span>
                        <div className="badge1530"></div>
                        {"15 - 30 Days"}
                      </span>
                      <p>{`£${formatPounds(Data?.charges_15_30_days || 0)}`}</p>
                    </li>
                    <li
                      className={`${
                        Data?.charges_31_plus_days == 0
                          ? "notAnimated"
                          : "animated"
                      }`}
                      onClick={(e) => {
                        setPopupstate(true);
                        setActiveTab("31+ Days");
                      }}
                    >
                      <span>
                        <div className="badge31"></div>
                        {"31+ Days"}
                      </span>
                      <p>{`£${formatPounds(
                        Data?.charges_31_plus_days || 0
                      )}`}</p>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
          <div
            className="arrdataMain"
            style={{
              display: "flex",
              height: "100%",
            }}
          >
            <div style={{ width: "100%", height: "100%" }}>
              <div className="overview_left" style={{ height: "100%" }}>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    // width: "100%",
                    marginBottom: "20px",
                  }}
                >
                  <h1
                    style={{
                      fontSize: "24px",
                      fontWeight: "400",
                      lineHeight: "36px",
                    }}
                  >
                    Business Stats
                  </h1>
                  <DateRange
                    onChange={(e) => {
                      setRange(e);
                    }}
                    minDate={new Date(2020, 0, 1)}
                    maxDate={new Date()}
                    defaultValue={[dayjs(), dayjs()]}
                    style={{ width: "300px" }}
                  />
                </div>
                <div className="card_parent">
                  <div className="cardWrap">
                    <div
                      className="card"
                      onClick={(e) => {
                        dispatch(
                          setClientRentPopupData({
                            ...keydata,
                            text: "Today’s Rents",
                            subtext: "",
                          })
                        );
                        dispatch(setClientRentPopup(true));
                      }}
                    >
                      <p>
                        {handleBusinessStats(
                          "Today’s Rents",
                          "Rental Period: "
                        )}
                      </p>
                      <div className="amount">
                        <h1>£{formatPounds(todaysrent)}</h1>
                      </div>
                    </div>
                  </div>
                  <div className="cardWrap">
                    <div
                      className="card"
                      onClick={(e) => {
                        dispatch(
                          setClientRentPopupData({
                            ...keydata,
                            text: "Today's Agency Income",
                            subtext: "",
                          })
                        );
                        dispatch(setClientRentPopup(true));
                      }}
                    >
                      <p>
                        {handleBusinessStats(
                          "Today's Agency Income",
                          "Agency Income received: "
                        )}
                      </p>
                      <div className="amount">
                        <h1>£{formatPounds(todaysagencyincome)}</h1>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="overviewDays">
                  <div
                    className="date"
                    style={{
                      alignItems: "flex-end",
                    }}
                  >
                    <span
                      className={period == "7days" ? "active" : ""}
                      onClick={(e) => dispatch(setClientMoneyPeriod("7days"))}
                    >
                      7 Days
                    </span>
                    <span
                      className={period == "month" ? "active" : ""}
                      onClick={(e) => dispatch(setClientMoneyPeriod("month"))}
                    >
                      Month
                    </span>
                    <span
                      className={period == "year" ? "active" : ""}
                      onClick={(e) => dispatch(setClientMoneyPeriod("year"))}
                    >
                      Year
                    </span>
                  </div>
                  <div className="toggle_button">
                    <button
                      className={toggle ? "active" : ""}
                      // style={{
                      //   background: toggle ? "#000047" : "#fff",
                      //   color: toggle ? "#fff" : "#000047",
                      // }}
                      style={{
                        zIndex: "1",
                      }}
                      onClick={(e) => {
                        setToggle(true);
                        dispatch(setClientMoneyAgencyType("Rent"));
                      }}
                    >
                      Rent
                    </button>
                    <button
                      className={!toggle ? "active" : ""}
                      style={{
                        zIndex: "1",
                      }}
                      onClick={(e) => {
                        setToggle(false);
                        dispatch(setClientMoneyAgencyType("Management Fee"));
                      }}
                    >
                      Agency Income
                    </button>
                  </div>
                </div>

                <div className="chart" style={{ position: "relative" }}>
                  <ReactEcharts
                    style={{
                      width: "100%",
                      height: "70%",
                      position: "absolute",
                      // left: "-7%",
                      maxHeight: "560px",
                      minHeight: "400px",
                    }}
                    option={optionline}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {popup && (
        <ClientMoneyOverviewPopup
          keydata={keydata}
          todayRent={todaysrent}
          todaysAgencyIncome={todaysagencyincome}
          fromDate={range[0]}
          toDate={range[1]}
        />
      )}
      {popupstate && (
        <>
          <ArrearsPopup
            setActiveTab={setActiveTab}
            activeTab={activeTab}
            setPopupstate={setPopupstate}
            popuptext={""}
            dataFromData={Data}
            popupstate={popupstate}
          />
        </>
      )}
    </>
  );
}
