import React, { useState, useEffect } from "react";
import "./style.scss";
import { PasswordInput } from "../../GlobalComponents/othercomponents/PasswordInput";
import logo from "../../../Assets/Login/Logo_main.svg";
import solace from "../../../Assets/Login/Solace.svg";
import solaceCorner from "../../../Assets/Login/Solace_corner.svg";
// import { useLogin } from '../../Context/queries';
import Arrow from "../../../Assets/Common/Arrow.svg";
import { clientid } from "../../../redux/reducer/counterslice";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router";
import { useLogin } from "../../../Api/PropertiesQuery";
import DefaultLoader from "../DefaultLoader";
import LowWidthPopup from "../LowWidthPopup";

export default function ActivateForm() {
  const [UserDetails, setUserDetails] = useState({ email: "", password: "" });
  const userdata = useSelector((state) => state.counter.loginuserdata);
  const navigate = useNavigate({ data: FormData });
  const dispatch = useDispatch();

  const [lowWidthPopup, setLowWidthPopup] = useState(false);

  const loginApi = useLogin({
    email: UserDetails.email.toLowerCase(),
    password: UserDetails.password,
  });
  useEffect(() => {
    console.log(userdata, "kjhkjfdhuserdata");
    dispatch(clientid(userdata.id));
  }, [userdata]);

  const handleSubmit = () => {
    const formdata = new FormData();
    formdata.append("email", UserDetails.email);
    formdata.append("password", UserDetails.password);
    loginApi.mutate();
    console.log(loginApi, "ajfkdjlkj");
  };
  return (
    <>
      {loginApi?.isPending && <DefaultLoader />}
      {lowWidthPopup ? (
        <LowWidthPopup />
      ) : (
        <div className="loginMain">
          <div className="loginLeft">
            <div className="backgroundImage">
              <div className="innerContent">
                <div className="innerTop">
                  <div className="loginPageLogo">
                    <img src={logo} alt="logo" />
                  </div>
                  <div className="loginPageSolace">
                    <img src={solace} alt="solace" />
                  </div>
                  <div className="borderDiv"></div>
                  <p>Client Portal</p>
                </div>
              </div>
            </div>
          </div>
          <div className="loginRight">
            <div className="loginRightMainImg1">
              <img src={solaceCorner} alt="solaceCorner" />
            </div>
            <div className="innerContent">
              <h2>Welcome</h2>
              <p>Sign into your Solace Account</p>
              <form
                // className="innerContent"
                onSubmit={(e) => {
                  e.preventDefault();
                }}
              >
                <div className="inputStyle">
                  <input
                    className="inputStyle"
                    type="text"
                    placeholder="Email"
                    value={UserDetails.email}
                    style={{
                      background: "#F0F0F0",
                      // opacity: ""
                    }}
                    onChange={(e) => {
                      setUserDetails((prevState) => ({
                        ...prevState,
                        email: e.target.value,
                      }));
                    }}
                  />
                  <div className="customInputLogin">
                    <PasswordInput
                      name="password"
                      type="text"
                      placeholder="Password"
                      value={UserDetails.password}
                      onChange={(e) => {
                        setUserDetails((prevState) => ({
                          ...prevState,
                          password: e.target.value,
                        }));
                      }}
                    />
                  </div>
                  <p
                    className="forgetPassword"
                    onClick={() => {
                      navigate("/ForgetPassword");
                    }}
                    style={{
                      position: "relative",
                      left: "6px",
                      margin: "0",
                    }}
                  >
                    Forgot Password ?
                  </p>
                </div>

                <div
                  className="button_container"
                  style={{
                    margin: "0",
                    // fontSize: `${Math.min(15, window.innerWidth * 0.015)}px`,
                  }}
                >
                  <button
                    type="submit"
                    onClick={(e) => {
                      handleSubmit();
                    }}
                  >
                    Login
                    <img
                      src={Arrow}
                      alt="arrow"
                      // style={{ marginLeft: "10px" }}
                    ></img>
                  </button>
                </div>
              </form>
            </div>
          </div>
          <div className="loginMainImg1">
            <img src={solaceCorner} alt="solaceCorner" />
          </div>
        </div>
      )}
    </>
  );
}
