import "../../../StyleSheet/Maintenance/popups/maintenancesupplierapprove.scss";
import closebutton from "../../../../Assets/Common/close_button.svg";
import { useEffect, useMemo, useState } from "react";
import TableComponent from "../../../GlobalComponents/table-v8/reacttable";
import { createColumnHelper } from "@tanstack/react-table";
import Search from "../../../../MainComponent/fields/search";
import GenerateReport from "../../../../MainComponent/fields/generatereport";
import ConformationPopup from "../../../../MainComponent/conformationpopup/Conformation";
import thubIcon from "../../../../Assets/Common/thubIcon.svg";
import starIcon from "../../../../Assets/Common/starIcon.svg";
import { useApproveSupplier } from "../../../../Api/MaintenanceQuery";
import { formatPounds } from "../../../../Utils/Utils";
import closeSolidIcon from "../../../../Assets/Common/closeSolidIcon.svg";

export default function MaintenanceSupplierApprove(props) {
  const { rowvalue, setpopup, popupstate } = props;
  const columnHelper = createColumnHelper();
  const [approvepopup, setApprovepopup] = useState(false);
  const [workOrderId, setWorkOrderId] = useState(false);
  const [supplierId, setSupplierId] = useState(false);

  function formatAddress(addressParts) {
    return addressParts
      .filter((part) => part) // Filters out undefined, null, and empty strings
      .join(", ");
  }

  const handleSuccess = () => {
    setApprovepopup(true);
    setpopup(false);
  };

  const handleApproveSupplier = useApproveSupplier({
    id: workOrderId,
    handleSuccess: handleSuccess,
    payload: {
      approved: "True",
      supplier: supplierId,
      status: "Approved",
    },
  });

  useEffect(() => {
    console.log(rowvalue, "ihuashfsahdkfhushufdihasi");
  }, [rowvalue]);

  const handleSubmit = () => {
    handleApproveSupplier.mutate();
  };
  const addressBuilder = () => {
    const data = props?.rowvalue?.property;
    const value = formatAddress([
      data?.name + " " + data?.street,
      data?.city,
      data?.postcode,
    ]);
    return value;
  };
  return (
    <>
      <div
        className={`popup_main_wrapper ${
          popupstate === true ? "popup-open" : "popup-close"
        } `}
      >
        <div
          className={`maintenance_content_wrapper ${
            popupstate === true ? "popup-open" : "popup-close"
          } `}
          style={{
            position: "relative",
          }}
        >
          <div className="header" style={{ paddingBottom: "10px" }}>
            <div className="header_content-wrapper row p-0 m-0">
              <div className="col-2 p-0 m-0">
                <img
                  src={rowvalue.property.image?.[0]}
                  alt="img"
                  width={125}
                  height={125}
                ></img>
              </div>
              <div className="title_wrapper col-9 m-0">
                <h1 style={{ marginBottom: "20px" }}>
                  Please approve a supplier
                </h1>
                <p>{addressBuilder()}</p>
                <p>{props.rowvalue.title}</p>
              </div>
              <div className="col-1 close_button_wrapper">
                <img
                  className="close"
                  alt="img"
                  onClick={(e) => {
                    props.setpopup(false);
                  }}
                  style={{ borderRadius: "0" }}
                  src={closeSolidIcon}
                ></img>
              </div>
            </div>
          </div>
          <div className="popuptable">
            {rowvalue?.work_order_suppliers?.map((items, index) => {
              const dataFromParent = items.supplier?.contact_detail?.[0];
              return (
                <>
                  <div className="" key={index} style={{ width: "32.5%" }}>
                    <div
                      className="download_card"
                      style={{
                        border: items?.perferd ? "1.5px solid #0FBB00" : "none",
                        // boxShadow: items?.rec
                        //   ? "0 4px 10px rgba(15, 187, 0, 0.2)"
                        //   : "none",
                      }}
                    >
                      <div
                        className="text"
                        style={{
                          display: "flex",
                          alignItems: "flex-start",
                          justifyContent: "space-between",
                          width: "100%",
                          gap: "10px",
                        }}
                      >
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "flex-start",
                            alignItems: "flex-start",
                            flexDirection: "column",
                          }}
                        >
                          <p
                            style={{
                              fontSize: "16px",
                              fontWeight: "400",
                              margin: "0",
                              textTransform: "capitalize",
                            }}
                          >
                            {items.supplier?.company}
                          </p>

                          <p
                            style={{
                              margin: "0",
                              fontSize: "12px",
                              fontWeight: "400",
                              textTransform: "capitalize",
                            }}
                          >
                            {" "}
                            {formatAddress([
                              dataFromParent?.title +
                                " " +
                                dataFromParent?.first_name +
                                " " +
                                dataFromParent?.last_name +
                                " ",
                            ])}
                          </p>
                        </div>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            gap: "10px",
                            height: "100%",
                          }}
                        >
                          {items?.perferd && (
                            <img src={thubIcon} alt="recommendedIcon" />
                          )}
                          {items?.rec && (
                            <img
                              src={starIcon}
                              alt="recommendedIcon"
                              style={{}}
                            />
                          )}
                        </div>
                      </div>
                      <div className="dividerLine"></div>
                      <div
                        className="bottomSection"
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                          alignItems: "center",
                        }}
                      >
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            gap: "5px",
                          }}
                        >
                          <span style={{ fontSize: "24px", fontWeight: "300" }}>
                            £{formatPounds(items?.amount)}
                          </span>
                        </div>
                        <div style={{ display: "flex", gap: "8px" }}>
                          <button>View</button>
                          <button
                            onClick={(e) => {
                              setSupplierId(items?.supplier.id);
                              setWorkOrderId(items?.work_order);
                              setApprovepopup(true);
                            }}
                          >
                            Approve
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </>
              );
            })}
          </div>
        </div>
      </div>
      {approvepopup && (
        <ConformationPopup
          text={"Are you sure you want to Approve ?"}
          accept={"Yes"}
          Decline={"No"}
          successtext={"Your Property Manager has been notified."}
          setApprovepopup={setApprovepopup}
          handleFunction={handleSubmit}
          style={{ height: "385px" }}
        />
      )}
    </>
  );
}
