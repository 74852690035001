import TableComponent from "../../../GlobalComponents/table-v8/reacttable";
import "../../../StyleSheet/dashboard/popups/compliencepopupstyle.scss";
import FilterPanel from "../../../../MainComponent/FilterPanel";
import closebutton from "../../../../Assets/Common/close_button.svg";
import { createColumnHelper } from "@tanstack/react-table";
import { useEffect, useMemo, useState } from "react";
import { useFetchArrearsSelected } from "../../../../Api/homeQuery";
import { useDispatch, useSelector } from "react-redux";
import dayjs from "dayjs";
import {
  useFetchLandlordDetails,
  useFetchPropertiesCompliance,
  useFetchPropertyBasedOnStatus,
} from "../../../../Api/PropertiesQuery";
import closeSolidIcon from "../../../../Assets/Common/closeSolidIcon.svg";

import {
  formatAddress,
  formatName,
  formatPounds,
} from "../../../../Utils/Utils";
import {
  setContactDetailsFilter,
  setPropertyDetailsFilter,
  setPropertyOccupancyPopup,
  setPropertyOccupancyPopupFilter,
} from "../../../../redux/reducer/counterslice";
import Propertydetails from "./propertydetails";
import LandLordPopup from "../../Contacts/Popup/LandlordPopup";
import Search from "../../../../MainComponent/fields/search";
import { Skeleton } from "antd";

export default function OccupancyPopup(props) {
  const { activeTab, setActiveTab } = props;
  const columnHelper = createColumnHelper();
  const [paramsPayload, setParamsPayload] = useState("occupied");
  const [TableData, setTableData] = useState([]);
  const clientid = useSelector((state) => state.counter.loginuserdata.id);
  const [count, setCount] = useState(0);
  const [pageindex, setPageIndex] = useState(1);
  const [pagesize, setPageSize] = useState(10);
  const [popup, setPopup] = useState(false);
  const dispatch = useDispatch();
  const [landLordPopup, setLandLordPopup] = useState(false);
  const [selectedPropertyId, setSelectedPropertyId] = useState("");
  const [selectedLandLordId, setSelectedLandLordId] = useState("");
  const fetchProperties = useFetchPropertiesCompliance(selectedPropertyId);
  const fetchLandlordDetails = useFetchLandlordDetails(selectedLandLordId);
  const [search, setSearch] = useState("");

  const fetchArrears = useFetchPropertyBasedOnStatus({
    status: true,
    client: clientid,
    page: pageindex,
    page_size: pagesize,
    search: search,
    ostatus: paramsPayload,
  });
  const upcomingDocumentStatus = useSelector(
    (state) => state.counter.PropertyOccupancyPopup
  );

  const columns = useMemo(
    () => [
      columnHelper.accessor((row) => row, {
        id: "photo",
        header: "Photo",
        sortingFn: "basic",

        enableSorting: false,
        cell: (info) => {
          console.log(info.row.original, "kljkasjkdfjlasjdkf");
          return (
            <img
              src={
                info.row.original?.media?.[0]?.url
                  ? info.row.original?.media?.[0]?.url
                  : info.row.original?.image?.[0]
              }
              alt="image"
              width={"102px"}
              height={"73px"}
              style={{ borderRadius: "10px" }}
            />
          );
        },
      }),

      columnHelper.accessor((row) => row, {
        sortingFns: "basic",
        id: "property_address",
        header: "Property Address",
        enableSorting: false,
        cell: (info) => {
          const data = info.row.original;
          return (
            <span
              className="contentHover"
              style={{ wordBreak: "break-all" }}
              onClick={() => {
                dispatch(setPropertyDetailsFilter("1"));
                dispatch(setContactDetailsFilter(1));
                setSelectedPropertyId(data?.id);
                setPopup(true);
              }}
            >
              {formatAddress([
                data.name,
                data.street,
                data.city,
                data.postcode,
              ])}
            </span>
          );
        },
      }),
      columnHelper.accessor((row) => row, {
        id: "landlord_Name",
        header: "Landlord Name",
        sortingFn: "basic",
        cell: (info) => {
          const data = info.row.original.landlord?.contact_detail?.[0];
          return (
            <span
              className="contentHover"
              style={{ wordBreak: "break-all" }}
              onClick={() => {
                dispatch(setPropertyDetailsFilter("1"));
                dispatch(setContactDetailsFilter(1));
                setSelectedLandLordId(info.row.original.landlord?.id);
                setLandLordPopup(true);
              }}
            >
              {info.row.original?.landlord?.company
                ? info.row.original?.landlord?.company
                : formatName(data?.title, data?.first_name, data?.last_name)}
            </span>
          );
        },
      }),
      columnHelper.accessor((row) => row, {
        id: "tenant",
        header: "Tenant Name",
        sortingFn: "basic",
        cell: (info) => {
          const data = info.row.original?.tenant?.tenant__contact_detail?.[0];
          console.log(
            data?.title,
            data?.first_name,
            data?.last_name,
            "asfdasdfasfdasfdas"
          );
          return (
            <span
              className="contentHover"
              style={{ wordBreak: "break-all" }}
              onClick={() => {
                dispatch(setPropertyDetailsFilter("1"));
                dispatch(setContactDetailsFilter(2));
                setSelectedPropertyId(info.row.original.id);
                setPopup(true);
              }}
            >
              {formatName(data?.title, data?.first_name, data?.last_name)}
            </span>
          );
        },
      }),
      columnHelper.accessor((row) => row, {
        id: "rent",
        header: "Rent",
        sortingFn: "basic",
        cell: (info) => {
          const data = info.row.original?.tenant?.rent;
          return (
            <span
              style={{
                wordBreak: "break-all",
                textTransform: "capitalize",
                fontSize: "max(12px, 1.1vw)",
              }}
            >
              {info.row.original?.tenant?.rent ? (
                <p>£{formatPounds(data)}</p>
              ) : (
                "--"
              )}
            </span>
          );
        },
      }),
    ],
    []
  );
  const setParams = () => {
    if (activeTab === "Occupied") {
      setParamsPayload("occupied");
    } else if (activeTab === "Upcoming Vacancies") {
      setParamsPayload("vacant_in_progress");
    } else if (activeTab === "Vacant") {
      setParamsPayload("vacant");
    }
  };
  useEffect(() => {
    setParams();
  }, [activeTab]);
  useEffect(() => {
    if (fetchArrears.data?.data && pageindex && pagesize) {
      setTableData(fetchArrears.data?.data?.results);
      setCount(fetchArrears.data?.data?.count);
    }
  }, [fetchArrears.data, pageindex, pagesize, activeTab]);

  const updateFilter = (item) => {
    dispatch(setPropertyOccupancyPopupFilter(item));
  };
  return (
    <div
      className={`popup_main_wrapper ${
        upcomingDocumentStatus === true ? "popup-open" : "popup-close"
      } `}
    >
      <div
        className={`main_popup ${
          upcomingDocumentStatus === true ? "popup-open" : "popup-close"
        } `}
        style={{
          // width: "1542px",
          height: "800px",
          marginRight: "20px",
          position: "relative",
        }}
      >
        <div className="com_popup_header">
          <div className="content">
            <h1
              style={{
                fontSize: "max(16px, 1.8vw)",
                display: "flex",
                alignItems: "center",
                marginBottom: "20px",
              }}
            >
              Occupancy:
              <span
                style={{
                  fontSize: "max(16px, 1.8vw)",
                  color:
                    props?.activeTab === "Occupied"
                      ? "#4dde87"
                      : props?.activeTab === "Upcoming Vacancies"
                      ? "#FFB200"
                      : props?.activeTab === "Vacant"
                      ? "#FF3A29"
                      : "#000000",
                  textWrap: "nowrap",
                }}
              >
                {props?.activeTab}
              </span>
            </h1>
            <div
              style={{
                // marginLeft: "43%",
                display: "flex",
                gap: "max(10px, 0.8vw)",
              }}
            >
              <Search
                setsearch={setSearch}
                style={{ width: "210px" }}
                setPageIndex={setPageIndex}
              />
              <div className="close_button_wrapper">
                <img
                  className="close"
                  alt="close"
                  onClick={() => dispatch(setPropertyOccupancyPopup(false))}
                  src={closeSolidIcon}
                />
              </div>
            </div>
          </div>
          <div
            style={{
              width: "100%",
              marginBottom: "max(10px, 0.8vw)",
              background: "rgb(247,249,251)",
              borderRadius: "15px",
            }}
          >
            <FilterPanel
              tabs={["Occupied", "Upcoming Vacancies", "Vacant"]}
              setActiveTab={updateFilter}
              activeTab={activeTab}
            />
          </div>
        </div>

        <div className=" occupancyConfig">
          {fetchArrears.isFetching ? (
            <div className="skelitonDiv">
              <Skeleton active style={{ height: "98px" }} rows={1} />
            </div>
          ) : (
            <TableComponent
              columns={columns}
              data={
                fetchArrears.data?.data?.results
                  ? fetchArrears.data?.data?.results
                  : []
              }
              count={count}
              pageindex={pageindex}
              pagesize={pagesize}
              setPageIndex={setPageIndex}
              setPageSize={setPageSize}
              skeLetonLoading={fetchArrears?.isFetching}
              //   handleRowClick={handleRowClick}
            />
          )}
        </div>
      </div>
      {fetchProperties.data?.data && popup && (
        <Propertydetails
          rowvalue={fetchProperties.data?.data}
          setpopup={setPopup}
          selectedData={"selectedData"}
          fromContact={true}
          setSelectedLandLordId={() => {}}
          propertyPopup={popup}
        />
      )}{" "}
      {fetchLandlordDetails.data?.data && landLordPopup && (
        <LandLordPopup
          rowvalue={fetchLandlordDetails.data?.data}
          setpopup={setLandLordPopup}
          selectedData={"selectedData"}
          landlordPopup={landLordPopup}
          fromContact={true}
        />
      )}
    </div>
  );
}
